import {
  RECLAIMEDWATERREUSEPROJECT
} from 'actions/pzCustom/reclaimedWaterReuseProject';
export const initialState = {
  isFetching: false,
  data: []
};
export const reclaimedWaterReuseProjectList = (state = initialState, action) => {
  switch (action.type) {
    case `${RECLAIMEDWATERREUSEPROJECT}_PENDING`:
      return Object.assign({}, initialState, {
        isFetching: true
      });
    case `${RECLAIMEDWATERREUSEPROJECT}_SUCCESS`:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.payload.data,
        allData: action.payload
      });
    default:
      return state;
  }
};

