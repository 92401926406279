import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {alarm,hydrologyAlarm,hydrologyAlarmId,alarmCofirm,alarmClear} = api;

/**
 * 查询告警列表
 */
export async function alarmList(params) {
  return request({url: alarm, method: 'get', data: params,});
}
/**
 * 告警确认
 */
export async function alarmCofirmSubmit(params) {
  return request({url: alarmCofirm, method: 'put', data: params,});
}
/**
 * 告警确认
 */
export async function alarmClearSubmit(params) {
  return request({url: alarmClear, method: 'put', data: params,});
}
/**
 * 查询告警配置列表
 */
export async function alarmConfigList(params) {
  return request({url: hydrologyAlarm, method: 'get', data: params,});
}
/**
 * 新增告警配置列表
 */
export async function alarmConfigAdd(params) {
  return request({url: hydrologyAlarm, method: 'post', data: params,});
}
/**
 * 删除告警配置列表
 */
export async function alarmConfigDel(params) {
  return request({url: hydrologyAlarm + '/delete' , method: 'post', data: params,});
}

/**
 * 获取告警配置详情
 */
export async function alarmConfigDetail(params) {
  return request({url: hydrologyAlarmId, method: 'get', data: params,});
}
/**
 * 更新告警配置详情
 */
export async function updateAlarmConfigDetail(params) {
  console.log('params: ', params);
  return request({url: hydrologyAlarmId, method: 'put', data: params,});
}



