import {publicBoardManage} from 'api';

export const PUBLICBOARDMANAGE = 'PUBLICBOARDMANAGE';


export const tableAction = (data) => ({
  type: PUBLICBOARDMANAGE,
  payload: {
    promise: publicBoardManage.list(data)
  }
});


