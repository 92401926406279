import {role} from 'api';

export const ROLELIST = 'ROLELIST';


export const roleAction = (data) => ({
  type: ROLELIST,
  payload: {
    promise: role.roleList(data)
  }
});
