import config from "utils/apiconfig";
import request from "utils/request";

const { api } = config;
const { patrolRealTime, riverSystem, river, topTarea, topTchief,soketApi } = api;

/**
 * 实时巡查列表
 */
export async function riverCruisersList(params) {
  return request({ url: patrolRealTime, method: "get", data: params });
}
/**
 * 水系列表
 */
export async function riverSystemList(params) {
  return request({ url: riverSystem, method: "get", data: params });
}
/**
 * 河流列表
 */
export async function riverList(params) {
  return request({ url: river, method: "get", data: params });
}
/**
 * 河长Top列表
 */
export async function topTchiefList(params) {
  return request({ url: topTchief, method: "get", data: params });
}
/**
 * 区域Top列表
 */
export async function topTareaList(params) {
  return request({ url: topTarea, method: "get", data: params });
}
/**
 * 区域Top列表
 */
export async function soket(params) {
  return request({ url: soketApi, method: "post", data: params });
}
