import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {  patrolRecord,patrolRecordId,patrolRecordSupervise,patrolChief} = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({url: patrolRecord, method: 'get', data: params,});
}

/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({url: patrolRecordId, method: 'get', data: {
      id:paramId
    },});
}
/**
 * 编辑
 */
export async function edit(params) {
  return request({url: patrolRecordId, method: 'put', data: params});
}
/**
 * 删除
 */
export async function deleteItem (params) {
  return request({url: patrolRecord+ '/delete' , method: 'post', data:params});
}
/**
 * 创建
 */
export async function createItem(params) {
  return request({url: patrolRecord, method: 'post', data: params,});
}

/**
 * 监督巡查
 */
export async function superviseList(params) {
  return request({url: patrolRecordSupervise, method: 'get', data: params,});
}
export async function createSupervise(params) {
  return request({url: patrolRecordSupervise, method: 'post', data: params,});
}

/**
 * 实时巡查人员
 */
export async function patrolChiefList(params) {
  return request({url: patrolChief, method: 'get', data: params,});
}
