/**
 * Created by hao.cheng on 2017/5/7.
 */
import React from 'react';

import {message} from "antd";
import history from "../../utils/history"
import {loginByOAuth} from "../../api/login";
import queryString from 'query-string';

import {setToken, auth, getToken} from "../../utils/auth"
import {chiefManage, user} from "../../api";
import {connect} from "react-redux";
import {loginUser, oauthLoginUser, refreshUser} from "../../redux/actions/login";
import {actionEmums} from "../../redux/actions/basic";

@connect(
  state => {
    return {
      emums: state.emums,
      loginUserData: state.loginUser.allData
    }
  },
  {oauthLoginUser, actionEmums, refreshUser}
)
class OAuthLogin extends React.Component {

  componentWillMount() {
    const query = queryString.parse(this.props.location.search);
    if (query && query.code) {
      this.handleSubmit(query);
    }
  }

  //获取用户详情
  getUserDetail = async paramId => {
    return new Promise((resolve, reject) => {
      user.getUserDetail("default").then(res => {
        if (res.code === 200) {
          resolve(res.data)
        } else {
          resolve({})
          message.error("用户信息获取失败")
        }
      })
    })
  }

  handleSubmit = (query) => {
    if (getToken()) {
      history.push("/index")
    } else {
      loginByOAuth(Object.assign({redirect_uri: window.location.href}, query)).then(res => {
        console.log("createRole res: ", res);
        if (res.code == 200) {
          const isNobindUser = res.data.principal.authorities.find(a => a.authority == "ROLE_NO_BIND_USER") != null;

          if(isNobindUser){
            this.props.oauthLoginUser(res).then(response => {
              auth(res.data).then(()=>{
                history.push("/bind-third-account");
              })

            });
          }else{
            this.props.oauthLoginUser(res).then(response => {
              message.info("登录成功!")
              auth(res.data)
              const userId = res.data.principal.id
              Promise.all([
                this.getUserDetail(userId),
                this.getChiefDetail(userId)
              ]).then(values => {
                const userPrincipal = Object.assign({}, values[0], values[1])
                const userAllData = this.props.loginUserData
                userAllData.data.principal = userPrincipal
                localStorage.setItem(
                  "avatarUrl",
                  userAllData.data.principal.avatarUrl
                )
                userAllData.data.getDetail = true
                const rolesFirstKey = Object.keys(
                  userAllData.data.principal.roles
                )[0]
                userAllData.data.principal.roles =
                  userAllData.data.principal.roles[rolesFirstKey]
                this.props.refreshUser(userAllData).then(res => {
                  let activeItems = sessionStorage.getItem("activeItem")
                  if (activeItems) {
                    let nowPath = activeItems.split(",")
                    nowPath = "/" + nowPath[nowPath.length - 1]
                    history.push(nowPath)
                  } else {
                    history.push("/index")
                  }
                })
              })
            });
          }
        } else {

        }
      }).catch(err => {
        console.log(err);
        message.warn("访问异常");
        history.push("/login")
      })

    }


  };


  //获取用户详情
  getUserDetail = async paramId => {
    return new Promise((resolve, reject) => {
      user.getUserDetail(paramId).then(res => {
        if (res.code === 200) {
          resolve(res.data)
        } else {
          resolve({})
          message.error("用户信息获取失败")
        }
      })
    })
  }
  //获取河长详情
  getChiefDetail = async paramId => {
    return new Promise((resolve, reject) => {
      chiefManage.getDetail(paramId).then(res => {
        if (res.code === 200) {
          resolve(res.data)
        } else {
          resolve({})
        }
      })
    })
  }


  render() {
    return (
      <div className="center" style={{height: '100%', background: '#ececec', overflow: 'hidden'}}>
        三方登录进行中
      </div>
    )
  }
}

export default OAuthLogin;
