import {list} from 'api/pzCustom/pzzsstnum';

export const PZZSSTNUMLIST = 'PZZSSTNUMLIST';

export const tableAction = (data) => ({
  type: PZZSSTNUMLIST,
  payload: {
    promise: list(data)
  }
});
