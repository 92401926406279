import {
  AREASTATISTICSTABLEACTION,
  CHIEFPATROLCHART,
  CHIEFPATROLCHART_HALFYEAR,
  CHIEFPATROLTABLE,
  CHIEFPATROLTABLE_HALFYEAR,
  CHIEFPATROLTWONCHART,
  CHIEFPATROLVILLAGECHART,
  HOMECHIEFPATROLCHART_DIS,
  HOMECHIEFPATROLCHART_FULLYEAR,
  HOMECHIEFPATROLCHART_HALFYEAR,
  HOMECHIEFPATROLTABLE_DIS,
  HOMECHIEFPATROLTABLE_FULLYEAR,
  HOMECHIEFPATROLTABLE_HALFYEAR,
  HOMECHIEFPATROLTWONCHART,
  HOMECHIEFPATROLVILLAGECHART,
  RIVERREACHPATROLCHART,
  RIVERREACHPATROLCHART_HALFYEAR,
  RIVERREACHPATROLTABLE,
  RIVERREACHPATROLTABLE_HALFYEAR,
} from "../actions/patrolAnalysis"

import { reducerPackage } from "../../utils"

export const chiefPatrolTable = reducerPackage(CHIEFPATROLTABLE)
export const chiefPatrolChart = reducerPackage(CHIEFPATROLCHART)

export const chiefPatrolTable_halfYear = reducerPackage(CHIEFPATROLTABLE_HALFYEAR)
export const chiefPatrolChart_halfYear = reducerPackage(CHIEFPATROLCHART_HALFYEAR)

export const riverreachPatrolChart = reducerPackage(RIVERREACHPATROLCHART)
export const riverreachPatrolTable = reducerPackage(RIVERREACHPATROLTABLE)



export const riverreachPatrolChart_halfYear = reducerPackage(RIVERREACHPATROLCHART_HALFYEAR)
export const riverreachPatrolTable_halfYear = reducerPackage(RIVERREACHPATROLTABLE_HALFYEAR)



export const chiefPatrolTwonChart = reducerPackage(CHIEFPATROLTWONCHART)
export const chiefPatrolVillageChart = reducerPackage(CHIEFPATROLVILLAGECHART)
export const areaStatisticsTable = reducerPackage(AREASTATISTICSTABLEACTION)


export const homechiefPatrolTable_fullYear = reducerPackage(HOMECHIEFPATROLTABLE_FULLYEAR)
export const homechiefPatrolChart_fullYear = reducerPackage(HOMECHIEFPATROLCHART_FULLYEAR)
export const homechiefPatrolTable_halfYear = reducerPackage(HOMECHIEFPATROLTABLE_HALFYEAR)
export const homechiefPatrolChart_halfYear = reducerPackage(HOMECHIEFPATROLCHART_HALFYEAR)


export const homechiefPatrolTableDis = reducerPackage(HOMECHIEFPATROLTABLE_DIS)
export const homechiefPatrolChartDis = reducerPackage(HOMECHIEFPATROLCHART_DIS)




export const homechiefPatrolTwonChart = reducerPackage(HOMECHIEFPATROLTWONCHART)
export const homechiefPatrolVillageChart = reducerPackage(HOMECHIEFPATROLVILLAGECHART)



