import React, { Component } from "react";
import moment from "moment";

export default class Date extends Component {
  state = {
    timer: moment().format("YYYY-MM-DD HH:mm:ss dddd")
  };

  componentDidMount = () => {
    this.interval = setInterval(() => this.tick(), 1000);
  };
  tick() {
    this.setState({
      timer: moment().format("YYYY-MM-DD HH:mm:ss dddd")
    });
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    return <div>{this.state.timer}</div>;
  }
}
