import {
  OUTFALLGOVERMENT} from 'actions/pzCustom/outfallGoverment';
  export const initialState = {
    isFetching: false,
    data: []
  };
  export const outfallGovermentList = (state = initialState, action) => {
    switch (action.type) {
      case `${OUTFALLGOVERMENT}_PENDING`:
        return Object.assign({}, initialState,{
          isFetching:true
        });
      case `${OUTFALLGOVERMENT}_SUCCESS`:
        return Object.assign({}, state, {
          isFetching: false,
          data: action.payload.data,
          allData:action.payload
        });
      default:
        return state;
    }
  };
  
  