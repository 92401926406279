import {issueManage} from 'api';

export const ISSUEMANAGELIST = 'ISSUEMANAGELIST';


export const tableAction = (data) => ({
  type: ISSUEMANAGELIST,
  payload: {
    promise: issueManage.list(data)
  }
});
