import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const { issueSupervise,issueSuperviseComplete,issueSuperviseHistory} = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({url: issueSupervise, method: 'get', data: params,});
}
/**
 * 查询已完成巡查问题督办列表
 */
export async function completeList(params) {
  return request({url: issueSuperviseComplete, method: 'get', data: params,});
}
/**
 * 查询已完成巡查问题督办列表
 */
export async function historyList(params) {
  return request({url: issueSuperviseHistory, method: 'get', data: params,});
}

/**
 * 创建
 */
export async function createItem(params) {
  return request({url: issueSupervise, method: 'post', data: params,});
}
