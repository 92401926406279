import {pollutionSource} from 'api';

export const POLLUTIONSOURCELIST = 'POLLUTIONSOURCELIST';


export const tableAction = (data) => ({
  type: POLLUTIONSOURCELIST,
  payload: {
    promise: pollutionSource.list(data)
  }
});
