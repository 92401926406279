import {supervise} from 'api';

export const SUPERVISELIST = 'SUPERVISELIST';


export const superviseAction = (data) => ({
  type: SUPERVISELIST,
  payload: {
    promise: supervise.superviseList(data)
  }
});
