import {
  TWONSEWAGETREATMENT
} from 'actions/pzCustom/twonSewageTreatment';
export const initialState = {
  isFetching: false,
  data: []
};
export const twonSewageTreatmentList = (state = initialState, action) => {
  switch (action.type) {
    case `${TWONSEWAGETREATMENT}_PENDING`:
      return Object.assign({}, initialState, {
        isFetching: true
      });
    case `${TWONSEWAGETREATMENT}_SUCCESS`:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.payload.data,
        allData: action.payload
      });
    default:
      return state;
  }
};

