import {list} from 'api/pzCustom/twonSewageTreatment';

export const TWONSEWAGETREATMENT = 'TWONSEWAGETREATMENT';

export const tableAction = (data) => ({
  type: TWONSEWAGETREATMENT,
  payload: {
    promise: list(data)
  }
});
