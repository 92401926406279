import {company} from 'api';

export const COMPANYLIST = 'COMPANYLIST';


export const companyAction = (data) => ({
  type: COMPANYLIST,
  payload: {
    promise: company.companyList(data)
  }
});
