import config from 'utils/apiconfig';
import request from 'utils/request';

const { api } = config;
const { company, companyId,enterprise } = api;

/**
 * 查询组织机构列表
 */
export async function companyList(params) {
  return request({ url: company, method: 'get', data: params, });
}
/**
 * 查询组织机构详情
 */
export async function getCompanyDetail(id) {
  return request({
    url: companyId, method: 'get', data: {
      id: id
    },
  });
}
/**
 * 编辑组织机构
 */
export async function editCompany(params) {
  return request({ url: companyId, method: 'put', data: params });
}
/**
 * 删除组织机构
 */
export async function deleteCompany(params) {
  return request({ url: company+ '/delete' , method: 'post', data: params });
}
/**
 * 创建组织机构
 */
export async function createCompany(params) {
  return request({ url: company, method: 'post', data: params, });
}


export async function getMeterenterprise(params) {
  return request({ url: enterprise, method: 'get', data: params })
}