export const amapKey = 'ccdd8e3f4a58670065be1fa4390c291f';
export const processTypesAll = {
  NEW: "未处理",
  APPLY: "处理",
  REJECT: "回退",
  ESCALATE: "上报",
  ESCALATE_DEPARTMENT: "上报到河长办",
  ESCALATE_CHIEF: "上报到河长",
  DELEGATE: "下派",
  DELEGATE_DEPARTMENT: "下派到河长办",
  DELEGATE_CHIEF: "下派到河长",
  TRANSFER: "转派",
  TRANSFER_DEPARTMENT: "转派到河长办",
  TRANSFER_CHIEF: "转派到河长",
  TRANSFER_RD: "转派到成员单位",
  END: "结案",
};
