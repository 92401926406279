import React, { Component } from "react";
import { Map, InfoWindow, Marker } from "react-amap";
import { Spin, Button, Icon, message } from "antd";
import styled from "styled-components";
import { amapKey } from "../../utils/constant";
import { getMapData } from "../../api/reach";
import { soket } from "../../api/commandCenter";

import "./index.less";
import RtcClient from "./rtcClient";
import imgA from "./images/sj.png";
import imgB from "./images/xj.png";
import imgC from "./images/xxj.png";
import imgD from "./images/cj.png";
import SP from "./images/sp.png";

import markerIconSj from "./images/s-sj.png";
import markerIconXj from "./images/s-xxj.png";
import markerIconXXj from "./images/s-xj.png";
import markerIconCj from "./images/s-cj.png";

import { patrolChiefList } from "../../api/patrolRecord";

import { getUserId } from "../../utils/auth";

const LoadingBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const legendData = [
  {
    imgs: imgA,
    name: "市级河长",
  },
  {
    imgs: imgB,
    name: "县级河长",
  },
  {
    imgs: imgC,
    name: "乡级河长",
  },
  {
    imgs: imgD,
    name: "村级河长",
  },
];

export default class MapCenter extends Component {
  constructor(props) {
    super(props);
    const _this = this;
    this.state = {
      cMapLoading: false,
      trtcProShow: false,

      visible: false,
      value: 1,
      position: {
        longitude: 120,
        latitude: 30,
      },
      chiefData: [],
      offset: [-4, -14],
    };
    this.mapEvents = {
      created: (mapInstance) => {
        this.setState({
          map: mapInstance,
        });
        mapInstance.setCenter([103.52279663, 29.26603487]);
        mapInstance.setZoom(9);
        new window.AMap.TileLayer.Satellite().setMap(mapInstance);
        new window.AMap.plugin("AMap.DistrictSearch", () => {
          _this.DistrictSearch = new window.AMap.DistrictSearch({
            extensions: "all",
            subdistrict: 0,
          }).search("乐山市", function (status, result) {
            var outer = [
              new window.AMap.LngLat(-360, 90, true),
              new window.AMap.LngLat(-360, -90, true),
              new window.AMap.LngLat(360, -90, true),
              new window.AMap.LngLat(360, 90, true),
            ];
            var holes = result.districtList[0].boundaries;
            var pathArray = [outer];
            pathArray.push.apply(pathArray, holes);
            var polygon = new window.AMap.Polygon({
              pathL: pathArray,
              strokeColor: "#6893FD",
              strokeWeight: 3,
              fillColor: "#00000090",
              fillOpacity: 1,
            });
            polygon.setPath(pathArray);
            mapInstance.add(polygon);
          });
        });
      },
    };

    this.windowEvents = {
      created: (iw) => { },
    };

    this.markerEvents = {
      created: (markerInstance) => {
        console.log("markerInstance: ", markerInstance);
      },
      click: (markerInstance) => {
        this.handleOpenInfoWindow(markerInstance.target.getExtData());
        this.state.map.setZoomAndCenter(13, [
          markerInstance.target.getExtData().trackPoint.longitude,
          markerInstance.target.getExtData().trackPoint.latitude,
        ]);
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.tableClickInfo &&
      nextProps.tableClickInfo &&
      nextProps.tableClickInfo.trackPoint !== null
    ) {
      this.state.map.setZoomAndCenter(13, [
        nextProps.tableClickInfo.trackPoint.longitude,
        nextProps.tableClickInfo.trackPoint.latitude,
      ]);
    }
  }

  componentDidMount() {
    this.loadData();
    this.interval = setInterval(() => this.loadData(), 1000 * 60);

    this.setState({
      cMapLoading: true,
    });

    getMapData().then((res) => {
      if (res.code == 200) {
        this.setState(
          {
            data: res.data,
            dataTotal: res.total,
          },
          () => {
            this.show();
          }
        );
      }
    });
  }
  loadData = () => {
    patrolChiefList().then((res) => {
      if (res.code == 200) {
        this.setState({
          chiefData: res.data && res.data.users ? res.data.users : [],
        });
      }
    });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  show = () => {
    // 地图中的河段
    if (!this.mapLayer) {
      var marker = new window.AMap.Marker({
        content: "  ",
        map: this.state.map,
      });

      var data = this.state.data
        .filter((d) => d.polyline && d.polyline.length > 0)
        .map((d) => {
          // const path = d.polyline.split(";").map((s) => {
          //   const pa = s.split(",");
          //   return new window.AMap.LngLat(pa[0], pa[1]);
          // });

          const path = d.polyline.split("|").map(sArr => {
            return sArr.split(";").map(s => {
              const pa = s.split(",");
              return new window.AMap.LngLat(pa[0], pa[1]);
            });
          });

          const ret = new window.AMap.Polyline({
            path: path,
            extData: d,
            zIndex: 151,
          });
          ret.on("mouseover", function (e) {
            ret.setOptions({
              cursor: "pointer",
              strokeColor: "#1a64ff",
              strokeWeight: 6,
              showDir: true,
            });
            marker.setPosition(e.lnglat);
            marker.setLabel({
              content: `<div class='marketInfoC'>${d.name}</div>`,
            });
            marker.show();
          });
          ret.on("mouseout", function (e) {
            ret.setOptions({
              cursor: "pointer",
              strokeColor: "#40a9ff",
              strokeWeight: 3,
              showDir: false,
            });
            marker.hide();
          });
          // ret.on("click", function(e) {
          //   that.props.showDataDetailInMapSearch({
          //     searchText: d.name,
          //     dataType: "riverReach",
          //     dataId: d.id
          //   })
          // })

          return ret;
        });
      this.state.map.add(data)
      this.mapLayer = new window.AMap.OverlayGroup(data);
      this.mapLayer.setOptions({
        cursor: "pointer",
        strokeColor: "#40a9ff",
        strokeWeight: 3,
        showDir: false,
      });
      this.mapLayer.setMap(this.state.map);
      if (this.mapLayer.getOverlays()) {
        this.setState({
          cMapLoading: false,
        });
      }
    }
  };
  handleCall = (id) => {
    soket({ id: id }).then((res) => {
      if (res.code == 200) {
        if (res.data == "用户不在线！") {
          message.info(`用户不在线，请稍后再试`);
          return;
        }
        if (res.data == "发送成功！") {
          this.setState(
            {
              visible: !this.state.visible,
              trtcProShow: !this.state.trtcProShow,
            },
            () => {
              this.RtcClient.opJoin();
            }
          );
        }
      }
    });
  };

  handleCancelCall = () => {
    this.setState({
      trtcProShow: !this.state.trtcProShow,
    });
  };
  handleOpenInfoWindow = (data) => {
    this.setState({
      visible: !this.state.visible,
      position: {
        longitude: data.trackPoint.longitude,
        latitude: data.trackPoint.latitude,
      },
      infoData: data,
    });
  };
  handleCloseInfoWindow = () => {
    this.setState({
      visible: !this.state.visible,
      infoData: null,
    });
  };

  handleData = (data) => {
    if (data == "取消") {
      message.info("用户已取消");
      this.handleCancelCall();
    }
  };

  render() {
    const { cMapLoading, trtcProShow, infoData, chiefData } = this.state;

    return (
      <Map
        amapkey={amapKey}
        mapStyle="amap://styles/772e82098363cfb5b4689e108a57a628"
        events={this.mapEvents}
        animateEnable={false}
        resizeEnable={true}
        loading={
          cMapLoading && (
            <LoadingBox>
              <Spin tip="加载中..." />
            </LoadingBox>
          )
        }
        version={"2.0"}
      >
        <InfoWindow
          position={this.state.position}
          visible={this.state.visible}
          isCustom={true}
          size={this.state.size}
          offset={this.state.offset}
          events={this.windowEvents}
        >
          <div className="infoWBox">
            <Icon
              type="close-square"
              className="close"
              style={{ color: "red" }}
              onClick={this.handleCloseInfoWindow}
            />
            <div className="title">{infoData && infoData.riverReachName}</div>
            <div className="infoWBoxContent">
              <div className="pic"></div>
              <div className="userInfo">
                <div className="userName">
                  姓名：{infoData && infoData.user.nickname}
                </div>
                <div className="pos">
                  职务：{infoData && infoData.user.position}
                </div>
                <div className="phoneNum">
                  电话：{infoData && infoData.user.mobile}
                </div>
              </div>
            </div>
            <div
              className="buttonGroup"
              onClick={() => this.handleCall(infoData && infoData.userId)}
            >
              <div className="video">
                <div className="wrapperImg">
                  <img src={SP} width="100%" height="100%" />
                </div>
                <div className="fontBoxSp">视频</div>
              </div>
            </div>
          </div>
        </InfoWindow>

        {trtcProShow ? (
          <RtcClient
            ref={(ref) => (this.RtcClient = ref)}
            cancelCall={this.handleCancelCall}
            roomId={infoData && infoData.userId}
          ></RtcClient>
        ) : null}

        {chiefData &&
          chiefData
            .filter(
              (pos) =>
                pos.trackPoint &&
                pos.trackPoint.longitude &&
                pos.trackPoint.latitude
            )
            .map((pos, indx) => {
              const pposition = {
                longitude: pos.trackPoint && pos.trackPoint.longitude,
                latitude: pos.trackPoint && pos.trackPoint.latitude,
              };
              const icon =
                pos.area.level == "CITY"
                  ? `${markerIconSj}`
                  : pos.area.level == "DISTRICT"
                  ? `${markerIconXj}`
                  : pos.area.level == "TOWN"
                  ? `${markerIconXXj}`
                  : pos.area.level == "VILLAGE"
                  ? `${markerIconCj}`
                  : null;
              return (
                <Marker
                  key={pos.userId}
                  events={this.markerEvents}
                  position={pposition}
                  icon={icon}
                  extData={pos}
                />
              );
            })}

        <div className="legendBox">
          {legendData &&
            legendData.map((v, indx) => (
              <div className="legendItem" key={indx}>
                <div className="legendIcon">
                  <img src={`${v.imgs}`} alt="" width="100%" height="100%" />
                </div>
                <div className="legendFont">{v.name}</div>
              </div>
            ))}
        </div>
      </Map>
    );
  }
}
