
import React, { Fragment } from "react";
import styled from "styled-components";
const KSIcon = styled.div`
  width: 30px;
  height: 15px;
  background-color: #A0971B;
  border-radius: 8px;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  color: #fff;
  margin-right: 6px;
`;
const KSsIcon = styled.div`
  width: 30px;
  height: 15px;
  background-color: #F7B500;
  border-radius: 8px;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  color: #fff;
  margin-right: 6px;
`;
const KQIcon = styled.div`
  width: 60px;
  height: 15px;
  background-color: #6DD400;
  border-radius: 8px;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  color: #fff;
  margin-right: 6px;
`;
const KSczIcon = styled.div`
  width: 60px;
  height: 15px;
  background-color: #0091FF;
  border-radius: 8px;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  color: #fff;
  margin-right: 6px;
`;
const XNIcon = styled.div`
  width: 30px;
  height: 15px;
  background-color: #4ADEBC;
  border-radius: 8px;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  color: #fff;
  margin-right: 6px;
`;

const WZIcon = styled.div`
  width: 30px;
  height: 15px;
  background-color: #C6C6C6;
  border-radius: 8px;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  color: #fff;
  margin-right: 6px;
`;
const CrossType = parameter => {
  return (
    <Fragment>
      {parameter.type == "CROSS_PROVINCE" ? (
        <KSIcon>跨省</KSIcon>
      ) : parameter.type == "CROSS_CITY" ? (
        <KSsIcon>跨市</KSsIcon>
      ) : parameter.type == "CROSS_DISTRICT" ? (
        <KQIcon>跨区(县)</KQIcon>
      ) : parameter.type == "CROSS_TOWN" ? (
        <KSczIcon>跨乡(镇)</KSczIcon>
      ) : parameter.type == "TOWN" ? (
        <XNIcon>乡内</XNIcon>
      ) : (
        <WZIcon>未知</WZIcon>
      )}
    </Fragment>
  );
};

export default CrossType;
