import {alarm} from 'api';

export const ALARMLIST = 'ALARMLIST';
export const ALARMLIST_NORELOAD = 'ALARMLIST_NORELOAD';

export const getAlarmList = (data) => ({
  type: ALARMLIST,
  payload: {
    promise: alarm.alarmList(data)
  }
});

export const getAlarmListNew = (data) => ({
  type: ALARMLIST_NORELOAD,
  payload: {
    promise: alarm.alarmList(data)
  }
});
