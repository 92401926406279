import React, { Component } from "react";

export default class Weather extends Component {
  state = {
    data: []
  };
  componentDidMount = () => {
    fetch(
      "https://restapi.amap.com/v3/weather/weatherInfo?key=6a2c5d20be5f985d759939a9d446d716&city=511100"
    )
      .then(res => res.json())
      .then(res => {
        if (res.status === "1") {
          this.setState({
            data: res.lives
          });
        }
      });
  };
  render() {
    return (
      <div>
        {this.state.data &&
          this.state.data.map(v => (
            <div key={v.adcode}>
              {v.city} {v.weather} {v.temperature}°C {v.winddirection}风
            </div>
          ))}
      </div>
    );
  }
}
