import React from 'react';
import {connect} from 'react-redux';
import {Form, Col, Input, Button, Select, message, InputNumber} from 'antd';
import UploadImg from '../../../components/upload/Uploads';
import UiModal from 'components/modalForm/UiModal';
import './index.less';
import {filterEmums, itemReplaceEmums, dateFormat} from "utils/filter";
import CascaderRegion from 'views/components/CascaderRegion';

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {span: 6},
  wrapperCol: {span: 16}
};
const formItemLayoutForLine = {
  labelCol: {span: 3},
  wrapperCol: {span: 21}
};
@Form.create()
@connect(
  (state) => {
    return ({
      emums: state.emums.data,
    });
  },
)
export default class FeedbackAddModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emums: {},
      detailVisible: false,
      modalData: {},
      folderList: []
    };
  }

  onCancel = () => {
    this.setState({detailVisible: false});
  };
  handleSubmit = () => {
    event.preventDefault();
    const _this = this;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {onOk} = _this.props;
        onOk && onOk(values, _this.props.modalData);
      }
    });
  };
  handleAccept = () => {
    const {onOk} = this.props;
    onOk && onOk(this.props.modalData);
  };

  componentDidMount() {
    setTimeout(() => {
      const ProposalAcceptType = filterEmums(this.props.emums, 'ProposalAcceptType');
      const ProposalSource = filterEmums(this.props.emums, 'ProposalSource');
      const ProposalStatus = filterEmums(this.props.emums, 'ProposalStatus');
      const ProposalType = filterEmums(this.props.emums, 'ProposalType');
      this.setState({emums: {ProposalAcceptType, ProposalSource, ProposalStatus, ProposalType}});
    }, 0);
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {modalData = {}} = this.props;
    let userFiles = '';
    let acceptFiles = '';
    let finishFiles = '';
    if (modalData.files && modalData.files.length > 0 && !modalData.files[0].name) {
      userFiles = modalData.files.map((item, index) => ({
        url: item, raw: index, uid: index,
        name: item, status: 'done'
      }));
    }
    if (modalData.proposalFinishes && modalData.proposalFinishes.length > 0) {
      finishFiles = modalData.proposalFinishes[0].files && modalData.proposalFinishes[0].files.map((item, index) => ({
        url: item, raw: index, uid: index,
        name: item, status: 'done'
      }));
    }
    if (modalData.proposalAccepts && modalData.proposalAccepts.length > 0) {
      acceptFiles = modalData.proposalAccepts[0].files && modalData.proposalAccepts[0].files.map((item, index) => ({
        url: item, raw: index, uid: index,
        name: item, status: 'done'
      }));
    }
    const requireStatus = !(this.props.modalKey === 'accept' || this.props.modalKey === 'look');
    const disabledStatus = this.props.modalKey === 'acceptStatus' || this.props.modalKey === 'look' || this.props.modalKey === 'onGoing';
    const disabledStatusTwo = this.props.modalKey === 'look' || this.props.modalKey === 'onGoing';
    return (
      <UiModal
        modalKey={'add'}
        visible={this.props.modalShow}
        title={this.props.title || '添加'}
        onCancel={this.props.onCancel}
        loading={this.props.loading}
        className='modal-middle feedback-add-modal'
        attr={{maskClosable: false}}
      >
        {this.props.modalKey === 'add' || this.props.modalKey === 'look' || this.props.modalKey === 'acceptStatus' || this.props.modalKey === 'onGoing' ?
          <div className='form-content clearfix'>
            {disabledStatus ? <div className='title'>用户意见
              {modalData.proposalFinishes.length > 0 ? <div className='finish-tag'>已办结</div> : ''}
            </div> : ''}
            <div>
              <div className='content-line clearfix'>
                <Col span={12}>
                  <FormItem
                    label='意见来源'
                    {...formItemLayout}
                    className='form-item-line'
                  >
                    {getFieldDecorator('source', {
                      initialValue: modalData.source,
                      rules: [{
                        required: this.props.hasDefault ? false : !disabledStatus,
                        message: '请选择意见来源!'
                      }],
                    })(
                      <Select disabled={disabledStatus || this.props.hasDefault}
                              style={{width: '100%'}}
                              placeholder={'请选择意见来源!'}>
                        {this.state.emums.ProposalSource && this.state.emums.ProposalSource.map(({value, name}) =>
                          <Select.Option key={value} value={value}>{name}</Select.Option>)}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={12} push={1}>
                  <FormItem
                    label='意见类型'
                    {...formItemLayout}
                    className='form-item-line'
                  >
                    {getFieldDecorator('type', {
                      initialValue: modalData.type,
                      rules: [{
                        required: !disabledStatus,
                        message: '请选择意见类型'
                      }],
                    })(
                      <Select disabled={disabledStatus}
                              style={{width: '100%'}}
                              placeholder={'请选择意见类型!'}>
                        {this.state.emums.ProposalType && this.state.emums.ProposalType.map(({value, name}) =>
                          <Select.Option key={value} value={value}>{name}</Select.Option>)}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </div>
              <div className='content-line clearfix'>
                <Col span={12}>
                  <FormItem
                    label='联系人'
                    {...formItemLayout}
                    className='form-item-line'
                  >
                    {getFieldDecorator('commitUser', {
                      initialValue: modalData.commitUser,
                      rules: [{
                        required: !disabledStatus,
                        message: '请输入联系人'
                      }],
                    })(
                      <Input disabled={disabledStatus}
                             placeholder='请输入联系人' style={{width: '100%'}}
                             className='hide-border'/>
                    )}
                  </FormItem>
                </Col>
                <Col span={12} push={1}>
                  <FormItem
                    label='联系电话'
                    {...formItemLayout}
                    className='form-item-line'
                  >
                    {getFieldDecorator('phone', {
                      initialValue: modalData.phone,
                      rules: [{
                        required: !disabledStatus,
                        message: '请输入联系电话'
                      }],
                    })(
                      <InputNumber disabled={disabledStatus}
                                   placeholder='请输入联系电话'
                                   style={{width: '100%'}}
                                   className='hide-border'/>
                    )}
                  </FormItem>
                </Col>
              </div>
              {this.props.modalKey === 'add' ? '' : <div className='content-line clearfix'>
                <Col span={12}>
                  <FormItem
                    label='提交人'
                    {...formItemLayout}
                    className='form-item-line'
                  >
                    {getFieldDecorator('user', {
                      initialValue: modalData.user && modalData.user.nickname,
                      rules: [{
                        required: !disabledStatus,
                        message: '请输入联系人'
                      }],
                    })(
                      <Input disabled={disabledStatus}
                             placeholder='请输入联系人' style={{width: '100%'}}
                             className='hide-border'/>
                    )}
                  </FormItem>
                </Col>
                <Col span={12} push={1}>
                  <FormItem
                    label='提交时间'
                    {...formItemLayout}
                    className='form-item-line'
                  >
                    {getFieldDecorator('date', {
                      initialValue: modalData.createTime,
                      rules: [{
                        required: !disabledStatus,
                        message: '请输入联系电话'
                      }],
                    })(
                      <Input disabled={disabledStatus}
                             placeholder='请输入联系电话' style={{width: '100%'}}
                             className='hide-border'/>
                    )}
                  </FormItem>
                </Col>
              </div>}
              <div className='content-line clearfix'>
                <FormItem
                  label='意见内容'
                  {...formItemLayoutForLine}
                  className='form-item-line'>
                  <Col span={24}>
                    {getFieldDecorator('content', {
                      initialValue: modalData.content,
                      rules: [{
                        required: !disabledStatus,
                        message: '请填写意见内容'
                      }],
                    })(
                      <Input.TextArea disabled={disabledStatus}
                                      placeholder='请输入'
                                      style={{width: '100%'}}
                                      className='hide-border'/>
                    )}
                  </Col>
                </FormItem>
              </div>
              <div className='content-line clearfix'>
                <FormItem
                  {...formItemLayoutForLine}
                  className='form-item-line'>
                  <Col span={21} offset={3}>
                    {getFieldDecorator('files', {
                      rules: [{required: false}],
                    })(
                      <UploadImg
                        className='upload-img'
                        lookKey={disabledStatus}
                        imageValues={userFiles}
                        icon={<i className='rvicon rvicon-xiangji'/>}
                      />
                    )}
                  </Col>
                </FormItem>
              </div>
            </div>
          </div> : ''}
        {disabledStatus ? <div className='white-line'/> : ''}
        {this.props.modalKey === 'acceptStatus' || this.props.modalKey === 'onGoing' || this.props.modalKey === 'look' && modalData.proposalAccepts.length > 0 ?
          <div className='form-content marin-top-10 clearfix'>
            {disabledStatusTwo ? <div className='title'>受理情况</div> : ''}
            <div>
              <div className='content-line clearfix'>
                <Col span={12}>
                  <FormItem
                    label='处理类型'
                    {...formItemLayout}
                    className='form-item-line'
                  >
                    {getFieldDecorator('type', {
                      initialValue: modalData.proposalAccepts && modalData.proposalAccepts[0] && modalData.proposalAccepts[0].type,
                      rules: [{
                        required: !disabledStatusTwo,
                        message: '请选择处理类型'
                      }],
                    })(
                      <Select disabled={disabledStatusTwo}
                              style={{width: '100%'}}
                              placeholder={'请选择信息类型!'}>
                        {this.state.emums.ProposalAcceptType && this.state.emums.ProposalAcceptType.map(({value, name}) =>
                          <Select.Option key={value} value={value}>{name}</Select.Option>
                          )}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                {disabledStatusTwo || this.props.modalKey === 'look' ? <Col span={12} push={1}>
                  <FormItem
                    label='受理时间'
                    {...formItemLayout}
                    className='form-item-line'
                  >
                    {getFieldDecorator('time', {
                      initialValue: modalData.proposalAccepts && modalData.proposalAccepts[0] && modalData.proposalAccepts[0].createTime || '',
                      rules: [{required: false, message: '请选择意见类型'}],
                    })(
                      <Input disabled={disabledStatusTwo} placeholder='请输入受理时间' style={{width: '100%'}}
                             className='hide-border'/>
                    )}
                  </FormItem>
                </Col> : ''}
              </div>
              <div className='content-line clearfix'>
                <FormItem
                  label='受理意见'
                  {...formItemLayoutForLine}
                  className='form-item-line'>
                  <Col span={24}>
                    {getFieldDecorator('acceptContent', {
                      initialValue: modalData.proposalAccepts && modalData.proposalAccepts[0] && modalData.proposalAccepts[0].content || '',
                      rules: [{
                        required: !disabledStatusTwo,
                        message: '请填写受理意见内容'
                      }],
                    })(
                      <Input.TextArea disabled={disabledStatusTwo}
                                      placeholder='请输入'
                                      style={{width: '100%'}}
                                      className='hide-border'/>
                    )}
                  </Col>
                </FormItem>
              </div>
              <div className='content-line clearfix'>
                <FormItem
                  {...formItemLayoutForLine}
                  className='form-item-line'>
                  <Col span={21} offset={3}>
                    {getFieldDecorator('acceptFiles', {
                      rules: [{required: false}],
                    })(
                      <UploadImg
                        className='upload-img'
                        lookKey={disabledStatusTwo}
                        imageValues={acceptFiles}
                        icon={<i className='rvicon rvicon-xiangji'/>}
                      />
                    )}
                  </Col>
                </FormItem>
              </div>
            </div>
          </div> : ''}
        {disabledStatusTwo ? <div className='white-line'/> : ''}
        {this.props.modalKey === 'onGoing' || this.props.modalKey === 'look' && modalData.proposalFinishes.length > 0 ?
          <div className='form-content marin-top-10 clearfix'>
            {this.props.modalKey === 'look' ? <div className='title'>办结情况</div> : ''}
            <div>
              <div className='content-line clearfix'>
                {this.props.modalKey === 'look' ? <Col span={24}>
                  <FormItem
                    label='办结时间'
                    {...formItemLayoutForLine}
                    className='form-item-line'
                  >
                    {getFieldDecorator('time', {
                      initialValue: modalData.proposalFinishes && modalData.proposalFinishes[0] && modalData.proposalFinishes[0].createTime || '',
                      rules: [{required: false, message: '请选择意见类型'}],
                    })(
                      <Input disabled={this.props.modalKey === 'look'} placeholder='办结时间' style={{width: '40%'}}
                             className='hide-border'/>
                    )}
                  </FormItem>
                </Col> : ''}
              </div>
              <div className='content-line clearfix'>
                <FormItem
                  label='办结内容'
                  {...formItemLayoutForLine}
                  className='form-item-line'>
                  <Col span={24}>
                    {getFieldDecorator('finishContent', {
                      initialValue: modalData.proposalFinishes && modalData.proposalFinishes[0] && modalData.proposalFinishes[0].content || '',

                      rules: [{
                        required: this.props.modalKey !== 'look',
                        message: '请填写意见内容'
                      }],
                    })(
                      <Input.TextArea disabled={this.props.modalKey === 'look'}
                                      placeholder='请输入'
                                      style={{width: '100%'}}
                                      className='hide-border'/>
                    )}
                  </Col>
                </FormItem>
              </div>
              <div className='content-line clearfix'>
                <FormItem
                  {...formItemLayoutForLine}
                  className='form-item-line'>
                  <Col span={21} offset={3}>
                    {getFieldDecorator('finishFiles', {
                      rules: [{required: false}],
                    })(
                      <UploadImg
                        className='upload-img'
                        lookKey={this.props.modalKey === 'look'}
                        imageValues={finishFiles}
                        icon={<i className='rvicon rvicon-xiangji'/>}
                      />
                    )}
                  </Col>
                </FormItem>
              </div>
            </div>
          </div> : ''}
        {this.props.modalKey !== 'look' ? <div className="buttons">
          {
            this.props.modalKey !== 'accept'
              ? <Button className='modal-button save-button' type="primary"
                        htmlType="submit" onClick={this.handleSubmit}>提交</Button> : ''}
          {this.props.modalKey === 'accept' ?
            <Button className='modal-button save-button' type="primary"
                    htmlType="submit" onClick={this.handleAccept}>受理</Button> : ''}
        </div> : ''}
      </UiModal>
    );
  }

  static defaultProps = {
    modalShow: false,
    onOk: () => null,
    onCancel: () => null,
    loading: false
  };
}



