import "./index.less";

import {
  Avatar,
  Badge,
  Divider,
  Dropdown,
  Icon,
  Layout,
  Menu,
  Popover,
  message,
} from "antd";

import AppQrcode from "../../components/AppQrcode/AppQrcode";
import FeedbackAdd from "../../systemManage/feedback/FeedbackAdd";
import Nav from "../menu";
import React from "react";
import ResetPwd from "./ResetPwd";
import { clearAuth } from "utils/auth";
import { connect } from "react-redux";
import { createItem as feedbackCreate } from "../../../api/feedback";
import { getQrCode } from "../../../api/basic";
import { getTips } from "actions/basic";
import history from "utils/history";
import { mineMessageList } from "api/messageNotification";

const { Header } = Layout;
@connect((state) => {
  return {
    loginUser: state.loginUser.data,
    loginUserAllData: state.loginUser,
    permissions: state.permission.data,
    tips: state.tips.data
  };
}, { getTips })
export default class LSTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      visible: false,
      messageTotal: 0,
      modalShow: false,
      showRwd: false,
    };
    this.messageUnradData = {
      status: "UNREAD",
      pageSize: 10,
      page: 1,
    };
  }

  getMineMessageList = () => {
    return new Promise((resolve, reject) => {
      mineMessageList(this.messageUnradData).then((res) => {
        if (res.code === 200) {
          resolve(res);
        } else {
          reject("消息列表获取失败");
        }
      });
    });
  };

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  componentDidMount() {
    const { permissions, getTips } = this.props
    if (permissions.includes("PERMS_MESSAGE_LIST")) {

      getTips(this.messageUnradData);
    }
    // * 获取下载安卓app二维码
    getQrCode({ appType: "ANDROID" }).then((res) => {
      if (res.code === 200) {
        this.setState({
          qrData: res.data,
        });
      }
    });
    // this.interval = setInterval(() => this.tick(), 1000 * 30);
  }
  // tick() {
  //   this.getMineMessageList().then((res) => {
  //     this.setState({ messageTotal: res.total });
  //   });
  // }
  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }

  onCancel = () => {
    this.setState({
      visible: false,
      showRwd: false,
    });
  };
  dropDownEvents = (item) => {
    if (item.key === "logOut") {
      location.href = "/login";
      clearAuth();
    }
    if (item.key === "resetPwd") {
      this.setState({
        visible: true,
      });
    }
    if (item.key === "feedback") {
      this.setState({ modalShow: true });
    }
  };
  goMessagePage = () => {
    sessionStorage.setItem("activeItem", "messageNotification");
    history.push("/messageNotification");
  };
  menu = (
    <Menu className="logOut" onClick={this.dropDownEvents}>
      <Menu.Item key="resetPwd">个人中心</Menu.Item>
      <Menu.Item key="feedback">意见反馈</Menu.Item>
      <Menu.Item key="logOut">退出</Menu.Item>
    </Menu>
  );
  onOk = (data) => {
    if (data.files) {
      data.files = data.files.map((ele) => {
        return ele.url;
      });
    }
    feedbackCreate(data).then((res) => {
      this.setState({
        modalLoading: false,
      });
      if (res.code === 200) {
        message.info("反馈成功!");
        this.setState({
          modalShow: false,
        });
      }
    });
  };
  showForm = () => {
    this.setState({
      showRwd: true,
    });
  };

  render() {

    const { tips } = this.props


    const loginUser =
      (this.props.loginUser && this.props.loginUser.principal) || {};
    return (
      <Header className="header">
        <div className="system-info">
          <div className="system-logo"></div>
          <div className="system-content">
            <div className="system-content-wrapper">
              <div className="system-content-top">
                <div className="system-content-top-wrapper">
                  <div className="system-content-title">
                    乐山市河（湖）长制管理信息平台
                  </div>
                  <div className="system-content-sub-title">
                    Smart River Chiefs Information Platform
                  </div>
                </div>
                <div className="system-content-userInfo">
                  {this.props.permissions &&
                    this.props.permissions instanceof Array &&
                    this.props.permissions.includes("PERMS_MANAGER_CENTER") &&
                    location.protocol == "https:" ? (
                    <a href="/commandCenter" target="_blank">
                      <div className="commandCenter">
                        <Icon type="desktop" />
                      </div>
                    </a>
                  ) : null}

                  <Popover
                    style={{ cursor: "pointer" }}
                    content={<AppQrcode appversion={this.state.qrData} />}
                    title="客户端下载"
                    placement="bottomRight"
                  >
                    <div className="QrCodeBox">
                      <Icon type="qrcode" />
                    </div>
                  </Popover>

                  {this.props.permissions &&
                    this.props.permissions instanceof Array &&
                    this.props.permissions.includes("PERMS_MESSAGE_LIST") ? (
                    <span
                      className="message-out"
                      onClick={this.goMessagePage}
                      style={{ cursor: "pointer" }}
                    >
                      <Badge count={tips.length || 0}>
                        <i className="rvicon rvicon-xiaoxi" />
                      </Badge>
                    </span>
                  ) : (
                    ""
                  )}

                  <Avatar
                    src={loginUser.avatarUrl}
                    className="user-img"
                    icon="user"
                  />
                  <Dropdown overlay={this.menu}>
                    <a className="ant-dropdown-link">
                      {loginUser.nickname} <Icon type="down" />
                    </a>
                  </Dropdown>
                </div>
              </div>
              <Divider className="divider"></Divider>
              <div className="system-content-bottom">
                <Nav permission={this.props.permissions}></Nav>
              </div>
            </div>
          </div>
        </div>
        <ResetPwd
          avatarUrl={loginUser.avatarUrl}
          onCancel={this.onCancel}
          visible={this.state.visible}
          showForm={this.showForm}
          showRwd={this.state.showRwd}
        />
        <FeedbackAdd
          onOk={this.onOk}
          modalShow={this.state.modalShow}
          onCancel={() => {
            this.setState({ modalShow: false });
          }}
          modalData={{ source: "WEB" }}
          modalKey={"add"}
          title={"添加"}
          hasDefault={true}
        />
      </Header>
    );
  }
}
