import React, { Component, Fragment } from "react";
import { Progress } from "antd";
import { riverCruisersList } from "../../api/commandCenter";
import { list } from "../../api/chiefManage";

export default class StatisticalDisplay extends Component {
  state = {
    total: [],
    rcList: [],
    users: []
  };
  componentDidMount = () => {
    this.getList()
    this.interval = setInterval(() => this.getList(), 1000 * 60);
  };

  getList = value => {
    list({value,pageSize:1}).then(res => {
      if (res.code === 200) {
        this.setState({
          total: res.total && res.total.toString().split("")
        });
      }
    });
    riverCruisersList(value).then(res => {
      if (res.code === 200) {
        this.setState({
          rcList: res.data.users.length.toString().split(""),
          users: res.data.users
        });
      }
    });
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { total, rcList, users } = this.state;
    return (
      <div className="centerUpBox">
        <div className="leftShowQuantity">
          <div className="riverTotal">
            <div className="quantityFont">河长总数</div>
            <div className="numPanelGroup">
              {total.map((item, index) => (
                <div className="numPanel" key={index}>
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div className="riverCruise">
            <div className="quantityFont">正在巡查</div>
            <div className="numPanelGroup">
              {rcList.length > 0
                ? rcList.map((rc, indx) => (
                    <div className="numPanel" key={indx}>
                      {rc}
                    </div>
                  ))
                : []}
            </div>
          </div>
        </div>
        <div className="rightShowChart">
          <div className="progressBar">
            <div className="progressBarItem">
              <div className="progressBarName">市级巡查</div>
              <Progress
                percent={
                  users && users.filter(f => f.area.level == "CITY").length
                }
                format={percent => `${percent}人`}
                status="active"
                strokeColor={{
                  from: "#FA9300",
                  to: "#FABC00"
                }}
              />
            </div>
            <div className="progressBarItem">
              <div className="progressBarName">县级巡查</div>
              <Progress
                percent={
                  users && users.filter(f => f.area.level == "DISTRICT").length
                }
                format={percent => `${percent}人`}
                status="active"
                strokeColor={{
                  from: "#00F7FA",
                  to: "#0091FF"
                }}
              />
            </div>
          </div>
          <div className="progressBar">
            <div className="progressBarItem">
              <div className="progressBarName">乡级巡查</div>
              <Progress
                percent={
                  users && users.filter(f => f.area.level == "TOWN").length
                }
                format={percent => `${percent}人`}
                status="active"
                strokeColor={{
                  from: "#0E8799",
                  to: "#34E95C"
                }}
              />
            </div>
            <div className="progressBarItem">
              <div className="progressBarName">镇级巡查</div>
              <Progress
                percent={
                  users && users.filter(f => f.area.level == "VILLAGE").length
                }
                format={percent => `${percent}人`}
                status="active"
                strokeColor={{
                  from: "#F94064",
                  to: "#F4850F"
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
