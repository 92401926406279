import {timeLimit} from 'api';

export const TIMELIMITLIST = 'TASKMELIST';


export const timeLimitAction = (data) => ({
  type: TIMELIMITLIST,
  payload: {
    promise: timeLimit.list(data)
  }
});

