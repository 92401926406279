import React, { Component } from "react";
import { List, Spin, Divider } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { wxRelated } from "../../api";
import "./index.less";
import history from "utils/history";

export default class News extends Component {
  state = {
    data: [],
    loading: false,
    hasMore: true,
  };

  componentDidMount = () => {
    this.fetchData((res) => {
      this.setState({
        data: res.data,
      });
    });
  };
  fetchData = (callback) => {
    wxRelated.list({ type: "WX" }).then((res) => {
      if (res.code === 200) {
        callback(res);
        // this.setState({
        //   data:res.data
        // })
      }
    });
  };

  handleInfiniteOnLoad = () => {
    let { data } = this.state;
    this.setState({
      loading: true,
    });
    if (data.length > 14) {
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }
    this.fetchData((res) => {
      data = data.concat(res.data);
      this.setState({
        data,
        loading: false,
      });
    });
  };

  handlePush = (data) => {
    history.push({
      pathname: "/wxNewsDe",
      state: { content: data },
    });
  };

  render() {
    return (
      <div className="infinite-container-list">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={this.handleInfiniteOnLoad}
          hasMore={!this.state.loading && this.state.hasMore}
          useWindow={false}
        >
          <List
            dataSource={this.state.data}
            renderItem={(item) => {
              return item.titleImg ? (
                <List.Item key={item.id}>
                  <a
                    href="/wxNewsDe"
                    onClick={() => this.handlePush(item.content)}
                  >
                    <div className="listWrapper">
                      <div className="leftBox">
                        <div className="title">{item.title}</div>
                        <div className="pbTime">
                          发布日期：{item.publishTime}
                        </div>
                      </div>
                      <div className="right">
                        <img src={item.titleImg} width="100%" height="100%" />
                      </div>
                    </div>
                    {/* <Divider style={{ margin: "3px 0" }}></Divider> */}
                  </a>
                </List.Item>
              ) : (
                <List.Item key={item.id}>
                  <a
                    href="/wxNewsDe"
                    onClick={() => this.handlePush(item.content)}
                  >
                    <div className="listWrapper">
                      <div className="leftBox">
                        <div className="title">{item.title}</div>
                        <div className="pbTime">
                          发布日期：{item.publishTime}
                        </div>
                      </div>
                    </div>
                  </a>
                </List.Item>
              );
            }}
          >
            {this.state.loading && this.state.hasMore && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </div>
    );
  }
}
