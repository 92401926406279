import { publicReport } from "api";

export const PUBLICREPORT = "PUBLICREPORT";
export const ACCEPT = "ACCEPT";
export const CLOSED = "CLOSED";
export const REPLYED = "REPLYED";
export const FINISHED = "FINISHED";

export const tableAction = data => ({
  type: PUBLICREPORT,
  payload: {
    promise: publicReport.list(data)
  }
});
export const accept = data => ({
  type: ACCEPT,
  payload: {
    promise: publicReport.accept(data)
  }
});
export const closed = data => ({
  type: CLOSED,
  payload: {
    promise: publicReport.closed(data)
  }
});
export const replyed = data => ({
  type: REPLYED,
  payload: {
    promise: publicReport.replyed(data)
  }
});
export const finished = data => ({
  type: FINISHED,
  payload: {
    promise: publicReport.finished(data)
  }
});
