import * as alarmConfiguration from "./alarmConfiguration";
import * as alarmList from "./alarm";
import * as blackSmellyWaterGoverment from "./pzCustom/blackSmellyWaterGoverment"; //沱江流域黑臭水体综合治理
import * as briefreport from './briefreport' //塘坝
import * as canal from "./canal";
import * as chiefManage from "./chiefManage";
import * as cleanUpOperation from "./pzCustom/cleanUpOperation"; //沱江干流和重要支流清淤任务进展
//彭州定制数据
import * as clearRiver from "./pzCustom/clearRiver";
import * as company from "./company";
import * as dailyHistory from "./dailyHistory"; //在线监测-每日
import * as dam from './dam' //塘坝
import * as deviceManage from "./deviceManage";
import * as deviceTypeManage from "./deviceTypeManage";
import * as deviceVender from "./deviceVender";
import * as engineerProject from "./engineerProject";
import * as feedback from "./feedback";
import * as filecon from './filecon' //塘坝
import * as greenWayConstruction from "./pzCustom/greenWayConstruction"; //沱江流域“七带”绿道建设任务进展情况
import * as hydropower from "./hydropower"; //公众上报
import * as intakeManage from "./intakeManage";
import * as issueAnalysis from "./issueAnalysis";
import * as issueManage from "./issueManage";
import * as issueSupervise from "./issueSupervise";
import * as joint from "./joint";
import * as lakeManage from "./lakeManage";
import * as login from "./login";
import * as loginlog from "./loginlog";
import * as messageNotification from "./messageNotification";
import * as monthreport from './monthreport' //塘坝
import * as municipalLevelManage from "./municipalLevelManage";
import * as org from "./org";
import * as outfallGoverment from "./pzCustom/outfallGoverment"; //沱江流域下河排水口综合治理
import * as patrol from "./patrol";
import * as patrolAnalysis from "./patrolAnalysis";
import * as patrolSupervise from "./patrolSupervise";
import * as pollutionSource from "./pollutionSource";
import * as publicBoardManage from './publicBoardManage' //河长公示牌
import * as publicReport from "./publicReport"; //公众上报
import * as pzblhsk from "./pzCustom/pzblhsk";
import * as pzplanlist from "./pzCustom/pzplanlist";
import * as pzsum from "./pzCustom/pzsum";
import * as pzzsstnum from "./pzCustom/pzzsstnum";
import * as pzzsstword from "./pzCustom/pzzsstword";
import * as ratingChief from "./ratingChief";
import * as reach from "./reach";
import * as realtimeMonitoring from "./realtimeMonitoring"; //在线监测
import * as reclaimedWaterReuseProject from "./pzCustom/reclaimedWaterReuseProject"; //沱江流域再生水利用工程建设任务进展
import * as region from "./region";
import * as reservoirManage from "./reservoirManage";
import * as river from "./river";
import * as riverGeneralSituation from "./riverGeneralSituaction"; //首页河湖概况
import * as riverSectionManage from './riverSectionManage' //河流断面
import * as riverSystem from "./riverSystem";
import * as role from "./role";
import * as sewageOutlet from "./sewageOutlet";
import * as sewagePipeNetwork from "./pzCustom/sewagePipeNetwork"; //沱江流域污水管网建设任务节点
import * as shareFolder from "./shareFolder";
import * as shareInfo from "./shareInfo";
import * as siteManage from "./siteManage";
import * as summaryOfGovernanceProjects from "./pzCustom/summaryOfGovernanceProjects"; //沱江流域水生态综合治理工程项目详细
import * as supervise from './supervise' //塘坝
import * as task from "./task";
import * as timeLimit from "./timeLimit";
import * as totalSummaryOfGovernanceProjects from "./pzCustom/totalSummaryOfGovernanceProjects"; //沱江流域水生态综合治理工程项目汇总
import * as twonSewageTreatment from "./pzCustom/twonSewageTreatment"; //沱江流域城镇污水处理设施建设任务节点
import * as user from "./user";
import * as videoSurveillanceList from './videoSurveillance' //视频监控数据
import * as waterDevice from "../modules/waterDevice/reducer";
import * as waterMeterList from "../modules/waterDevice/reducer"; //智能水表仪表管理
import * as wetlands from "./wetlands";

import { emums, layoutLoading, permission, tips, tipsNum } from "./basic";

import { combineReducers } from "redux";
import localForage from "localforage";
import { persistReducer } from "redux-persist";

const emumsConfig = {
  key: "emums",
  storage: localForage,
};
const permissionConfig = {
  key: "permission",
  storage: localForage,
};
const rootReducer = combineReducers({
  layoutLoading,
  emums: persistReducer(emumsConfig, emums),
  permission: persistReducer(permissionConfig, permission),
  tipsNum,
  tips,
  ...login,
  ...user,
  ...role,
  ...region,
  ...river,
  ...riverSystem,
  ...org,
  ...reach,
  ...deviceVender,
  ...deviceTypeManage,
  ...siteManage,
  ...deviceManage,
  ...pollutionSource,
  ...engineerProject,
  ...intakeManage,
  ...sewageOutlet,
  ...lakeManage,
  ...reservoirManage,
  ...company,
  ...chiefManage,
  ...loginlog,
  ...issueManage,
  ...patrol,
  ...clearRiver,
  ...pzsum,
  ...pzzsstnum,
  ...pzzsstword,
  ...pzblhsk,
  ...pzplanlist,
  ...blackSmellyWaterGoverment,
  ...outfallGoverment,
  ...sewagePipeNetwork,
  ...twonSewageTreatment,
  ...cleanUpOperation,
  ...reclaimedWaterReuseProject,
  ...greenWayConstruction,
  ...summaryOfGovernanceProjects,
  ...totalSummaryOfGovernanceProjects,
  ...task,
  ...patrolSupervise,
  ...ratingChief,
  ...messageNotification,
  ...shareFolder,
  ...shareInfo,
  ...issueSupervise,
  ...joint,
  ...timeLimit,
  ...patrolAnalysis,
  ...issueAnalysis,
  ...feedback,
  ...waterDevice,
  ...riverGeneralSituation,
  ...waterMeterList,
  ...municipalLevelManage,
  ...realtimeMonitoring,
  ...dailyHistory,
  ...alarmList,
  ...alarmConfiguration,
  ...canal,
  ...publicReport,
  ...wetlands,
  ...hydropower,
  ...videoSurveillanceList,
  ...alarmList,
  ...alarmConfiguration,
  ...publicBoardManage,
  ...riverSectionManage,
  ...dam,
  ...filecon,
  ...supervise,
  ...briefreport,
  ...monthreport,
});

export default rootReducer;
