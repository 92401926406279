import axios from 'axios';
import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {outfallGoverment, outfallGovermentId, outfallGovermentExport} = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({url: outfallGoverment, method: 'get', data: params});
}

/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({
    url: outfallGovermentId, method: 'get', data: {
      id: paramId
    },
  });
}

/**
 * 编辑
 */
export async function edit(params) {
  return request({url: outfallGovermentId, method: 'put', data: params});
}

/**
 * 删除
 */
export async function deleteItem(params) {
  return request({url: outfallGoverment+ '/delete' , method: 'post', data: params});
}

/**
 * 创建
 */
export async function createItem(params) {
  return request({url: outfallGoverment, method: 'post', data: params});
}

/**
 * 导出列表
 */
export async function exportList(params) {
  return axios({method: 'get', download: true, url: outfallGovermentExport, params: params, responseType: 'blob'});
}
