import {patrolPlan} from 'api';

export const PATROLPLANLIST = 'PATROLPLANLIST';


export const tableAction = (data) => ({
  type: PATROLPLANLIST,
  payload: {
    promise: patrolPlan.list(data)
  }
});
