import "./index.less";

import { Layout, Tabs, message } from "antd";

/* eslint-disable react/jsx-no-undef */
import React from "react";
import { Switch } from "react-router-dom";
import _ from "lodash";
import history from "utils/history";
import pageConfig from "../entry/pageConfig";
import { viewsConfig } from "../utils/viewTools";

const { TabPane } = Tabs;
const { Content } = Layout;

export default class TabsRouterPages extends React.Component {
  constructor(props) {
    const {
      location: { pathname }
    } = history;
    super(props);
    this.state = {
      ...this.setCurPanes(pathname, []),
      childRoutes: null
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { pathname }
    } = history;
    if (pathname) {
      const newState = this.setCurPanes(pathname);
      this.setState(newState);
    }
  }

  setCurPanes = (pathName, _panes) => {
    let otherPageProps = {};
    if (history && history.location && history.location.state) {
      otherPageProps = history.location.state;
    }
    let childRoutes =
      this.state && this.state.childRoutes ? this.state.childRoutes : null;
    if (!childRoutes) {
      childRoutes = Object.keys(pageConfig).map((pageKey, index) => {

        const Page = viewsConfig(pageConfig[pageKey]);

        let hisParams = {};
        if (pageKey == pathName) {
          hisParams = otherPageProps;
        }

        return {
          key: pageKey,
          title: _.last(pageConfig[pageKey].breadName),
          _route: Page,
          route: (
            <Page
              tabRm={() => {
                this.onRemove(pageKey);
              }}
              hisParams={{ ...otherPageProps }}
            />
          )
        };
      });
    } else {
      const targetRoute = childRoutes.find(r => r.key == pathName);
      if (targetRoute) {
        const PPage = targetRoute._route;
        targetRoute.route = (
          <PPage
            tabRm={() => {
              this.onRemove(targetRoute.key);
            }}
            hisParams={{ ...otherPageProps }}
          />
        );
      }
    }

    let result = null;

    let panes = _panes || this.state.panes;
    const existPane = panes.some(item => item.key === pathName);
    if (existPane) {
      result = {
        activeKey: pathName,
        panes,
        noMatch: false,
        childRoutes
      };
    } else {
      const nextPanes = (childRoutes ? childRoutes : []).filter(
        item => item.key === pathName
      );
      if (nextPanes.length) {
        result = {
          activeKey: pathName,
          panes: panes.concat(nextPanes),
          noMatch: false,
          childRoutes
        };
      } else {
        result = {
          activeKey: pathName,
          panes: panes,
          noMatch: true,
          childRoutes
        };
      }
    }
    const activePane = panes.find(item => item.key === pathName);

    if (activePane) {
      activePane.route = childRoutes.find(k => k.key == pathName).route;
    }
    if (result.panes.length >= 6) {
      message.info("已达到标签页数上限，请先关闭部分标签。");
      result = {
        activeKey: panes[panes.length - 1] && panes[panes.length - 1].key,
        panes: panes,
        noMatch: true,
        childRoutes
      };
    }
    return result;
  };
  onChange = activeKey => {

    history.push(activeKey);

  };

  onRemove = targetKey => {
    let { activeKey, panes } = this.state;
    let lastIndex;
    panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newpanes = panes.filter(pane => pane.key !== targetKey);
    if (newpanes.length && activeKey === targetKey) {
      activeKey = lastIndex >= 0 ? newpanes[lastIndex].key : newpanes[0].key;
    }
    this.setState({ panes: newpanes, activeKey }, () => {
      if (activeKey !== targetKey) this.onChange(activeKey);
    });
  };

  onRemoveOther = () => {
    let { activeKey, panes } = this.state;
    const newpanes = panes.filter(pane => pane.key === activeKey);
    this.setState({ panes: newpanes });
  };

  onRemoveAll = () => {
    this.setState({ panes: [], activeKey: null });
  };

  onTabsActions = ({ key }) => {
    let { activeKey } = this.state;
    switch (key) {
      case "close":
        this.onRemove(activeKey);
        break;
      case "closeother":
        this.onRemoveOther();
        break;
      case "closeall":
        this.onRemoveAll();
        break;
      default:
        break;
    }
  };
  render() {
    let panesAfter = this.state.panes.map((v, indx) => {
      return {
        ...v,
        closable: this.state.panes.length >= 2 ? true : false
      };
    });
    return (
      <Layout className="full-layout tabs-layout">
        <Content>
          <Switch>
            <Tabs
              hideAdd
              onChange={this.onChange}
              activeKey={this.state.activeKey}
              type="editable-card"
              className="full-page-tabs-content"
              tabBarGutter={4}
              onEdit={this.onRemove}
            // tabBarExtraContent={
            //   <Dropdown
            //     overlay={
            //       <Menu onClick={this.onTabsActions}>
            //         <Menu.Item key="close">关闭当前</Menu.Item>
            //         <Menu.Item key="closeother">关闭其它</Menu.Item>
            //         <Menu.Item key="closeall">关闭所有</Menu.Item>
            //       </Menu>
            //     }
            //   >
            //     <Button type="primary" ghost>
            //       操作
            //       <Icon type="down" />
            //     </Button>
            //   </Dropdown>
            // }
            >
              {panesAfter.map((pane, indx) => (
                <TabPane
                  tab={pane.title}
                  key={pane.key}
                  closable={pane.closable}
                  forceRender={true}
                >
                  {pane.route}
                </TabPane>
              ))}
            </Tabs>
          </Switch>
        </Content>
      </Layout>
    );
  }
}
