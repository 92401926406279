import config from "../utils/apiconfig";
import request from "../utils/request";

const { api } = config;
const {
    reach,
    reachId,
    reachMapData,
    reachSurvey,
    treeRiver,
    treeCanal,
    channelMap,
    channelAllId,
    channelAllMap,
    lakeId,
    lakeAllId,
    treeLake,
    lakeAllMap,
    treeReservoir,
    reservoirAllMap,
    reservoirAllId,
    treeWetlands,
    wetlandIdAll,

    reachArchives,
    reachStrategy,
    channelArchives,
    channelStrategy,
    reservoirArchives,
    reservoirStrategy,
    lakeArchives,
    lakeStrategy,
    wetlandArchives,
    wetlandStrategy,
    resourceCenterMore,
} = api;

/**
 * 查询河流列表
 */
export async function list(params) {
    return request({ url: reach, method: "get", data: params });
}

export async function getMapData(params) {
    return request({ url: reachMapData, method: "get", data: params });
}

export async function getCanalMapData(params) {
    return request({ url: channelAllMap, method: "get", data: params });
}
export async function getLakeMapData(params) {
    return request({ url: lakeAllMap, method: "get", data: params });
}
export async function getReservoirMapData(params) {
    return request({ url: reservoirAllMap, method: "get", data: params });
}

/**
 * 查询河流详情
 */
export async function getDetail(paramId) {
    return request({
        url: reachId,
        method: "get",
        data: {
            id: paramId,
        },
    });
}

/**
 * 查询河流详情
 */
export async function getChannelDetail(paramId) {
    return request({
        url: channelAllId,
        method: "get",
        data: {
            id: paramId,
        },
    });
}
/**
 * 查询河流详情
 */
export async function getLakeDetail(paramId) {
    return request({
        url: lakeAllId,
        method: "get",
        data: {
            id: paramId,
        },
    });
}
/**
 * 查询河流详情
 */
export async function getReservoirDetail(paramId) {
    return request({
        url: reservoirAllId,
        method: "get",
        data: {
            id: paramId,
        },
    });
}
/**
 * 查询河流详情
 */
export async function getWetlandDetail(paramId) {
    return request({
        url: wetlandIdAll,
        method: "get",
        data: {
            id: paramId,
        },
    });
}
/**
 * 编辑河流
 */
export async function edit(params) {
    return request({ url: reachId, method: "put", data: params });
}

export async function survey(params) {
    return request({ url: reachSurvey, method: "put", data: params });
}
/**
 * 删除河流
 */
export async function deleteItem(params) {
    return request({ url: reach, method: "delete", data: params });
}
/**
 * 创建河流
 */
export async function createItem(params) {
    return request({ url: reach, method: "post", data: params });
}

/**
 * 河流河段树
 */
export async function TreeList(params) {
    return request({ url: treeRiver, method: "get", data: params });
}
/**
 * 渠道渠段树
 */
export async function TreeCanalList(params) {
    return request({ url: treeCanal, method: "get", data: params });
}
/**
 * 湖泊湖片树
 */
export async function TreeLakeList(params) {
    return request({ url: treeLake, method: "get", data: params });
}
/**
 * 水库库片树
 */
export async function TreeReservoirList(params) {
    return request({ url: treeReservoir, method: "get", data: params });
}
/**
 * 湿地片树
 */
export async function TreeWetlandsList(params) {
    return request({ url: treeWetlands, method: "get", data: params });
}

/**
 * 更新一河一档
 */
export async function UpdateReachArchives(param) {
    return request({
        url: reachArchives,
        method: "put",
        data: {
            id: param.id,
            ...param,
        },
    });
}
/**
 * 更新一河一策
 */
export async function UpdateReachStrategy(param) {
    return request({
        url: reachStrategy,
        method: "put",
        data: {
            id: param.id,
            ...param,
        },
    });
}

/**
 * 更新一河一 档
 */
export async function UpdateChannelAllArchives(param) {
    return request({
        url: channelArchives,
        method: "put",
        data: {
            id: param.id,
            ...param,
        },
    });
}
/**
 * 更新一河一策
 */
export async function UpdateChannelAllStrategy(param) {
    return request({
        url: channelStrategy,
        method: "put",
        data: {
            id: param.id,
            ...param,
        },
    });
}

/**
 * 更新一河一档
 */
export async function UpdateReservoirArchives(param) {
    return request({
        url: reservoirArchives,
        method: "put",
        data: {
            id: param.id,
            ...param,
        },
    });
}
/**
 * 更新一河一策
 */
export async function UpdateReservoirStrategy(param) {
    return request({
        url: reservoirStrategy,
        method: "put",
        data: {
            id: param.id,
            ...param,
        },
    });
}

/**
 * 更新一河一策
 */
export async function UpdateLakeArchives(param) {
    return request({
        url: lakeArchives,
        method: "put",
        data: {
            id: param.id,
            ...param,
        },
    });
}
/**
 * 更新一河一档
 */
export async function UpdateLakeStrategy(param) {
    return request({
        url: lakeStrategy,
        method: "put",
        data: {
            id: param.id,
            ...param,
        },
    });
}

/**
 * 更新一河一策
 */
export async function UpdateWetlandArchives(param) {
    return request({
        url: wetlandArchives,
        method: "put",
        data: {
            id: param.id,
            ...param,
        },
    });
}
/**
 * 更新一河一档
 */
export async function UpdateWetlandStrategy(param) {
    return request({
        url: wetlandStrategy,
        method: "put",
        data: {
            id: param.id,
            ...param,
        },
    });
}

export async function getResourceCenterMoreApi(param) {
    return request({ url: resourceCenterMore, method: "get", data: param });
}