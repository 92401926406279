import config from "utils/apiconfig";
import request from "utils/request";

const { api } = config;
const { chief, chiefId, creactRelated, deleteRelated } = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({ url: chief, method: "get", data: params });
}
/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({
    url: chiefId,
    method: "get",
    data: {
      id: paramId
    }
  });
}
/**
 * 编辑
 */
export async function edit(params) {
  return request({ url: chiefId, method: "put", data: params });
}
/**
 * 删除
 */
export async function deleteItem(params) {
  return request({ url: chief, method: "delete", data: params });
}
/**
 * 创建
 */
export async function createItem(params) {
  return request({ url: chief, method: "post", data: params });
}

/**
 * 创建关联
 */
export async function createRelateds(params) {
  return request({ url: creactRelated, method: "post", data: params });
}

/**
 * 删除关联
 */
export async function deleteRelateds(params) {
  return request({ url: deleteRelated, method: "DELETE", data: params });
}
