import {monthreport} from 'api';

export const MONTHREPORTLIST = 'MONTHREPORTLIST';


export const monthreportAction = (data) => ({
  type: MONTHREPORTLIST,
  payload: {
    promise: monthreport.monthreportList(data)
  }
});
