import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const { patrolSupervise} = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({url: patrolSupervise, method: 'get', data: params,});
}

/**
 * 创建
 */
export async function createItem(params) {
  return request({url: patrolSupervise, method: 'post', data: params,});
}
