import config from "../utils/apiconfig";
import request from "../utils/request";

const { api } = config;
const {
  publicReport,
  publicReportId,
  publicReportMapData,
  publicReportReply,
  publicReportAccept,
  publicReportClosed,
  publicReportReplyed,
  publicReportFinished
} = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({ url: publicReport, method: "get", data: params });
}

export async function getMapData(params) {
  return request({ url: publicReportMapData, method: "get", data: params });
}
/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({
    url: publicReportId,
    method: "get",
    data: {
      id: paramId
    }
  });
}
/**
 * 编辑
 */
export async function edit(params) {
  return request({ url: publicReportId, method: "put", data: params });
}
/**
 * 删除
 */
export async function deleteItem(params) {
  return request({ url: publicReport, method: "delete", data: params });
}
/**
 * 创建
 */
export async function createItem(params) {
  return request({ url: publicReport, method: "post", data: params });
}
/**
 * 受理
 */
export async function accept(params) {
  return request({ url: publicReportAccept, method: "post", data: params });
}
/**
 * 关闭
 */
export async function closed(params) {
  return request({ url: publicReportClosed, method: "post", data: params });
}
/**
 * 回复
 */
export async function replyed(params) {
  return request({ url: publicReportReplyed, method: "post", data: params });
}
/**
 * 结案
 */
export async function finished(params) {
  return request({ url: publicReportFinished, method: "post", data: params });
}

export async function listPublicReportReply(params) {
  return request({ url: publicReportReply, method: "get", data: params });
}
