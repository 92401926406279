import {org} from 'api';

export const ORGLIST = 'ORGLIST';


export const orgAction = (data) => ({
  type: ORGLIST,
  payload: {
    promise: org.orgList(data)
  }
});
