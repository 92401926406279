import {list} from 'api/pzCustom/reclaimedWaterReuseProject';

export const RECLAIMEDWATERREUSEPROJECT = 'RECLAIMEDWATERREUSEPROJECT';

export const tableAction = (data) => ({
  type: RECLAIMEDWATERREUSEPROJECT,
  payload: {
    promise: list(data)
  }
});
