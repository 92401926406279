import { reservoirManage } from "api";

export const RESERVOIRMANAGELIST = "RESERVOIRMANAGELIST";
export const RESERVOIRALLLISTDETAILS = "RESERVOIRALLLISTDETAILS";
export const RESERVOIR = "RESERVOIR";
export const RESERVOIRALL = "RESERVOIRALL";

export const tableAction = data => ({
  type: RESERVOIRMANAGELIST,
  payload: {
    promise: reservoirManage.list(data)
  }
});
export const reservoirList = data => ({
  type: RESERVOIR,
  payload: {
    promise: reservoirManage.reservoirList(data)
  }
});
export const reservoirAllList = data => ({
  type: RESERVOIRALL,
  payload: {
    promise: reservoirManage.reservoirAllList(data)
  }
});
