import {briefreport} from 'api';

export const BRIEFREPORTLIST = 'BRIEFREPORTLIST';


export const briefreportAction = (data) => ({
  type: BRIEFREPORTLIST,
  payload: {
    promise: briefreport.briefreportList(data)
  }
});
