import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {riverSystem,riverSystemId} = api;

/**
 * 查询水系列表
 */
export async function riverSystemList(params) {
  return request({url: riverSystem, method: 'get', data: params,});
}
/**
 * 查询水系详情
 */
export async function getRiverSystemDetail(id) {
  return request({url: riverSystemId, method: 'get', data: {
      riverSystemId:id
    },});
}
/**
 * 编辑水系
 */
export async function editRiverSystem(params) {
  return request({url: riverSystemId, method: 'put', data: params});
}
/**
 * 删除用户
 */
export async function deleteRiverSystem(params) {
  return request({url: riverSystem+ '/delete' , method: 'post', data:params});
}
/**
 * 创建用户
 */
export async function createRiverSystem(params) {
  return request({url: riverSystem, method: 'post', data: params,});
}
