import config from "utils/apiconfig";
import request from "utils/request";

const { api } = config;
const { dam, damId } = api;

/**
 * 查询河流列表
 */
export async function damList(params) {
  return request({ url: dam, method: "get", data: params });
}

/**
 * 查询河流详情
 */
export async function damDetail(id) {
  return request({
    url: damId,
    method: "get",
    data: {
      damId: id
    }
  });
}
