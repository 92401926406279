import React, { Component } from "react";
import { DatePicker } from "antd";
import moment from 'moment'
const { RangePicker } = DatePicker;

export default class SelectDate extends Component {
  render() {
    return (
      <RangePicker
        dropdownClassName="dateClass"
        {...this.props}
        defaultValue={[
          moment(moment().format('YYYY-MM-DD'), "YYYY-MM-DD"),
          moment(moment().format('YYYY-MM-DD'), "YYYY-MM-DD"),
        ]}
      />
    );
  }
}
