import {siteManage} from 'api';

export const SITEMANAGELIST = 'SITEMANAGELIST';


export const tableAction = (data) => ({
  type: SITEMANAGELIST,
  payload: {
    promise: siteManage.list(data)
  }
});
