import config from "utils/apiconfig"
import request from "utils/request"

const { api } = config
const {
  chiefPatrolTable,
  chiefPatrolChart,
  riverreachPatrolTable,
  riverreachPatrolChart,
  chiefPatrolTwonChart,
  chiefPatrolVillageChart,
  areaStatisticsTable
} = api

/**
 * 查询巡查河长统计
 */
export async function chiefPatrolTableList(params) {
  return request({ url: chiefPatrolTable, method: "get", data: params })
}

export async function chiefPatrolChartList(params) {
  return request({ url: chiefPatrolChart, method: "get", data: params })
}

/**
 * 查询巡查资源统计
 */
export async function riverreachPatrolTableList(params) {
  return request({ url: riverreachPatrolTable, method: "get", data: params })
}

export async function riverreachPatrolChartList(params) {
  return request({ url: riverreachPatrolChart, method: "get", data: params })
}

// 查询乡镇图表数据
export async function chiefPatrolTwonChartList(params) {
  return request({ url: chiefPatrolTwonChart, method: "get", data: params })
}
//查询村图表数据
export async function chiefPatrolVillageChartList(params) {
  return request({ url: chiefPatrolVillageChart, method: "get", data: params })
}

//巡查统计按区域查询数据
export async function areaStatisticsTableList(params) {
  return request({ url: areaStatisticsTable, method: "get", data: params })
}
