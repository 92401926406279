import {riverSectionManage} from 'api';

export const RIVERSECTIONMANAGE = 'RIVERSECTIONMANAGE';


export const tableAction = (data) => ({
  type: RIVERSECTIONMANAGE,
  payload: {
    promise: riverSectionManage.list(data)
  }
});


