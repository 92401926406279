import {list} from 'api/pzCustom/greenWayConstruction';

export const GREENWAYCONSTRUCTION = 'GREENWAYCONSTRUCTION';

export const tableAction = (data) => ({
  type: GREENWAYCONSTRUCTION,
  payload: {
    promise: list(data)
  }
});
