import axios from 'axios';
import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {pzplanlist, pzplanlistId,pzplanlistExport} = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({url: pzplanlist, method: 'get', data: params,});
}

/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({
    url: pzplanlistId, method: 'get', data: {
      id: paramId
    },
  });
}

/**
 * 编辑
 */
export async function edit(params) {
  return request({url: pzplanlistId, method: 'put', data: params});
}

/**
 * 删除
 */
export async function deleteItem(params) {
  return request({url: pzplanlist+ '/delete' , method: 'post', data: params});
}

/**
 * 创建
 */
export async function createItem(params) {
  return request({url: pzplanlist, method: 'post', data: params,});
}

/**
 * 导出列表
 */
export async function exportList(params) {
  return axios({method: 'get', download: true, url: pzplanlistExport, params: params, responseType: 'blob'});
}
