import {sewageOutlet} from 'api';

export const SEWAGEOUTLETLIST = 'INTAKEMANAGELIST';


export const tableAction = (data) => ({
  type: SEWAGEOUTLETLIST,
  payload: {
    promise: sewageOutlet.list(data)
  }
});
