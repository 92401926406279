import {messageNotification} from 'api';

export const MESSAGENOTIFICATION = 'MESSAGENOTIFICATION';


export const tableAction = (data) => ({
  type: MESSAGENOTIFICATION,
  payload: {
    promise: messageNotification.mineMessageList(data)
  }
});
