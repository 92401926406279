const defaultPages = {
  "/index": {
    hideBread: true,
    breadName: ["首页"],
    loader: () => import("../views/index/index.js"),
  },
  "/onemap": {
    hideBread: true,
    breadName: ["一张图"],
    loader: () => import("../views/oneMap"),
  },
  "/userManage": {
    breadName: ["基础信息 ", "用户配置", "用户管理"],
    hideBread: true,
    loader: () => import("../views/systemManage/userManage"),
  },
  "/roleManage": {
    breadName: ["基础信息 ", "用户配置", "角色管理"],
    hideBread: true,
    loader: () => import("../views/systemManage/roleManage"),
  },
  "/moduleManage": {
    hideBread: true,
    loader: () => import("../views/systemManage/moduleManage"),
    breadName: ["基础信息 ", "模块管理"],
  },
  "/versionManage": {
    breadName: ["基础信息 ", "版本管理"],
    hideBread: true,
    loader: () => import("../views/systemManage/versionManage"),
  },
  "/logManage": {
    breadName: ["基础信息 ", "操作日志管理"],
    hideBread: true,
    loader: () => import("../views/systemManage/logManage"),
  },
  "/regionManage": {
    breadName: ["基础信息 ", "系统中心", "行政区域管理"],
    hideBread: true,
    loader: () => import("../views/systemManage/regionManage"),
  },
  "/riverSystem": {
    breadName: ["基础信息 ", "河湖基础信息", "水系信息"],
    hideBread: true,
    loader: () => import("../views/riverBasic/riverSystem"),
  },
  "/riverMessage": {
    breadName: ["基础信息 ", "河湖基础信息", "河流信息"],
    hideBread: true,
    loader: () => import("../views/riverBasic/riverMessage"),
  },
  "/canalMessage": {
    breadName: ["基础信息 ", "河湖基础信息", "渠道信息"],
    hideBread: true,
    loader: () => import("../views/riverBasic/canalMessage"),
  },
  "/lakeMessage": {
    breadName: ["基础信息 ", "河湖基础信息", "湖泊信息"],
    hideBread: true,
    loader: () => import("../views/riverBasic/lakeMessage"),
  },
  "/lakeManage": {
    breadName: ["基础信息 ", "河湖基础信息", "湖片湖长管理"],
    hideBread: true,
    loader: () => import("../views/riverBasic/lakeManage"),
  },
  "/reachManage": {
    breadName: ["基础信息 ", "河湖基础信息", "河段河长管理"],
    hideBread: true,
    loader: () => import("../views/riverBasic/reachManage"),
  },
  "/canalManage": {
    breadName: ["基础信息 ", "河湖基础信息", "渠段渠长管理"],
    hideBread: true,
    loader: () => import("../views/riverBasic/canalManage"),
  },

  /**
   * 2021-12-20 新增需求 start
   */
  "/damMessage": {
    breadName: ["基础信息 ", "河湖基础信息", "塘坝管理"],
    hideBread: true,
    loader: () => import("../views/riverBasic/damMessage"),
  },

  // **********************************************河档河册***********************************************************//

  "/riverArchivesReachManage": {
    breadName: ["河档河册 ", "河段"],
    hideBread: true,
    loader: () => import("../views/riverArchives/riverArchivesReachManage"),
  },

  "/riverArchivesCanalManage": {
    breadName: ["河档河册 ", "渠段"],
    hideBread: true,
    loader: () => import("../views/riverArchives/riverArchivesCanalManage"),
  },

  "/riverArchivesLakeManage": {
    breadName: ["河档河册 ", "湖片"],
    hideBread: true,
    loader: () => import("../views/riverArchives/riverArchivesLakeManage"),
  },

  "/riverArchivesReservoirManage": {
    breadName: ["河档河册 ", "库片"],
    hideBread: true,
    loader: () => import("../views/riverArchives/riverArchivesReservoirManage"),
  },

  "/riverArchivesWetlandsManage": {
    breadName: ["河档河册 ", "湿地片"],
    hideBread: true,
    loader: () => import("../views/riverArchives/riverArchivesWetlandsManage"),
  },

  // ******************************************************河档河册***************************************************//

  "/organizationManage": {
    breadName: ["基础信息 ", "用户配置", "组织机构管理"],
    hideBread: true,
    loader: () => import("../views/systemManage/organizationManage"),
  },
  "/reservoirMessage": {
    breadName: ["基础信息 ", "河湖基础信息", "水库信息"],
    hideBread: true,
    loader: () => import("../views/riverBasic/reservoirMessage"),
  },
  "/reservoirManage": {
    breadName: ["基础信息 ", "河湖基础信息", "库片库长管理"],
    hideBread: true,
    loader: () => import("../views/riverBasic/reservoirManage"),
  },
  "/wetlandsMessage": {
    breadName: ["基础信息 ", "河湖基础信息", "湿地信息"],
    hideBread: true,
    loader: () => import("../views/riverBasic/wetlandsMessage"),
  },
  "/wetlandsManage": {
    breadName: ["基础信息 ", "河湖基础信息", "湿地片湿地长管理"],
    hideBread: true,
    loader: () => import("../views/riverBasic/wetlandsManage"),
  },
  "/deviceVender": {
    breadName: ["基础信息 ", "监控管理", "设备厂家管理"],
    hideBread: true,
    loader: () => import("../views/device/deviceVender"),
  },
  "/deviceTypeManage": {
    breadName: ["基础信息 ", "监控管理", "设备型号管理"],
    hideBread: true,
    loader: () => import("../views/device/deviceTypeManage"),
  },
  "/deviceManage": {
    breadName: ["基础信息", "监控管理", "设备管理"],
    hideBread: true,
    loader: () => import("../views/device/deviceManage"),
  },
  "/videoSurveillance": {
    breadName: ["在线监测", "视频监控"],
    hideBread: true,
    loader: () => import("../views/video/videoSurveillance")
  },
  "/hydrologicalMonitoring": {
    breadName: ["在线监测", "水文监测"],
    hideBread: true,
    loader: () => import("../views/video/hydrologicalMonitoring")
  },
  "/hydrologicalMonitoringHistoricalData": {
    breadName: ["在线监测", "水文监测历史数据"],
    hideBread: true,
    loader: () => import("../views/video/hydrologicalMonitoringHistoricalData")
  },
  "/siteManage": {
    breadName: ["基础信息 ", "监控管理", "测站管理"],
    hideBread: true,
    loader: () => import("../views/relateManage/siteManage"),
  },
  "/engineerProject": {
    breadName: ["基础信息 ", "河湖基础信息", "涉河涉湖工程项目"],
    hideBread: true,
    loader: () => import("../views/relateManage/engineerProject"),
  },
  "/intakeManage": {
    breadName: ["基础信息 ", "河湖基础信息", "取水口管理"],
    hideBread: true,
    loader: () => import("../views/relateManage/intakeManage"),
  },
  "/riverSectionManage": {
    breadName: ["基础信息 ", "河湖基础信息", "河流断面管理"],
    hideBread: true,
    loader: () => import("../views/relateManage/riverSectionManage"),
  },
  "/publicBoardManage": {
    breadName: ["基础信息 ", "河湖基础信息", "河长公示牌管理"],
    hideBread: true,
    loader: () => import("../views/relateManage/publicBoardManage"),
  },
  "/sewageOutlet": {
    breadName: ["基础信息 ", "河湖基础信息", "排污口管理"],
    hideBread: true,
    loader: () => import("../views/relateManage/sewageOutlet"),
  },
  "/hydropowerStation": {
    breadName: ["基础信息 ", "河湖基础信息", "水电站管理"],
    hideBread: true,
    loader: () => import("../views/relateManage/hydropowerStation"),
  },
  "/companyManage": {
    breadName: ["基础信息 ", "河湖基础信息", "企业管理"],
    hideBread: true,
    loader: () => import("../views/relateManage/companyManage"),
  },
  "/chiefManage": {
    breadName: ["基础信息 ", "河湖基础信息", "河长/湖长/渠长/库长/湿地长管理"],
    hideBread: true,
    loader: () => import("../views/relateManage/chiefManage"),
  },
  "/patrolPlan": {
    breadName: ["巡查管理", "例行巡查"],
    hideBread: true,
    loader: () => import("../views/patrolAndOversee/patrolPlan"),
  },
  "/riverCruiseRecord": {
    breadName: ["巡查管理", "巡查记录"],
    loader: () => import("../views/patrolAndOversee/riverCruiseRecord")
  },
  "/supervisionTourRiver": {
    breadName: ["巡查管理", "监督巡查"],
    hideBread: true,
    loader: () => import("../views/patrolAndOversee/supervisionTourRiver"),
  },
  "/patrolPlan/patrolRecord": {
    breadName: ["巡查记录列表"],
    hideBread: true,

    loader: () => import("../views/patrolAndOversee/patrolRecord"),
  },
  "/supervisionTourRiver/supervisionTourRiverRecord": {
    breadName: ["监督记录列表"],
    hideBread: true,

    loader: () =>
      import("../views/patrolAndOversee/supervisionTourRiverRecord"),
  },

  "/patrolRecord": {
    breadName: ["巡查记录列表"],
    hideBread: true,
    loader: () => import("../views/patrolAndOversee/patrolRecord"),
  },
  "/issuesManage": {
    breadName: ["事件管理", "问题管理"],
    hideBread: true,
    loader: () => import("../views/issues/issuesManage"),
  },

  "/timeLimit": {
    breadName: ["基础信息 ", "系统中心", "时限配置"],
    hideBread: true,
    loader: () => import("../views/issues/timeLimit"),
  },


  "/dailyHistory": {
    breadName: ["在线监测 ", "排污厂每日历史数据"],
    hideBread: true,
    loader: () => import("../views/onlineMonitoring/dailyHistory"),
  },
  "/realtimeMonitoring": {
    breadName: ["在线监测 ", "排污厂实时监测"],
    hideBread: true,
    loader: () => import("../views/onlineMonitoring/realtimeMonitoring"),
  },
  "/alarmMonitoring": {
    breadName: ["告警管理", "告警监控"],
    hideBread: true,
    loader: () => import("../views/alarm/alarmMonitoring"),
  },
  "/alarmConfiguration": {
    breadName: ["告警管理", "告警配置"],
    hideBread: true,
    loader: () => import("../views/alarm/alarmConfiguration"),
  },
  "/toDo": {
    breadName: ["工作台", "我的待办"],
    hideBread: true,
    loader: () => import("../views/officeAnywhere/toDo"),
  },
  "/haveDone": {
    breadName: ["工作台", "我的已办"],
    hideBread: true,
    loader: () => import("../views/officeAnywhere/haveDone"),
  },
  "/xieban": {
    breadName: ["工作台", "我的协办"],
    hideBread: true,
    loader: () => import("../views/officeAnywhere/xieban"),
  },
  "/chaosong": {
    breadName: ["工作台", "我的抄送"],
    hideBread: true,
    loader: () => import("../views/officeAnywhere/chaosong"),
  },
  "/clearRiver": {
    breadName: ["数据填报", "清河行动"],
    hideBread: true,
    loader: () => import("../views/customFunction/clearRiver"),
  },
  "/pzsum": {
    breadName: ["数据填报", "彭州统计数据"],
    hideBread: true,
    loader: () => import("../views/customFunction/pzsum"),
  },
  "/pzzsstnum": {
    breadName: ["数据填报", "治水十条(非工程)"],
    hideBread: true,
    loader: () => import("../views/customFunction/pzzsstnum"),
  },
  "/pzzsstword": {
    breadName: ["数据填报", "治水十条(工程)"],
    hideBread: true,
    loader: () => import("../views/customFunction/pzzsstword"),
  },
  "/blackSmellyWaterGoverment": {
    breadName: [
      "数据填报",
      "沱江流域水生态综合治理工程措施",
      "黑臭水体综合治理",
    ],
    hideBread: true,
    loader: () => import("../views/customFunction/blackSmellyWaterGoverment"),
  },
  "/outfallGoverment": {
    breadName: [
      "数据填报",
      "沱江流域水生态综合治理工程措施",
      "下河排水口综合治理",
    ],
    hideBread: true,
    loader: () => import("../views/customFunction/outfallGoverment"),
  },
  "/sewagePipeNetwork": {
    breadName: [
      "数据填报",
      "沱江流域水生态综合治理工程措施",
      "污水管网建设任务节点",
    ],
    hideBread: true,
    loader: () => import("../views/customFunction/sewagePipeNetwork"),
  },
  "/twonSewageTreatment": {
    breadName: [
      "数据填报",
      "沱江流域水生态综合治理工程措施",
      "城镇污水处理设施建设任务节点",
    ],
    hideBread: true,
    loader: () => import("../views/customFunction/twonSewageTreatment"),
  },
  "/cleanUpOperation": {
    breadName: [
      "数据填报",
      "沱江流域水生态综合治理工程措施",
      "沱江干流和重要支流清淤任务进展",
    ],
    hideBread: true,
    loader: () => import("../views/customFunction/cleanUpOperation"),
  },
  "/reclaimedWaterReuseProject": {
    breadName: [
      "数据填报",
      "沱江流域水生态综合治理工程措施",
      "再生水利用工程建设任务进展",
    ],
    hideBread: true,
    loader: () => import("../views/customFunction/reclaimedWaterReuseProject"),
  },
  "/greenWayConstruction": {
    breadName: [
      "数据填报",
      "沱江流域水生态综合治理工程措施",
      "“七带”绿道建设任务进展",
    ],
    hideBread: true,
    loader: () => import("../views/customFunction/greenWayConstruction"),
  },
  "/summaryOfGovernanceProjects": {
    breadName: [
      "数据填报",
      "沱江流域水生态综合治理工程措施",
      "水生态综合治理工程项目详细表",
    ],
    hideBread: true,
    loader: () => import("../views/customFunction/summaryOfGovernanceProjects"),
  },
  "/totalSummaryOfGovernanceProjects": {
    breadName: [
      "数据填报",
      "沱江流域水生态综合治理工程措施",
      "水生态综合治理工程项目汇总表",
    ],
    hideBread: true,
    loader: () =>
      import("../views/customFunction/totalSummaryOfGovernanceProjects"),
  },
  "/wjsjwt": {
    breadName: ["数据填报", "巡查问题统计上报"],
    hideBread: true,
    loader: () => import("../views/wjsj/wt"),
  },
  "/wjsjxj": {
    breadName: ["数据填报", "巡检次数统计上报"],
    hideBread: true,
    loader: () => import("../views/wjsj/xj"),
  },
  "/pzblhsk": {
    breadName: ["数据填报", "水库工作进展"],
    hideBread: true,
    loader: () => import("../views/customFunction/pzblhsk"),
  },
  "/riverSupervision": {
    breadName: ["巡查管理", "巡查提醒"],
    hideBread: true,
    loader: () => import("../views/supervision/riverSupervision"),
  },
  "/issueSupervise": {
    breadName: ["事件管理", "问题督办"],
    hideBread: true,
    loader: () => import("../views/supervision/issueSupervise"),
  },
  "/publicReport": {
    breadName: ["事件管理", "公众上报"],
    hideBread: true,
    loader: () => import("../views/issues/publicReport"),
  },
  "/riverCruiseAssess": {
    breadName: ["巡查管理", "巡查考核"],
    hideBread: true,
    loader: () => import("../views/riverCruiseAssess"),
  },
  "/messageNotification": {
    breadName: ["消息中心"],
    hideBread: true,
    loader: () => import("../views/messageNotification"),
  },
  "/shareInfo": {
    breadName: ["信息共享", "发布管理"],
    hideBread: true,
    loader: () => import("../views/shareFile/shareInfo"),
  },
  "/riverNews": {
    breadName: ["信息共享", "信息查阅"],
    hideBread: true,
    loader: () => import("../views/shareFile/riverNews"),
  },
  "/filecon": {
    breadName: ["信息共享 ", "文件管理"],
    hideBread: true,
    loader: () => import("../views/dec/fileconMessage"),
  },
  "/supervise": {
    breadName: ["信息共享 ", "督查管理"],
    hideBread: true,
    loader: () => import("../views/dec/superviseMessage"),
  },
  "/monthreport": {
    breadName: ["信息共享 ", "月小结报"],
    hideBread: true,
    loader: () => import("../views/dec/monthreportMessage"),
  },
  "/briefreport": {
    breadName: ["信息共享 ", "工作简报"],
    hideBread: true,
    loader: () => import("../views/dec/briefreportMessage"),
  },


  "/jointStart": {
    breadName: ["协同工单", "协同起单"],
    hideBread: true,
    loader: () => import("../views/joint/jointStart"),
  },
  "/jointOnGoing": {
    breadName: ["协同工单", "在途工单"],
    hideBread: true,
    loader: () => import("../views/joint/jointOnGoing"),
  },
  "/jointClosed": {
    breadName: ["协同工单", "办结工单"],
    hideBread: true,
    loader: () => import("../views/joint/jointClosed"),
  },
  "/patrolAnalysis": {
    breadName: ["统计分析", "巡查统计"],
    hideBread: true,
    loader: () => import("../views/statisticAnalysis/patrolAnalysis"),
  },
  "/issueAnalysis": {
    breadName: ["统计分析", "问题统计"],
    hideBread: true,
    loader: () => import("../views/statisticAnalysis/issueAnalysis"),
  },
  "/issueOverTimeAnalysis": {
    breadName: ["统计分析", "超时问题统计"],
    hideBread: true,
    loader: () => import("../views/statisticAnalysis/issueOverTimeAnalysis"),
  },
  "/jointAnalysis": {
    breadName: ["统计分析", "协同工单统计"],
    hideBread: true,
    loader: () => import("../views/statisticAnalysis/jointAnalysis"),
  },

  "/feedback": {
    breadName: ["基础信息", "系统中心", "意见反馈"],
    hideBread: true,
    loader: () => import("../views/systemManage/feedback"),
  },

  "/reservoirDynamicSupervision": {
    breadName: ["水库动态监管"],
    hideBread: true,
    loader: () => import("../views/reservoirDynamicSupervision"),
  },
  "/mountainFloodDynamicSupervision": {
    breadName: ["山洪动态监管"],
    hideBread: true,
    loader: () => import("../views/mountainFloodDynamicSupervision"),
  },

  "/waterDashboard": {
    breadName: ["智慧水表", "水表一张图"],
    hideBread: true,
    loader: () => import("../views/waterMeter/waterDashboard"),
  },

  "/LargeMeterLogging": {
    breadName: ["智慧水表", "实时监控", "仪表抄表"],
    hideBread: true,
    loader: () => import("../views/waterMeter/LargeMeterLogging"),
  },

  "/CollectorsIsMonitored": {
    breadName: ["智慧水表", "实时监控", "站点监控"],
    hideBread: true,
    loader: () => import("../views/waterMeter/CollectorsIsMonitored"),
  },

  "/FlowStatistics": {
    breadName: ["智慧水表", "统计分析", "流量统计"],
    hideBread: true,
    loader: () => import("../views/waterMeter/FlowStatistics"),
  },
  "/DosageStatistics": {
    breadName: ["智慧水表", "统计分析", "用量统计"],
    hideBread: true,
    loader: () => import("../views/waterMeter/DosageStatistics"),
  },
  "/DosageContrast": {
    breadName: ["智慧水表", "统计分析", "用量对比分析"],
    hideBread: true,
    loader: () => import("../views/waterMeter/DosageContrast"),
  },
  "/EnterpriseStatistics": {
    breadName: ["智慧水表", "统计分析", "企业用量分析"],
    hideBread: true,
    loader: () => import("../views/waterMeter/EnterpriseStatistics"),
  },
  "/CollectorAlarm": {
    breadName: ["智慧水表", "告警管理", "站点告警"],
    hideBread: true,
    loader: () => import("../views/waterMeter/CollectorAlarm"),
  },
  "/LargeMeterAlarmInfo": {
    breadName: ["智慧水表", "告警管理", "仪表告警"],
    hideBread: true,
    loader: () => import("../views/waterMeter/LargeMeterAlarmInfo"),
  },
  "/LargeMeterAlarmSetup": {
    breadName: ["智慧水表", "告警管理", "仪表告警设置"],
    hideBread: true,
    loader: () => import("../views/waterMeter/LargeMeterAlarmSetup"),
  },
  "/PipelineInfo": {
    breadName: ["智慧水表", "管网管理", "管线信息"],
    hideBread: true,
    loader: () => import("../views/waterMeter/PipelineInfo"),
  },
  "/waterDeviceManufacturers": {
    breadName: ["智慧水表", "设备管理", "厂家管理"],
    hideBread: true,
    loader: () =>
      import("../views/waterMeter/waterDevice/waterDeviceManufacturers"),
  },
  "/waterStation": {
    breadName: ["智慧水表", "设备管理", "站点管理"],
    hideBread: true,
    loader: () => import("../views/waterMeter/waterDevice/newWaterStation"),
  },
  "/waterMeterModel": {
    breadName: ["智慧水表", "设备管理", "水表型号管理"],
    hideBread: true,
    loader: () => import("../views/waterMeter/waterDevice/waterMeterModel"),
  },
  "/waterEnterpriseManage": {
    breadName: ["智慧水表", "设备管理", "用水企业管理"],
    hideBread: true,
    loader: () => import("../views/waterMeter/waterDevice/newWaterEnterprise"),
  },
  "/waterDeviceManage": {
    breadName: ["智慧水表", "设备管理", "仪表管理"],
    hideBread: true,
    loader: () =>
      import("../views/waterMeter/waterDevice/newWaterDeviceManage"),
  },
  "/waterMeterReplace": {
    breadName: ["智慧水表", "设备管理", "更换仪表管理"],
    hideBread: true,
    loader: () => import("../views/waterMeter/waterDevice/waterMeterReplace"),
  },
  "/spaceInfoMap": {
    breadName: ["空间信息", "河段地图展示"],
    hideBread: true,
    loader: () => import("../views/spaceInfoMap"),
  },
  "/canalSpaceInfoMap": {
    breadName: ["空间信息", "渠段地图展示"],
    hideBread: true,
    loader: () => import("../views/canalSpaceInfoMap"),
  },
  "/lakeSpaceInfoMap": {
    breadName: ["空间信息", "湖片地图展示"],
    hideBread: true,
    loader: () => import("../views/lakeSpaceInfoMap"),
  },
  "/reservoirSpaceInfoMap": {
    breadName: ["空间信息", "库片地图展示"],
    hideBread: true,
    loader: () => import("../views/reservoirSpaceInfoMap"),
  },
  "/404": {
    hideBread: true,
    loader: () => import("../views/404Pages/404"),
  },
};
const pageConfig = Object.assign({}, defaultPages);
export default pageConfig;
