const EASY_MOCK_DOMIN = "https://www.easy-mock.com";
// const STAGING_DOMIN = `http://rvmls.api.kefen.site:7400`;
// const PROD_DOMIN = `http://rvmls.api.kefen.site:7400`;
// const DEV_DOMIN = `http://rvmls.api.kefen.site:7400`;
// const STAGING_DOMIN = `https://river.apim.pzsswj.net`;
// const PROD_DOMIN = `${location.protocol}//www.river.api.lsshzz.net`;
// const DEV_DOMIN = `${location.protocol}//rvmls.api.kefen.site:7400`;
// const DEV_DOMIN = `http://localhost:3030`;
const LOCAL_DOMIN = `${location.protocol}//localhost:3030`;

const STAGING_DOMIN = `${location.protocol}//www.river.api.lsshzz.net`;
const PROD_DOMIN = `${location.protocol}//www.river.api.lsshzz.net`;
const DEV_DOMIN = `${location.protocol}//www.river.api.lsshzz.net`;

let currentDomin = DEV_DOMIN;
if (process.env.API_ENV === "production") {
  currentDomin = PROD_DOMIN;
} else if (process.env.API_ENV === "staging") {
  currentDomin = STAGING_DOMIN;
} else if (process.env.API_ENV === "local") {
  currentDomin = LOCAL_DOMIN;
}

const APIV1 = currentDomin + "/api/v1";
const APIV2 = currentDomin + "/api/v2";

module.exports = {
  YQL: [],
  CORS: [EASY_MOCK_DOMIN, PROD_DOMIN, DEV_DOMIN, STAGING_DOMIN, LOCAL_DOMIN],
  api_domin: currentDomin,
  api: {
    heZhang: `${APIV1}`,
    //用户
    users: `${APIV1}/user`,
    userDefault: `${APIV1}/user/default`,
    user: `${APIV1}/user/:userId`,
    userReset: `${APIV1}/user/:id/reset-passwd`,
    modifyPasswd: `${APIV1}/user/modify-passwd`,
    thirdAccountBind: `${APIV1}/user/bind-third-account`,
    usersByorg: `${APIV1}/user/find-by-org`,
    //权限
    permission: `${APIV1}/permission`,
    //角色
    role: `${APIV1}/role`,
    roleId: `${APIV1}/role/:id`,
    // rolesId: `${APIV1}/roles/:id`,
    //枚举
    emums: `${APIV1}/sys/rv-enums`,
    enumType: `${APIV1}/sys/rv-enums/:enumType`,
    //任务督办
    patrolSupervise: `${APIV1}/patrol-supervise`,
    //问题督办
    issueSupervise: `${APIV1}/issue-supervise`,
    issueSuperviseComplete: `${APIV1}/issue-supervise/complete`,
    issueSuperviseHistory: `${APIV1}/issue-supervise/history`,
    //考核
    ratingChief: `${APIV1}/rating/chief`,
    //消息提醒
    messageNotification: `${APIV1}/message-notification`,
    messageNotificationId: `${APIV1}/message-notification/:id`,
    messageNotificationMine: `${APIV1}/message-notification/mine`,
    messageNotificationReading: `${APIV1}/message-notification/reading`,
    messageNotificationReadingId: `${APIV1}/message-notification/reading/:id`,
    // 首页待办提示数量
    todos: `${APIV1}/statistical/app/totalTodo`,
    //文件共享文件夹
    shareFolder: `${APIV1}/share-folder`,
    shareFolderId: `${APIV1}/share-folder/:id`,
    //文件共享信息
    shareInfo: `${APIV1}/share-info`,
    shareInfoId: `${APIV1}/share-info/:id`,
    shareInfoTopId: `${APIV1}/share-info/top/:id`,
    shareInfoPublishId: `${APIV1}/share-info/publish/:id`,
    //行政区域
    area: `${APIV1}/area`,
    areaId: `${APIV1}/area/:areaId`,
    areaSurvey: `${APIV1}/area/:areaId/surveying`,
    areaSiblings: `${APIV1}/area/:areaId/parents-siblings-tree`,
    //组织机构
    org: `${APIV1}/org`,
    orgId: `${APIV1}/org/:orgId`,
    //河长企业管理
    company: `${APIV1}/company`,
    companyId: `${APIV1}/company/:id`,
    //河段
    reach: `${APIV1}/river-reach`,
    reachMapData: `${APIV1}/river-reach/mapdata`,
    reachSurvey: `${APIV1}/river-reach/:id/surveying`,
    reachId: `${APIV1}/river-reach/:id`,
    //水系信息
    riverSystem: `${APIV1}/river-system`,
    riverSystemId: `${APIV1}/river-system/:riverSystemId`,
    //河流信息
    river: `${APIV1}/river`,
    riverId: `${APIV1}/river/:riverId`,
    riverMapData: `${APIV1}/river/mapdata`,
    riverReachRiver: `${APIV1}/river-reach/river`,
    //设备
    device: `${APIV1}/device`,
    deviceId: `${APIV1}/device/:id`,
    //设备
    deviceVender: `${APIV1}/device-manufacturer`,
    deviceVenderId: `${APIV1}/device-manufacturer/:id`,
    //设备型号
    deviceModel: `${APIV1}/device-model`,
    deviceModelId: `${APIV1}/device-model/:id`,
    //站点
    station: `${APIV1}/station`,
    stationMapData: `${APIV1}/station/mapdata`,
    stationId: `${APIV1}/station/:id`,
    //涉河涉湖
    engineerProject: `${APIV1}/engineering-project`,
    engineerProjectId: `${APIV1}/engineering-project/:id`,
    //取水口
    intake: `${APIV1}/water-intake`,
    intakeMapData: `${APIV1}/water-intake/mapdata`,
    intakeId: `${APIV1}/water-intake/:id`,
    //排污口
    sewageOutlet: `${APIV1}/sewage-outlet`,
    sewageOutletMapData: `${APIV1}/sewage-outlet/mapdata`,
    sewageOutletId: `${APIV1}/sewage-outlet/:id`,
    //湖泊
    lake: `${APIV1}/lake`,
    lakeMapData: `${APIV1}/lake/mapdata`,
    lakeSurvey: `${APIV1}/lake/:id/surveying`,
    lakeId: `${APIV1}/lake/:id`,
    //水库
    reservoir: `${APIV1}/reservoir`,
    reservoirMapData: `${APIV1}/reservoir/mapdata`,
    reservoirSurvey: `${APIV1}/reservoir/:id/surveying`,
    reservoirId: `${APIV1}/reservoir/:id`,
    //河长管理
    chief: `${APIV1}/chief`,
    chiefId: `${APIV1}/chief/:id`,
    riverGeneralSituaction: `${APIV1}/chief`,
    //统计分析
    chiefPatrolTable: `${APIV1}/statistical/patrol/chief-patrol-table-analysis`,
    chiefPatrolChart: `${APIV1}/statistical/patrol/chief-patrol-chart-analysis`,
    chiefPatrolTwonChart: `${APIV1}/statistical/patrol/chief-patrol-chart-analysis`,
    chiefPatrolVillageChart: `${APIV1}/statistical/patrol/chief-patrol-chart-analysis`,
    //按区域统计
    areaStatisticsTable: `${APIV1}/statistical/patrol/area-patrol-table-analysis`,
    riverreachPatrolChart: `${APIV1}/statistical/patrol/riverreach-patrol-chart-analysis`,
    riverreachPatrolTable: `${APIV1}/statistical/patrol/riverreach-patrol-table-analysis`,
    issueChart: `${APIV1}/statistical/issue/issue-chart-analysis`,
    chiefIssueTable: `${APIV1}/statistical/issue/issue-table-analysis-chief`,
    riverReachIssueTable: `${APIV1}/statistical/issue/issue-table-analysis-riverreach`,
    //超时分析
    issueTimeoutChart: `${APIV1}/statistical/issue/issue-timeout-chart-analysis`,
    chiefIssueTimeoutTable: `${APIV1}/statistical/issue/issue-timeout-table-analysis-chief`,
    issueTimeoutReportPerson: `${APIV1}/statistical/issue/issue-timeout-table-analysis-chief-user`, //按上报人
    issueTimeoutReach: `${APIV1}/statistical/issue/issue-timeout-table-analysis-chief-riverreach`, //按河段
    // 协同工单统计
    jointTable: `${APIV1}/statistical/joint/joint-table-analysis`,
    //成都市市级问题工单统计
    municipalLevelTable: `${APIV1}/statistical/wj-issue/issue-table-analysis`,

    //意见反馈
    proposal: `${APIV1}/proposal`,
    proposalId: `${APIV1}/proposal/:id`,
    proposalAccept: `${APIV1}/proposal/accept`,
    proposalFinish: `${APIV1}/proposal/finish`,
    proposalAcceptStatus: `${APIV1}/proposal/:id/accept-status`,
    //问题管理
    issue: `${APIV1}/issue`,
    issueMapData: `${APIV1}/issue/mapdata`,
    issueId: `${APIV1}/issue/:id`,
    issueProject: `${APIV1}/issue/enums/:project`,
    //问题管理
    timeLimit: `${APIV1}/time-limit`,
    timeLimitId: `${APIV1}/time-limit/:id`,

    //事件管理 市级问题工单
    municipalLevelIssue: `${APIV1}/wj-issue`,
    municipalLevelIssueId: `${APIV1}/wj-issue/:id`,
    //问题处理流程
    issueflowApply: `${APIV1}/issueflow/apply`,
    issueflowProcess: `${APIV1}/issueflow/process/list`,
    issueflowImg: `${APIV1}/issueflow/:id/processDiagram`,
    issueflowStart: `${APIV1}/issueflow/process/:id/start`,

    issueflowReply: `${APIV1}/issueflow/reply/:id`,

    //任务接口
    taskMe: `${APIV1}/task/me`,
    taskComplete: `${APIV1}/task/me/complete`,
    // 操作日志
    loginlog: `${APIV1}/loginlog`,
    // 巡查任务
    patrolPlan: `${APIV1}/patrol-plan`,
    patrolPlanId: `${APIV1}/patrol-plan/:id`,
    patrolPlanMe: `${APIV1}/patrol-plan/me`,
    // 巡查记录
    patrolRecord: `${APIV1}/patrol-record`,
    patrolRecordId: `${APIV1}/patrol-record/:id`,
    patrolRecordSupervise: `${APIV1}/patrol-record/supervise`,
    // 协同办公
    joint: `${APIV1}/joint`,
    jointId: `${APIV1}/joint/:id`,
    // 协同办公流程
    jointFlowProcessDiagram: `${APIV1}/joint-flow/:id/processDiagram`,
    jointFlowApply: `${APIV1}/joint-flow/apply`,
    jointFlowList: `${APIV1}/joint-flow/process/list`,
    jointFlowStart: `${APIV1}/joint-flow/process/start`,

    //文件上传
    uploadFile: `${APIV1}/sys/upload-files`,

    /******彭州定制功能************/
    //清河行动
    pzqhxd: `${APIV1}/pzqhxd`,
    qhxdCflag: `${APIV1}/pzqhxd/cflag`,
    qhxdExport: `${APIV1}/pzqhxd/doExport`,
    pzqhxdId: `${APIV1}/pzqhxd/:id`,
    //彭州数据统计
    pzsum: `${APIV1}/pzsum`,
    pzsumId: `${APIV1}/pzsum/:id`,
    pzsumCflag: `${APIV1}/pzsum/cflag`,
    pzsumExport: `${APIV1}/pzsum/doExport`,
    //治水十条(非工程)
    pzzsstnum: `${APIV1}/pzzsstnum`,
    pzzsstnumId: `${APIV1}/pzzsstnum/:id`,
    pzzsstnumExport: `${APIV1}/pzzsstnum/doExport`,
    //治水十条(工程)
    pzzsstword: `${APIV1}/pzzsstword`,
    pzzsstwordId: `${APIV1}/pzzsstword/:id`,
    pzzsstwordExport: `${APIV1}/pzzsstword/doExport`,
    //水库项目进展列表
    pzblhsk: `${APIV1}/pzblhsk`,
    pzblhskId: `${APIV1}/pzblhsk/:id`,
    pzblhskExport: `${APIV1}/pzblhsk/doExport`,
    //巡查台账列表
    pzplanlist: `${APIV1}/pzplanlist`,
    pzplanlistId: `${APIV1}/pzplanlist/:id`,
    pzplanlistExport: `${APIV1}/pzplanlist/doExport`,
    //统计报表
    areaIssueDayReport: `${APIV1}/statistical/issue/area-issue-day-report`,
    areaPatrolAreaLevelDetailReport: `${APIV1}/statistical/patrol/area-patrol-arealevel-detail-report`,
    areaPatrolAreaLevelReport: `${APIV1}/statistical/patrol/area-patrol-arealevel-report`,
    mineTaskReport: `${APIV1}/statistical/task/mine`,

    lastestWt: `${APIV1}/wjsj/wt/lastest`,
    wt: `${APIV1}/wjsj/wtpage`,
    lastestXj: `${APIV1}/wjsj/xj/lastest`,
    xj: `${APIV1}/wjsj/xjpage`,

    wjsjFile: `${APIV1}/wjsj/import-by-excel`,

    // ----沱江流域水生态综合治理工程措施填报 ----//
    //沱江流域黑臭水体综合治理
    blackSmellyWaterGoverment: `${APIV1}/pz-tjsthc`,
    blackSmellyWaterGovermentId: `${APIV1}/pz-tjsthc/:id`,
    blackSmellyWaterGovermentExport: `${APIV1}/pz-tjsthc/doExport`,
    //沱江流域下河排水口综合治理
    outfallGoverment: `${APIV1}/pz-tjstpsk`,
    outfallGovermentId: `${APIV1}/pz-tjstpsk/:id`,
    outfallGovermentExport: `${APIV1}/pz-tjstpsk/doExport`,
    //沱江流域污水管网建设任务节点
    sewagePipeNetwork: `${APIV1}/pz-tjstsgw`,
    sewagePipeNetworkId: `${APIV1}/pz-tjstsgw/:id`,
    sewagePipeNetworkExport: `${APIV1}/pz-tjstsgw/doExport`,
    //沱江流域城镇污水处理设施建设任务节点
    twonSewageTreatment: `${APIV1}/pz-tjstwscl`,
    twonSewageTreatmentId: `${APIV1}/pz-tjstwscl/:id`,
    twonSewageTreatmentExport: `${APIV1}/pz-tjstwscl/doExport`,
    //沱江干流和重要支流清淤任务进展
    cleanUpOperation: `${APIV1}/pz-tjstqy`,
    cleanUpOperationId: `${APIV1}/pz-tjstqy/:id`,
    cleanUpOperationExport: `${APIV1}/pz-tjstqy/doExport`,
    //沱江流域再生水利用工程建设任务进展
    reclaimedWaterReuseProject: `${APIV1}/pz-tjstzss`,
    reclaimedWaterReuseProjectId: `${APIV1}/pz-tjstzss/:id`,
    reclaimedWaterReuseProjectExport: `${APIV1}/pz-tjstzss/doExport`,
    //沱江流域“七带”绿道建设任务进展情况
    greenWayConstruction: `${APIV1}/pz-tjstqd`,
    greenWayConstructionId: `${APIV1}/pz-tjstqd/:id`,
    greenWayConstructionExport: `${APIV1}/pz-tjstqd/doExport`,
    //沱江流域水生态综合治理工程项目详细
    summaryOfGovernanceProjects: `${APIV1}/pz-tjstgcxm`,
    summaryOfGovernanceProjectsId: `${APIV1}/pz-tjstgcxm/:id`,
    summaryOfGovernanceProjectsExport: `${APIV1}/pz-tjstgcxm/doExport`,
    //沱江流域水生态综合治理工程项目汇总
    totalSummaryOfGovernanceProjects: `${APIV1}/pz-tjstgcxm-hz`,
    totalSummaryOfGovernanceProjectsId: `${APIV1}/pz-tjstgcxm-hz/:id`,
    totalSummaryOfGovernanceProjectsExport: `${APIV1}/pz-tjstgcxm-hz/doExport`,
    //首页图表
    problemsInProcessingChart: `${APIV1}/statistical/issue/issue-report-ongoing`,

    /************智慧水表  *****************/
    //厂家
    waterManufacturer: `${APIV1}/wm/manufacturer`,
    waterManufacturerId: `${APIV1}/wm/manufacturer/:id`,
    //站点S
    waterStation: `${APIV1}/wm/station`,
    waterStationId: `${APIV1}/wm/station/:id`,
    //型号
    waterMeterModel: `${APIV1}/wm/meter-model`,
    waterMeterModelId: `${APIV1}/wm/meter-model/:id`,
    waterMeterModelNames: `${APIV1}/wm/meter-model/names`,
    //仪表列表
    waterMeter: `${APIV1}/wm/meter`,
    waterMeterId: `${APIV1}/wm/meter/:id`,
    //水表企业----(水表用量统计-按用水企业)
    enterprise: `${APIV1}/wm/statistical/meter-flowacc-analysis-org`,
    //更换仪表列表
    waterMeterReplace: `${APIV1}/wm/meter-replace`,
    waterMeterReplaceId: `${APIV1}/wm/meter-replace/:id`,
    //仪表统计
    meterStatistics: `${APIV1}/wm/statistical/meter-flowacc-analysis-meter`,
    meterStatisticsOrg: `${APIV1}/wm/statistical/meter-flowacc-analysis-org`,
    flowStatistics: `${APIV1}/wm/statistical/meter-flowrate-analysis-meter`,
    dosageContrast: `${APIV1}/wm/statistical/meter-flowacccontrast-analysis-meter`,
    dosageContrastOrg: `${APIV1}/wm/statistical/meter-flowacccontrast-analysis-org`,
    // 采集器告警
    collectorAlarm: `${APIV1}/wm/station-alarm`,
    collectorAlarmDetails: `${APIV1}/wm/station-alarm/:id`,
    //大表告警信息
    largeMeterAlarmInfo: `${APIV1}/wm/meter-alarm`,
    largeMeterAlarmInfoDetails: `${APIV1}/wm/meter-alarm/:id`,

    //大表告警设置
    largeMeterAlarmSetup: `${APIV1}/wm/meter-alarm-config`,
    //管线信息
    pipelineInfo: `${APIV1}/wm/pipe`,
    //水表企业管理
    waterCompany: `${APIV1}/wm/company`,
    waterCompanyId: `${APIV1}/wm/company/:id`,
    //水表企业统计分析
    enterpriseStatistics: `${APIV1}/wm/statistical/meter-flowacc-analysis-org-yq`,
    //水表企业统计分析导出
    enterpriseStatisticsExport: `${APIV1}/wm/statistical-export/meter-flowacc-analysis-org-yq-export`,
    /************智慧水表  *****************/
    // 顶部二维码
    qrCode: `${APIV1}/app-version/new/:appType`,

    //实时巡查
    patrolChief: `${APIV1}/patrol-realtime/select`,

    // 水质日均数据历史查询
    dayHistory: `${APIV1}/quality-performance-day`,
    // 水质日均数据历史查询
    dayHistoryDetail: `${APIV1}/quality-performance-day/:id`,

    //彭州告警模块
    alarm: `${APIV1}/alarm`,
    alarmCofirm: `${APIV1}/alarm/confirm/:id`,
    alarmClear: `${APIV1}/alarm/deal/:id`,
    //水文告警配置
    hydrologyAlarm: `${APIV1}/quality-alarm-config`,
    hydrologyAlarmId: `${APIV1}/quality-alarm-config/:id`,

    /************乐山相关接口********************/

    //渠道信息
    channel: `${APIV1}/channel`,
    channelId: `${APIV1}/channel/:channelId`,
    channelMap: `${APIV1}/channel/mapdata`,

    //渠段渠长模块
    channelAll: `${APIV1}/channel_all`,
    channelAllId: `${APIV1}/channel_all/:id`,
    channelAllMap: `${APIV1}/channel_all/mapdata`,

    //湖片湖长模块
    lakeAll: `${APIV1}/lake-all`,
    lakeAllId: `${APIV1}/lake-all/:id`,
    lakeAllMap: `${APIV1}/lake-all/mapdata`,
    //库片库长模块
    reservoirAll: `${APIV1}/reservoir_all`,
    reservoirAllId: `${APIV1}/reservoir_all/:id`,
    reservoirAllMap: `${APIV1}/reservoir_all/mapdata`,

    //河长关联
    creactRelated: `${APIV1}/chief/chief-jurisdictional-create`,
    deleteRelated: `${APIV1}/chief/chief-jurisdictional-delete`,

    //河流河段树
    treeRiver: `${APIV1}/river/tree`,
    //渠道渠段树
    treeCanal: `${APIV1}/channel/tree`,
    //湖泊湖片树
    treeLake: `${APIV1}/lake/tree`,
    //水库库片树
    treeReservoir: `${APIV1}/reservoir/tree`,
    // 湿地湿地片树
    treeWetlands: `${APIV1}/wetland/tree`,

    /************乐山指挥中心接口********************/

    patrolRealTime: `${APIV1}/patrol-realtime/select`,
    riverSystem: `${APIV1}/river-system`,
    topTarea: `${APIV1}/statistical/center/top-area`,
    topTchief: `${APIV1}/statistical/center/top-chief`,
    soketApi: `${APIV1}/ws/:id/open-video`,

    /************乐山水库动态接口********************/
    rdsDetails: `${APIV1}/statistical/reservoir-monitor/montior-detail`,
    swrainDetails: `${APIV1}/statistical/reservoir-monitor/swrain-detail`,
    imgDetails: `${APIV1}/statistical/reservoir-monitor/image-detail`,

    swList: `${APIV1}/statistical/reservoir-monitor/montior-detail`,
    swCounty: `${APIV1}/statistical/reservoir-monitor/by-county`,
    swOverall: `${APIV1}/statistical/reservoir-monitor/overall`,
    swTegr: `${APIV1}/statistical/reservoir-monitor/tegr`,
    swMontior: `${APIV1}/statistical/reservoir-monitor/sw-montior`,

    /************乐山山洪动态接口********************/

    shList: `${APIV1}/statistical/tf-station-monitor/montior-detail`,
    shCounty: `${APIV1}/statistical/tf-station-monitor/by-county`,
    shOverall: `${APIV1}/statistical/tf-station-monitor/overall`,
    swTegr: `${APIV1}/statistical/reservoir-monitor/tegr`,
    shMontior: `${APIV1}/statistical/tf-station-monitor/sw-montior`,
    sqMontior: `${APIV1}/statistical/tf-data/water-level-real`,
    yqMontior: `${APIV1}/statistical/tf-data/rain-real`,
    waterChart: `${APIV1}/statistical/tf-data/waterlevel-history`,
    rainChart: `${APIV1}/statistical/tf-data/rain-history`,
    waterAndRain: `${APIV1}/statistical/tf-data/waterlevelrain-detail`,

    /************乐山一张图接口********************/

    //湿地
    wetland: `${APIV1}/wetland`,
    wetlandMapData: `${APIV1}/wetland/mapdata`,
    wetlandId: `${APIV1}/wetland/:id`,
    //水电站
    hydropower: `${APIV1}/hydropower`,
    hydropowerMapData: `${APIV1}/hydropower/mapdata`,
    hydropowerId: `${APIV1}/hydropower/:id`,
    //湿地片
    wetlandAll: `${APIV1}/wetland-all`,
    wetlandAllMapData: `${APIV1}/wetland-all/mapdata`,
    wetlandIdAll: `${APIV1}/wetland-all/:id`,
    //公众上报
    publicReport: `${APIV1}/rv-public-report`,
    publicReportMapData: `${APIV1}/rv-public-report/mapdata`,
    publicReportId: `${APIV1}/rv-public-report/:id`,
    publicReportReply: `${APIV1}/rv-public-report/reply-list`,
    publicReportAccept: `${APIV1}/rv-public-report/accept/:id`,
    publicReportClosed: `${APIV1}/rv-public-report/close/:id`,
    publicReportReplyed: `${APIV1}/rv-public-report/reply/:id`,
    publicReportFinished: `${APIV1}/rv-public-report/solve/:id`,

    //微信相关
    wxNews: `${APIV1}/wx-public/news`,
    wxRiver: `${APIV1}/wx-public/river`,
    wxRiverMap: `${APIV1}/wx-public/river/mapdata`,
    wxRiverReach: `${APIV1}/wx-public/river-reach`,
    wxRiverId: `${APIV1}/wx-public/river/:id`,
    wxMA: `${APIV1}/wx-public/ambassador-report`,
    wxRePly: `${APIV1}/ignoring/rv-public-report`,
    wxMaId: `${APIV1}/ignoring/rv-public-report/:id`,

    //一河一档
    reachArchives: `${APIV1}/river-reach/:id/archives`,
    reachStrategy: `${APIV1}/river-reach/:id/strategy`,

    channelArchives: `${APIV1}/channel_all/:id/archives`,
    channelStrategy: `${APIV1}/channel_all/:id/strategy`,

    reservoirArchives: `${APIV1}/reservoir_all/:id/archives`,
    reservoirStrategy: `${APIV1}/reservoir_all/:id/strategy`,

    lakeArchives: `${APIV1}/lake-all/:id/archives`,
    lakeStrategy: `${APIV1}/lake-all/:id/strategy`,

    wetlandArchives: `${APIV1}/wetland_all/:id/archives`,
    wetlandStrategy: `${APIV1}/wetland_all/:id/strategy`,


    //集中式对象相关接口
    resourceCenterMore: `${APIV1}/resource/center-more`,


    //告警模块
    alarm: `${APIV1}/alarm`,
    alarmCofirm: `${APIV1}/alarm/confirm/:id`,
    alarmClear: `${APIV1}/alarm/deal/:id`,
    //水文告警配置
    hydrologyAlarm: `${APIV1}/hydrology-alarm-config`,
    hydrologyAlarmId: `${APIV1}/hydrology-alarm-config/:id`,
    //水文监测
    hydrologyPerformanceRt: `${APIV1}/hydrology-performance/realtime`,
    //水文监测历史
    hydrologyPerformanceHistory: `${APIV1}/hydrology-performance`,


    //河长公示牌
    publicBoard: `${APIV1}/public-board`,
    publicBoardId: `${APIV1}/public-board/:id`,
    //河长公示牌
    riverSection: `${APIV1}/river-section`,
    riverSectionId: `${APIV1}/river-section/:id`,


    /**
     * 2021-12-20 新增需求 start
     */
    //塘坝
    dam:  `${APIV1}/dam`,
    damId:  `${APIV1}/dam/:id`,
    //工作简报
    briefreport:  `${APIV1}/briefreport`,
    briefreportId:  `${APIV1}/briefreport/:id`,
    //月小结报
    monthreport:  `${APIV1}/monthreport`,
    monthreportId:  `${APIV1}/monthreport/:id`,
    //文件
    filecon:  `${APIV1}/filecon`,
    fileconId:  `${APIV1}/filecon/:id`,
    //督查
    supervise:  `${APIV1}/supervise`,
    superviseId:  `${APIV1}/supervise/:id`,
    /**
     * 2021-12-20 新增需求 end
     */
  },
};
