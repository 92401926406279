import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {org,orgId} = api;

/**
 * 查询组织机构列表
 */
export async function orgList(params) {
  return request({url: org, method: 'get', data: params,});
}
/**
 * 查询组织机构详情
 */
export async function getOrgDetail(id) {
  return request({url: orgId, method: 'get', data: {
      orgId:id
    },});
}
/**
 * 编辑组织机构
 */
export async function editOrg(params) {
  return request({url: orgId, method: 'put', data: params});
}
/**
 * 删除组织机构
 */
export async function deleteOrg(params) {
  return request({url: org+ '/delete' , method: 'post', data:params});
}
/**
 * 创建组织机构
 */
export async function createOrg(params) {
  return request({url: org, method: 'post', data: params,});
}
