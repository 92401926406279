import React from 'react'
import QRCode  from 'qrcode-react';
import Logo from "../../../images/login/9696.png"

const AppQrcode = (props) => {

    return <div style={{width: 250}}>
    <div style={{textAlign: "center"}}>
        {
            props.appversion || props.appversion && props.appversion.filePath ? <QRCode
                value={props.appversion.filePath}
                size={140}
                fgColor='purple'
                bgColor='white'
                logoWidth={32}
                logoHeight={32}
                logo={Logo}
                /> 
                :null
        }
    </div>
    <p style={{textAlign: "center"}}>扫码下载安卓APP</p>
</div>;
}


export default AppQrcode
