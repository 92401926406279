import {list} from 'api/pzCustom/pzzsstword';

export const PZZSSTWORDLIST = 'PZZSSTWORDLIST';

export const tableAction = (data) => ({
  type: PZZSSTWORDLIST,
  payload: {
    promise: list(data)
  }
});
