import * as login from "./login";
import * as user from "./user";
import * as role from "./role";
import * as region from "./region";
import * as river from "./river";
import * as riverSystem from "./riverSystem";
import * as org from "./org";
import * as reach from "./reach";
import * as device from "./device";
import * as deviceVender from "./deviceVender";
import * as deviceTypeManage from "./deviceTypeManage";
import * as siteManage from "./siteManage";
import * as deviceManage from "./deviceManage";
import * as pollutionSource from "./pollutionSource";
import * as engineerProject from "./engineerProject";
import * as intakeManage from "./intakeManage";
import * as sewageOutlet from "./sewageOutlet";
import * as lakeManage from "./lakeManage";
import * as reservoirManage from "./reservoirManage";
import * as company from "./company";
import * as chiefManage from "./chiefManage";
import * as loginlog from "./loginlog";
import * as issueManage from "./issueManage";
import * as patrolPlan from "./patrolPlan";
import * as patrolRecord from "./patrolRecord";
import * as task from "./task";
import * as patrolSupervise from "./patrolSupervise";
import * as ratingChief from "./ratingChief";
import * as messageNotification from "./messageNotification";
import * as shareFolder from "./shareFolder";
import * as shareInfo from "./shareInfo";
import * as issueSupervise from "./issueSupervise";
import * as joint from "./joint";
import * as timeLimit from "./timeLimit";
import * as patrolAnalysis from "./patrolAnalysis";
import * as issueAnalysis from "./issueAnalysis";
import * as feedback from "./feedback";
import * as riverGeneralSituactionApi from "./riverGeneralSituaction";
import * as municipalLevelManage from "./municipalLevelManage";
import * as realtimeMonitoring from "./realtimeMonitoring";
import * as dailyHistory from "./dailyHistory";
import * as alarm from "./alarm";
import * as video from './video'
import * as publicBoardManage from './publicBoardManage'
import * as riverSectionManage from './riverSectionManage'

import * as canal from "./canal";
import * as commandCenter from "./commandCenter";
import * as wetlands from "./wetlands";
import * as hydropower from "./hydropower";
import * as publicReport from "./publicReport";
import * as wxRelated from "./wxRelated";
import * as dam from "./dam";
import * as supervise from "./supervise";
import * as filecon from "./filecon";
import * as monthreport from "./monthreport";
import * as briefreport from "./briefreport";

export {
  login,
  user,
  role,
  region,
  riverSystem,
  river,
  org,
  reach,
  device,
  deviceVender,
  deviceTypeManage,
  siteManage,
  deviceManage,
  pollutionSource,
  engineerProject,
  intakeManage,
  sewageOutlet,
  lakeManage,
  reservoirManage,
  company,
  chiefManage,
  loginlog,
  issueManage,
  patrolPlan,
  patrolRecord,
  task,
  patrolSupervise,
  ratingChief,
  messageNotification,
  shareFolder,
  shareInfo,
  issueSupervise,
  joint,
  timeLimit,
  patrolAnalysis,
  issueAnalysis,
  feedback,
  riverGeneralSituactionApi,
  municipalLevelManage,
  realtimeMonitoring,
  dailyHistory,
  alarm,
  canal,
  commandCenter,
  wetlands,
  publicReport,
  hydropower,
  wxRelated,
  video,
  publicBoardManage,
  riverSectionManage,
  dam,
  briefreport,
  monthreport,
  supervise,
  filecon,
};
