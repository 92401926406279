import {filecon} from 'api';

export const FILECONLIST = 'FILECONLIST';


export const fileconAction = (data) => ({
  type: FILECONLIST,
  payload: {
    promise: filecon.fileconList(data)
  }
});
