import React, { Component } from "react";
import { riverSystemList } from "../../api/commandCenter";
import { connect } from "react-redux";
import { Select } from "antd";
import "./index.less";

import { filterEmums, } from "utils/filter";

const Option = Select.Option;




@connect(
  (state) => {
    return {
      emums: state.emums.data,
    };
  },
  {}
)
export default class SelectRiverSystem extends Component {
  state = {
    data: [],
    emums: {}
  };

  componentDidMount = () => {

    const resourceObjType = filterEmums(this.props.emums, "ResourceObjType");

    this.setState({
      emums: {
        resourceObjType
      }
    })

  };

  handleChange = data => {
    this.props.onChange(data);
  };
  render() {
    const { width, margin, hasdropdown } = this.props;
    const options = this.state.emums && this.state.emums.resourceObjType && this.state.emums.resourceObjType.map(d => (
      <Option key={d.value}>{d.name}</Option>
    ));
    return (
      <Select
        allowClear
        onChange={this.handleChange}
        {...this.props}
        placeholder="请选择所属资源类型"
        style={{ width: width ? width : 150, margin: margin ? margin : "none" }}
        dropdownClassName={hasdropdown ? "dpdClass" : null}
      >
        {options}
      </Select>
    );
  }
}
