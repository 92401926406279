/* eslint-disable react/no-string-refs */
import './index.less'

import React, { Component } from "react";

import { Carousel } from 'antd';
import { Icon } from 'antd';
import Img1 from "../../images/index/ls-1.jpg";
import Img2 from "../../images/index/ls-2.jpg";
import Img3 from "../../images/index/ls-3.jpg";
import Img5 from "../../images/index/ls-5.jpg";
import Img6 from "../../images/index/ls-6.jpg";
import history from "../../utils/history";

const imgsArr = [{
    title: 'a',
    src: Img1

}, {
    title: 'b',
    src: Img2

}, {
    title: 'c',
    src: Img3

}, {
    title: 'd',
    src: Img5

}, {
    title: 'f',
    src: Img6

}]


class Welcome extends Component {

    render() {
        return (
            <div className='container-img' >
                <Carousel autoplay ref="welcome">
                    {
                        imgsArr.map(s => {
                            return <div
                                className='img'
                                key={s.title}
                            >
                                <img src={`${s.src}`} width='100%' height='100%' onClick={() => history.push('/index')}></img>
                            </div>
                        })
                    }
                </Carousel >
                <Icon type="left-circle"
                    className='left-arrow'
                    onClick={() => this.refs.welcome.prev()}
                />
                <Icon type="right-circle"
                    className='right-arrow'
                    onClick={() => this.refs.welcome.next()}
                />
            </div>
        );
    }
}

export default Welcome;


