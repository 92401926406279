import {
  ISSUESUPERVISELIST,
  ISSUESUPERVISECOMPLETELIST,
  ISSUESUPERVISEHISTORYLIST
} from '../actions/issueSupervise';
import {reducerPackage} from "../../utils";


export const issueSuperviseList = reducerPackage(ISSUESUPERVISELIST);

export const issueSuperviseCompleteList = reducerPackage(ISSUESUPERVISECOMPLETELIST);

export const issueSuperviseHistoryList = reducerPackage(ISSUESUPERVISEHISTORYLIST);
