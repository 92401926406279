import {list} from 'api/pzCustom/cleanUpOperation';

export const CLEANUPOPERATION = 'CLEANUPOPERATION';

export const tableAction = (data) => ({
  type: CLEANUPOPERATION,
  payload: {
    promise: list(data)
  }
});
