import { alarm } from "api"

export const ALARMCONFIGLIST = "ALARMCONFIGLIST"
export const ALARMCONFIGLIST_ADD = "ALARMCONFIGLIST_ADD"
export const ALARMCONFIGLIST_DEL = "ALARMCONFIGLIST_DEL"
export const ALARMCONFIGLIST_DETAIL = "ALARMCONFIGLIST_DETAIL"
export const ALARMCONFIGLIST_DETAIL_UPDATE = "ALARMCONFIGLIST_DETAIL_UPDATE"

export const getAlarmListAction = data => ({
  type: ALARMCONFIGLIST,
  payload: {
    promise: alarm.alarmConfigList(data)
  }
})

export const addAlarmItemAction = data => ({
  type: ALARMCONFIGLIST_ADD,
  payload: {
    promise: alarm.alarmConfigAdd(data)
  }
})
export const deleteAlarmItemAction = data => ({
  type: ALARMCONFIGLIST_DEL,
  payload: {
    promise: alarm.alarmConfigDel(data)
  }
})
export const DetailAlarmItemAction = data => ({
  type: ALARMCONFIGLIST_DETAIL,
  payload: {
    promise: alarm.alarmConfigDetail(data)
  }
})
export const updateDataAlarmItemAction = data => ({
  type: ALARMCONFIGLIST_DETAIL_UPDATE,
  payload: {
    promise: alarm.updateAlarmConfigDetail(data)
  }
})



