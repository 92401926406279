import {list} from 'api/pzCustom/pzblhsk';

export const PZBLHSKLIST = 'PZBLHSKLIST';

export const tableAction = (data) => ({
  type: PZBLHSKLIST,
  payload: {
    promise: list(data)
  }
});
