export  const  MANUFACTURES_LIST='MANUFACTURES_LIST';
export  const  WATERSTATION_LIST='WATERSTATION_LIST';
export  const  METEREMODEL_LIST='METEREMODEL_LIST';
export  const  WATERMETERREPLACE_LIST='WATERMETERREPLACE_LIST';
//
export const  WATERMETER_LIST = 'WATERMETER_LIST'


//获取采集器实时监控数据
export const COLLECTORISMONITORED_LIST = 'COLLECTORISMONITORED_LIST'

//获取采集器告警数据
export const COLLECTORALARM_LIST = 'COLLECTORALARM_LIST'

//获取采集器告警详情数据
export const COLLECTORALARMDETAILS_LIST = 'COLLECTORALARMDETAILS_LIST'
//更新采集器告警详情数据
export const UPDATA_COLLECTORALARMDETAILS = 'UPDATA_COLLECTORALARMDETAILS'

//获取大表告警信息数据
export const LARGEMETERALARMINFO_LIST = 'LARGEMETERALARMINFO_LIST'

//获取大表告警信息详情数据
export const LARGEMETERALARMINFODETAILS_LIST = 'LARGEMETERALARMINFODETAILS_LIST'
//获取大表告警信息详情数据
export const UPDATE_LARGEMETERALARMINFODETAILS = 'UPDATE_LARGEMETERALARMINFODETAILS'

//获取大表告警设置数据
export const LARGEMETERALARMSETUP_LIST = 'LARGEMETERALARMSETUP_LIST'

//新增大表告警设置
export const ADD_LARGMETERLARMSEUP = 'ADD_LARGMETERLARMSEUP'
//删除大表告警设置
export const DELETE_LARGMETERLARMSEUP = 'DELETE_LARGMETERLARMSEUP'


//新增采集器实时监控
export const ADD_COLLECTORISMONITORED = 'ADD_COLLECTORISMONITORED'
//删除采集器实时监控
export const DEL_COLLECTORISMONITORED = 'DEL_COLLECTORISMONITORED'

//获取管线信息数据
export const PIPELINEINFO = 'PIPELINEINFO'

//新增管线信息数据
export const ADDPIPELINEINFO = 'ADDPIPELINEINFO'

//删除管线信息数据
export const DELETEPIPELINEINFO = 'DELETEPIPELINEINFO'


//用水企业管理列表
export const USEWATERENTERPRISE = 'USEWATERENTERPRISE'
//新增
export const USEWATERENTERPRISE_ADD = 'USEWATERENTERPRISE_ADD'
//删除 
export const USEWATERENTERPRISE_DELETE = 'USEWATERENTERPRISE_DELETE'
//详情 
export const USEWATERENTERPRISE_DETAILS = 'USEWATERENTERPRISE_DETAILS'

//详情更新
export const USEWATERENTERPRISE_DETAILS_UPDATE = 'USEWATERENTERPRISE_DETAILS_UPDATE'


//用水企业统计分析列表
export const ENTERPRISESTATISTICS = 'ENTERPRISESTATISTICS'
//用水企业统计分析按用水企业查询图表数据
export const ENTERPRISESTATISTICS_BY_ENTERPRISE = 'ENTERPRISESTATISTICS_BY_ENTERPRISE'
//用水企业统计分析按用水企业查询(年)图表数据
export const ENTERPRISESTATISTICS_BY_ENTERPRISE_YEAR = 'ENTERPRISESTATISTICS_BY_ENTERPRISE_YEAR'

//大表抄表列表
export const METERLIST = 'METERLIST'
//大表新增
export const METERLIST_ADD = 'METERLIST_ADD'
//删除水表
export const METERLIST_DELETE = 'METERLIST_DELETE'
//水表详情
export const METERLIST_DETAILS = 'METERLIST_DETAILS'
//水表更新
export const METERLIST_DETAILS_UPDATE = 'METERLIST_DETAILS_UPDATE'