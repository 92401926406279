import {
    RTMLIST,
    RTMLIST_NEW
  } from '../actions/realtimeMonitoring';
  
  import {reducerPackage} from "../../utils";
  
  export const initialState = {
    isFetching: false,
    data: []
  };


 const innerReducerPackage = (mutations, success, error) => (state = initialState, action) => {
    switch (action.type) {
      case `${mutations}_PENDING`:
        return Object.assign({}, initialState, {
          isFetching: true
        });
      case `RTMLIST_NEW_PENDING`:
        return Object.assign({}, state, {
          isFetching: true
        });
      case `${mutations}_ERROR`:
        const errorDefaultObj = {
          isFetching: false,
          data: [],
          allData: {}
        };
        if (typeof  error === 'function') {
          const errorObj = error(action) || {};
          return Object.assign({}, errorDefaultObj, errorObj);
        }
        return errorDefaultObj;
      case `${mutations}_SUCCESS`:
      case `RTMLIST_NEW_SUCCESS`:
        let defaultObj = Object.assign({}, state, {
          isFetching: false,
          data: action.payload.data,
          allData: action.payload
        });
        if (typeof  success === 'function') {
          const successObj = success(action) || {};
          return Object.assign({}, defaultObj, successObj);
        }
        return defaultObj;
      default:
        return state;
    }
  };

  export const realtimeMonitoringList = innerReducerPackage(RTMLIST);
  
  