import { list } from 'api/pzCustom/totalSummaryOfGovernanceProjects';

export const TOTALSUMMARYOFGOVERNANCEPROJECTS = 'TOTALSUMMARYOFGOVERNANCEPROJECTS';

export const tableAction = (data) => ({
  type: TOTALSUMMARYOFGOVERNANCEPROJECTS,
  payload: {
    promise: list(data)
  }
});
