import {deviceManage} from 'api';

export const DEVICEMANAGELIST = 'DEVICEMANAGELIST';


export const tableAction = (data) => ({
  type: DEVICEMANAGELIST,
  payload: {
    promise: deviceManage.list(data)
  }
});
