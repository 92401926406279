import {engineerProject} from 'api';

export const ENGINEERPROJECTLIST = 'ENGINEERPROJECTLIST';


export const tableAction = (data) => ({
  type: ENGINEERPROJECTLIST,
  payload: {
    promise: engineerProject.list(data)
  }
});
