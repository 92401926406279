import {shareFolder} from 'api';

export const SHAREFOLDERLIST = 'SHAREFOLDERLIST';


export const tableAction = (data) => ({
  type: SHAREFOLDERLIST,
  payload: {
    promise: shareFolder.list(data)
  }
});
