import { dailyHistory } from "api";

export const DAYHLIST = "DAYHLIST";

export const dailyHistoryAction = data => ({
  type: DAYHLIST,
  payload: {
    promise: dailyHistory.list(data)
  }
});

