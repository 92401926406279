import {joint} from 'api';

export const JOINTLIST = 'JOINTLIST';


export const jointTableAction = (data) => ({
  type: JOINTLIST,
  payload: {
    promise: joint.list(data)
  }
});
