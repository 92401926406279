import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {messageNotification,  messageNotificationId,messageNotificationMine,messageNotificationReading,messageNotificationReadingId} = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({url: messageNotification, method: 'get', data: params,});
}
/**
 * 删除列表
 */
export async function deleteItem(params) {
  return request({url: messageNotification+ '/delete' , method: 'post', data: params,});
}

/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({
    url: messageNotificationId, method: 'get', data: {
      id: paramId
    },
  });
}
/**
 * 查询自己通知列表
 */
export async function mineMessageList(params) {
  return request({url: messageNotificationMine, method: 'get',data: params});
}

/**
 * 批量消息阅读
 */
export async function reading(params) {
  return request({url: messageNotificationReading, method: 'patch', data: params});
}
/**
 * 阅读消息
 */
export async function readId(paramId) {
  return request({url: messageNotificationReadingId, method: 'patch', data: {id:paramId}});
}

