import {list} from 'api/pzCustom/clearRiver';

export const CLEARRIVERLIST = 'CLEARRIVERLIST';

export const tableAction = (data) => ({
  type: CLEARRIVERLIST,
  payload: {
    promise: list(data)
  }
});
