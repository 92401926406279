import {
  SEWAGEPIPENETWORK} from 'actions/pzCustom/sewagePipeNetwork';
  export const initialState = {
    isFetching: false,
    data: []
  };
  export const sewagePipeNetworkList = (state = initialState, action) => {
    switch (action.type) {
      case `${SEWAGEPIPENETWORK}_PENDING`:
        return Object.assign({}, initialState,{
          isFetching:true
        });
      case `${SEWAGEPIPENETWORK}_SUCCESS`:
        return Object.assign({}, state, {
          isFetching: false,
          data: action.payload.data,
          allData:action.payload
        });
      default:
        return state;
    }
  };
  
  