import config from "utils/apiconfig";
import request from "utils/request";

const { api } = config;
const { supervise, superviseId } = api;

/**
 * 查询督查列表
 */
export async function superviseList(params) {
  return request({ url: supervise, method: "get", data: params });
}

/**
 * 查询督查详情
 */
export async function superviseDetail(id) {
  return request({
    url: superviseId,
    method: "get",
    data: {
      damId: id
    }
  });
}
