import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {device,hydrologyPerformanceRt,hydrologyPerformanceHistory} = api;

/**
 * 查询用户列表
 */
export async function videoSurveillanceList(params) {
  return request({url: device, method: 'get', data: params,});
}
/**
 * 查询用户列表
 */
export async function hydrologyPerformanceRtList(params) {
  return request({url: hydrologyPerformanceRt, method: 'get', data: params,});
}
/**
 * 查询用户列表
 */
export async function hydrologyPerformanceHistoryList(params) {
  return request({url: hydrologyPerformanceHistory, method: 'get', data: params,});
}