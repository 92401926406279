import {
  TOTALSUMMARYOFGOVERNANCEPROJECTS
} from 'actions/pzCustom/totalSummaryOfGovernanceProjects';
export const initialState = {
  isFetching: false,
  data: []
};
export const totalSummaryOfGovernanceProjectsList = (state = initialState, action) => {
  switch (action.type) {
    case `${TOTALSUMMARYOFGOVERNANCEPROJECTS}_PENDING`:
      return Object.assign({}, initialState, {
        isFetching: true
      });
    case `${TOTALSUMMARYOFGOVERNANCEPROJECTS}_SUCCESS`:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.payload.data,
        allData: action.payload
      });
    default:
      return state;
  }
};

