import {list} from 'api/pzCustom/pzplanlist';

export const PZPLANLISTLIST = 'PZPLANLISTLIST';

export const tableAction = (data) => ({
  type: PZPLANLISTLIST,
  payload: {
    promise: list(data)
  }
});
