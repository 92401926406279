import config from "utils/apiconfig";
import request from "utils/request";

const { api } = config;
const {
  users,
  user,
  userReset,
  modifyPasswd,
  userDefault,
  thirdAccountBind,
  usersByorg,
} = api;

/**
 * 查询用户列表
 */
export async function userList(params) {
  return request({ url: users, method: "get", data: params });
}
/**
 * 查询用户列表按组织机构
 */
export async function userByOrg(params) {
  return request({ url: usersByorg, method: "get", data: params });
}
/**
 * 查询用户详情
 */
export async function getUserDetail(id) {
  return request({
    url: user,
    method: "get",
    data: {
      userId: id,
    },
  });
}
/**
 * 重置密码
 */
export async function resetPwd(id) {
  return request({
    url: userReset,
    method: "post",
    data: {
      id: id,
    },
  });
}
/**
 * 修改密码
 */
export async function modifyPwd(params) {
  return request({ url: modifyPasswd, method: "post", data: params });
}
/**
 * 编辑用户
 */
export async function editUser(params) {
  return request({ url: user, method: "put", data: params });
}
export async function editUserDefault(params) {
  return request({ url: userDefault, method: "put", data: params });
}
/**
 * 删除用户
 */
export async function deleteUser(params) {
  return request({
    url: users,
    method: "delete",
    data: params,
    config: { showLoading: true },
  });
}
/**
 * 创建用户
 */
export async function createUser(params) {
  return request({ url: users, method: "post", data: params });
}

export async function bindThirdAccount(params) {
  return request({ url: thirdAccountBind, method: "post", data: params });
}
