import { issueAnalysis } from "api"

export const CHIEFISSUETABLE = "CHIEFISSUETABLE"
export const ISSUECHART = "ISSUECHART"
export const RIVERREACHISSUETABLE = "RIVERREACHISSUETABLE"
export const CHIEFISSUETIMEOUTTABLE = "CHIEFISSUETIMEOUTTABLE"
export const ISSUETIMEOUTCHART = "ISSUETIMEOUTCHART"
export const RIVERREACHISSUETIMEOUTTABLE = "RIVERREACHISSUETIMEOUTTABLE"
export const JOINTTABLEACTION = "JOINTTABLEACTION"   // 协同工单统计
export const MUNICIPALLEVELTABLEACTION = "MUNICIPALLEVELTABLEACTION" //成都市市级工单统计
//超时
export const chiefIssueTimeoutTableAction = data => ({
  type: CHIEFISSUETIMEOUTTABLE,
  payload: {
    promise: issueAnalysis.chiefIssueTimeoutTableList(data)
  }
})
export const issueTimeoutChart = data => ({
  type: ISSUETIMEOUTCHART,
  payload: {
    promise: issueAnalysis.issueTimeoutChartList(data)
  }
})
export const issueTimeoutChartClear = data => ({
  type: ISSUETIMEOUTCHART,
  payload: {
    promise: () => {
      return new Promise(resolve => {
        resolve({ data: [] })
      })
    }
  }
})
export const chiefIssueTimeoutTableActionClear = data => ({
  type: CHIEFISSUETIMEOUTTABLE,
  payload: {
    promise: () => {
      return new Promise(resolve => {
        resolve({ data: [] })
      })
    }
  }
})
export const chiefIssueTableAction = data => ({
  type: CHIEFISSUETABLE,
  payload: {
    promise: issueAnalysis.chiefIssueTableList(data)
  }
})
export const chiefIssueTableActionClear = data => ({
  type: CHIEFISSUETABLE,
  payload: {
    promise: () => {
      return new Promise(resolve => {
        resolve({ data: [] })
      })
    }
  }
})
export const riverReachIssueTableAction = data => ({
  type: RIVERREACHISSUETABLE,
  payload: {
    promise: issueAnalysis.riverReachIssueTableList(data)
  }
})
export const riverReachIssueTableActionClear = data => ({
  type: RIVERREACHISSUETABLE,
  payload: {
    promise: () => {
      return new Promise(resolve => {
        resolve({ data: [] })
      })
    }
  }
})
export const issueChart = data => ({
  type: ISSUECHART,
  payload: {
    promise: issueAnalysis.issueChartList(data)
  }
})
export const issueChartClear = data => ({
  type: ISSUECHART,
  payload: {
    promise: () => {
      return new Promise(resolve => {
        resolve({ data: [] })
      })
    }
  }
})

export const riverReachIssueTimeoutTableAction = data => ({
  type: RIVERREACHISSUETIMEOUTTABLE,
  payload: {
    promise: issueAnalysis.riverReachIssueTimeoutTableList(data)
  }
})

export const jointTableAction = data => ({
  type: JOINTTABLEACTION,
  payload: {
    promise: issueAnalysis.jointTableList(data)
  }
})

export const municipalLevelTableAction = data => ({
  type: MUNICIPALLEVELTABLEACTION,
  payload: {
    promise: issueAnalysis.municipalLevelTableList(data)
  }
})
