import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const { patrolPlan,patrolPlanId,patrolPlanMe} = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({url: patrolPlan, method: 'get', data: params,});
}
/**
 * 查询自己任务
 */
export async function planMeList(params) {
  return request({url: patrolPlanMe, method: 'get', data: params,});
}
/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({url: patrolPlanId, method: 'get', data: {
      id:paramId
    },});
}
/**
 * 编辑
 */
export async function edit(params) {
  return request({url: patrolPlanId, method: 'put', data: params});
}
/**
 * 删除
 */
export async function deleteItem (params) {
  return request({url: patrolPlan+ '/delete' , method: 'post', data:params});
}
/**
 * 创建
 */
export async function createItem(params) {
  return request({url: patrolPlan, method: 'post', data: params,});
}
