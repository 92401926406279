import {deviceVender} from 'api';

export const DEVICEVENDERLIST = 'DEVICEVENDERLIST';


export const tableAction = (data) => ({
  type: DEVICEVENDERLIST,
  payload: {
    promise: deviceVender.list(data)
  }
});
