import React, { Component } from "react";
import "./index.less";
import { Radio, Icon, Button } from "antd";
import { RectGraduallyShowLoading } from "react-loadingg";
import PieChart from "./chart";
import MapCenter from "./map";
import Weather from "./weather";
import DateCom from "./topDate";
import QueryCom from "./queryCom";
import QueryComR from "./queryComR";
import StatisticalDisplay from "./statisticalDisplay";

import TopChiefPatrolChart from "./topChiefPatrolChart";
import TopChiefIssueChart from "./topChiefIssueChart ";
import TopAreaPatrolChart from "./topAreaPatrolChart";
import TopAreaIssueChart from "./topAreaIssueChart";

import { topTareaList, topTchiefList } from "../../api/commandCenter";

import styled from "styled-components";
import moment from "moment";
const Empty = styled.div`
  width: 100%;
  line-height: 200px;
  color: #0cbee8;
`;
class CommandCenter extends Component {
  constructor(props) {
    super(props);
    this.queryCom = React.createRef();
    this.refreshMap = React.createRef();
  }

  state = {
    chiefPatrolListTop: [],
    chiefIssueListTop: [],
    areaPatrolListTop: [],
    areaIssueListTop: [],
    queryVal: null,
    rdValue: "hzxh",
  };

  componentDidMount = () => {
    this.getChiefPatrolList({
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
    this.getChiefIssueList({
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
    this.getAreaPatrolList({
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
    this.getAreaIssueList({
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
  };

  handleQueryComR = (value) => {
    this.setState(
      {
        queryVal: value,
      },
      () => {
        if (this.state.queryVal) {
          this.getChiefPatrolList(this.state.queryVal);
          this.getChiefIssueList(this.state.queryVal);
          this.getAreaPatrolList(this.state.queryVal);
          this.getAreaIssueList(this.state.queryVal);
        }
      }
    );
  };

  getChiefPatrolList = (value) => {
    topTchiefList({
      ...value,
      topField: "PATROL",
      topValue: "10",
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          chiefPatrolListTop: res.data,
        });
      }
    });
  };
  getChiefIssueList = (value) => {
    topTchiefList({
      ...value,
      topField: "ISSUE",
      topValue: "10",
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          chiefIssueListTop: res.data,
        });
      }
    });
  };

  getAreaPatrolList = (value) => {
    topTareaList({
      ...value,
      topField: "PATROL",
      topValue: "10",
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          areaPatrolListTop: res.data,
        });
      }
    });
  };
  getAreaIssueList = (value) => {
    topTareaList({
      ...value,
      topField: "ISSUE",
      topValue: "10",
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          areaIssueListTop: res.data,
        });
      }
    });
  };

  handleTableClick = (chiefInfo) => {
    this.setState({
      tableClickInfo: chiefInfo,
    });
  };
  onChange = (e) => {
    this.setState({
      rdValue: e.target.value,
    });
  };

  handleRef = () => {
    this.queryCom.current.handleQuery();
    this.refreshMap.current.loadData();
  };

  render() {
    const {
      chiefPatrolListTop,
      chiefIssueListTop,
      areaPatrolListTop,
      areaIssueListTop,
    } = this.state;

    return (
      <div className="commandContainer">
        <div className="header">
          <div className="sysWeather">
            <Weather></Weather>
          </div>

          <div className="wrapper">
            <div className="sysTitle">
              <div className="sysTitleFont">河长指挥中心</div>
            </div>
            <div className="line"></div>
            <div className="line mTop"></div>
          </div>

          <div className="sysDate">
            <DateCom></DateCom>
          </div>
        </div>
        <div className="content">
          <div className="leftBox">
            <div className="leftBoxTitle">
              <div className="leftBoxTitleWrapper">
                <RectGraduallyShowLoading
                  size="small"
                  style={{
                    position: "none",
                    marginRight: "4px",
                  }}
                />
                河长巡查列表
              </div>
              <Button
                type="primary"
                shape="circle"
                icon="reload"
                size="small"
                onClick={this.handleRef}
              />
            </div>
            <div className="leftBoxTable">
              <QueryCom
                ref={this.queryCom}
                tableClick={this.handleTableClick}
              ></QueryCom>
            </div>
          </div>

          <div className="centerBox">
            <StatisticalDisplay></StatisticalDisplay>
            <div className="centerDownBox">
              <div className="mapContent">
                <MapCenter
                  ref={this.refreshMap}
                  tableClickInfo={this.state.tableClickInfo}
                ></MapCenter>
              </div>
            </div>
          </div>

          <div className="rightBox">
            <div className="queryBar">
              <QueryComR query={this.handleQueryComR}></QueryComR>
              <div className="rightBoxChart">
                <div className="rightBoxTitle">
                  <RectGraduallyShowLoading
                    size="small"
                    style={{
                      position: "none",
                      marginRight: "4px",
                    }}
                  />
                  TOP10
                </div>
                <Radio.Group
                  onChange={this.onChange}
                  value={this.state.rdValue}
                >
                  <Radio value="hzxh">河长巡查</Radio>
                  <Radio value="hzwt">河长问题</Radio>
                  <Radio value="qxxh">区县巡查</Radio>
                  <Radio value="qxwt">区县问题</Radio>
                </Radio.Group>

                <div className="topChart">
                  {this.state.rdValue == "hzxh" ? (
                    chiefPatrolListTop.length > 0 ? (
                      <TopChiefPatrolChart
                        listTop={chiefPatrolListTop && chiefPatrolListTop}
                      ></TopChiefPatrolChart>
                    ) : (
                      <Empty>暂无数据</Empty>
                    )
                  ) : null}
                  {this.state.rdValue == "hzwt" ? (
                    chiefIssueListTop.length > 0 ? (
                      <TopChiefIssueChart
                        listTop={chiefIssueListTop && chiefIssueListTop}
                      ></TopChiefIssueChart>
                    ) : (
                      <Empty>暂无数据</Empty>
                    )
                  ) : null}
                  {this.state.rdValue == "qxxh" ? (
                    areaPatrolListTop.length > 0 ? (
                      <TopAreaPatrolChart
                        listTop={areaPatrolListTop && areaPatrolListTop}
                      ></TopAreaPatrolChart>
                    ) : (
                      <Empty>暂无数据</Empty>
                    )
                  ) : null}
                  {this.state.rdValue == "qxwt" ? (
                    areaIssueListTop.length > 0 ? (
                      <TopAreaIssueChart
                        listTop={areaIssueListTop && areaIssueListTop}
                      ></TopAreaIssueChart>
                    ) : (
                      <Empty>暂无数据</Empty>
                    )
                  ) : null}
                  <div className="issueProportion">
                    <div className="rightBoxTitle">
                      <RectGraduallyShowLoading
                        size="small"
                        style={{
                          position: "none",
                          marginRight: "4px",
                        }}
                      />
                      所有处理中问题
                    </div>
                    <PieChart></PieChart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommandCenter;
