import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Routes from './routes';
import './style/index.less';
import DevTools from './DevTools';
import { store, persistor } from './redux/index';
import { ConnectedRouter } from 'connected-react-router';
import history from 'utils/history';
import { setInterceptors } from 'utils/request';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from "antd";
import { LastLocationProvider } from 'react-router-last-location';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';


moment.locale('zh-cn');

setInterceptors(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <LastLocationProvider>
          <ConfigProvider locale={zhCN}>
            <div>
              <Routes />
              {process.env.NODE_ENV === 'development' ? <DevTools /> : ''}
            </div>
          </ConfigProvider>
        </LastLocationProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
