import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {proposal, proposalId, proposalAccept, proposalFinish, proposalAcceptStatus} = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({url: proposal, method: 'get', data: params,});
}

/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({
    url: proposalId, method: 'get', data: {
      id: paramId
    },
  });
}

/**
 * 编辑
 */
export async function edit(params) {
  return request({url: proposalId, method: 'put', data: params});
}

/**
 * 删除
 */
export async function deleteItem(params) {
  return request({url: proposal+ '/delete' , method: 'post', data: params});
}

/**
 * 创建
 */
export async function createItem(params) {
  return request({url: proposal, method: 'post', data: params,});
}

export async function acceptStatus(paramId) {
  return request({
    url: proposalAcceptStatus, method: 'post', data: {
      id: paramId
    },
  });
}

/**
 * 受理
 */
export async function feedbackAccept(params) {
  return request({url: proposalAccept, method: 'post', data: params,});
}

/**
 * 完成
 */
export async function feedbackFinish(params) {
  return request({url: proposalFinish, method: 'post', data: params,});
}
