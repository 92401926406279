import React, { Component, Fragment } from "react";
import TRTC from "trtc-js-sdk";
import { message, Icon } from "antd";
import "./index.less";
import genTestUserSig from "./GenerateTestUserSig";
import { getUserId } from "../../utils/auth";
let sdkAppId = "1400313263";
let userId = getUserId();
// let userSig = genTestUserSig(userId).userSig; //秘钥
export default class RtcClient extends Component {
  constructor(props) {
    super(props);
    this.client = null;
    this.sdkAppId_ = sdkAppId;
    this.userId_ = userId;
    this.userSig_ = genTestUserSig(userId).userSig;
    this.roomId_ = this.props.roomId;

    this.isJoined_ = false;
    this.isPublished_ = false;
    this.localStream_ = null;
    this.remoteStreams_ = [];
    this.Timer = null;
    this.state = {
      showRemoteStream: false,
      userSig: null,
      switchDiv: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      userSig: genTestUserSig(userId).userSig,
    });
  };

  opJoin = () => this.join();
  opLeave = () => this.leave();

  async join() {
    this.client_ = TRTC.createClient({
      mode: "videoCall", // 实时通话模式
      sdkAppId: this.sdkAppId_,
      userId: this.userId_,
      userSig: this.userSig_,
    });

    console.log("进房成功-------------》》》》》》》！");
    this.Timer = setTimeout(() => {
      message.info("对方未接听");
      this.client_.leave();
    }, 1000 * 60);
    console.log("TTTTTTT", this.Timer);
    console.log("进房成功-------------啊啊啊啊啊啊啊");
    
    this.handleEvents(this.Timer);

    try {
      // join the room
      await this.client_.join({ roomId: this.roomId_ });
      console.log("join room success");
      console.log("进房成功！");
      // message.info("准备视频通话");

      this.isJoined_ = true;
      this.setState({
        showTips: true,
      });
    } catch (error) {
      console.error("failed to join room because: " + error);
      console.log("进房错误！");
      return;
    }

    try {
      // 采集摄像头和麦克风视频流
      await this.createLocalStream({ audio: true, video: true });
      console.log("摄像头及麦克风采集成功！");
      console.log("createLocalStream with audio/video success");
    } catch (error) {
      console.error("createLocalStream with audio/video failed: " + error);
      try {
        // fallback to capture camera only
        await this.createLocalStream({ audio: false, video: true });
        console.log("采集摄像头成功！");
      } catch (error) {
        console.error("createLocalStream with video failed: " + error);
        return;
      }
    }

    this.localStream_.on("player-state-changed", (event) => {
      console.log(`local stream ${event.type} player is ${event.state}`);
      if (event.type === "video" && event.state === "PLAYING") {
        // dismiss the remote user UI placeholder
      } else if (event.type === "video" && event.state === "STOPPPED") {
        // show the remote user UI placeholder
      }
    });
    // 在名为 ‘local_stream’ 的 div 容器上播放本地音视频
    this.localStream_.play("local_stream", {
      objectFit: "contain",
      muted: true,
    });

    // publish local stream by default after join the room
    await this.publish();
    console.log("发布本地流成功！");
  }

  async leave() {

    clearTimeout(this.Timer);
    console.log('=================>>>>>=================>>>>>=================>>>>> clearTimeout(this.Timer);: ',  this.Timer);

    if (!this.isJoined_) {
      console.warn("leave() - leave without join()d observed");
      console.log("请先加入房间！");
      return;
    }

    if (this.isPublished_) {
      // ensure the local stream has been unpublished before leaving.
      await this.unpublish(true);
    }

    try {
      // leave the room
      await this.client_.leave();
      console.log("退房成功！");
      this.isJoined_ = false;
    } catch (error) {
      console.error("failed to leave the room because " + error);
      location.reload();
    } finally {
      // 停止本地流，关闭本地流内部的音视频播放器
      this.localStream_.stop();
      // 关闭本地流，释放摄像头和麦克风访问权限
      this.localStream_.close();
      this.localStream_ = null;
    }
  }

  async publish() {
    if (!this.isJoined_) {
      console.log("请先加入房间再点击开始推流！");
      console.warn("publish() - please join() firstly");
      return;
    }
    if (this.isPublished_) {
      console.warn("duplicate RtcClient.publish() observed");
      console.log("当前正在推流！");
      return;
    }
    try {
      // 发布本地流
      await this.client_.publish(this.localStream_);
      console.log("发布本地流成功！");
      this.isPublished_ = true;
    } catch (error) {
      console.error("failed to publish local stream " + error);
      console.log("发布本地流失败！");
      this.isPublished_ = false;
    }
  }

  async unpublish(isLeaving) {
    if (!this.isJoined_) {
      console.warn("unpublish() - please join() firstly");
      console.log("请先加入房间再停止推流！");
      return;
    }
    if (!this.isPublished_) {
      console.warn("RtcClient.unpublish() called but not published yet");
      console.log("当前尚未发布本地流！");
      return;
    }

    try {
      // 停止发布本地流
      await this.client_.unpublish(this.localStream_);
      this.isPublished_ = false;
      console.log("停止发布本地流成功！");
    } catch (error) {
      console.error("failed to unpublish local stream because " + error);
      console.log("停止发布本地流失败！");
      if (!isLeaving) {
        console.warn("leaving the room because unpublish failure observed");
        console.log("停止发布本地流失败，退出房间！");
        this.leave();
      }
    }
  }

  async createLocalStream(options) {
    this.localStream_ = TRTC.createStream({
      audio: options.audio, // 采集麦克风
      video: options.video, // 采集摄像头
      userId: this.userId_,
      // cameraId: getCameraId(),
      // microphoneId: getMicrophoneId()
    });
    // 设置视频分辨率帧率和码率
    this.localStream_.setVideoProfile("1080p");

    await this.localStream_.initialize();
  }

  handleEvents(Timer) {
    // 处理 client 错误事件，错误均为不可恢复错误，建议提示用户后刷新页面
    this.client_.on("error", (err) => {
      console.error(err);
      // alert(err);
      console.log("客户端错误：" + err);
      // location.reload();
    });

    // 处理用户被踢事件，通常是因为房间内有同名用户引起，这种问题一般是应用层逻辑错误引起的
    // 应用层请尽量使用不同用户ID进房
    this.client_.on("client-banned", (err) => {
      console.error("client has been banned for " + err);
      console.log("用户被踢出房间！");
      // location.reload();
    });

    // 远端用户进房通知 - 仅限主动推流用户
    this.client_.on("peer-join", (evt) => {
      const userId = evt.userId;
      this.whetherRS = evt.userId;
      console.log("peer-join " + userId);
    });
    // 远端用户退房通知 - 仅限主动推流用户
    this.client_.on("peer-leave", (evt) => {
      const userId = evt.userId;
      console.log("peer-leave " + userId);
      console.log("远端用户退房 - " + userId);
      message.info("对方已挂断");
      this.leave();
      this.removeView();
      clearTimeout(Timer);
      console.log('   =================>>>>>远端用户退房   clearTimeout(Timer);: ',       Timer);

    });

    // 处理远端流增加事件
    this.client_.on("stream-added", (evt) => {
      const remoteStream = evt.stream;
      const id = remoteStream.getId();
      const userId = remoteStream.getUserId();
      console.log(
        `remote stream added: [${userId}] ID: ${id} type: ${remoteStream.getType()}`
      );
      console.log("远端流增加 - " + userId);
      // 远端流默认已订阅所有音视频，此处可指定只订阅音频或者音视频，不能仅订阅视频。
      // 如果不想观看该路远端流，可调用 this.client_.unsubscribe(remoteStream) 取消订阅
      this.client_.subscribe(remoteStream);
    });

    // 远端流订阅成功事件
    this.client_.on("stream-subscribed", (evt) => {
      const remoteStream = evt.stream;
      const id = remoteStream.getId();
      this.remoteStreams_.push(remoteStream);
      this.addView(id);
      // 在指定的 div 容器上播放音视频

      remoteStream.play("remote_stream", {
        objectFit: "contain",
        muted: false,
      });
      remoteStream.muteAudio();
      console.log("stream-subscribed ID: ", id);
      console.log("远端流订阅成功 - " + remoteStream.getUserId());
    });

    // 处理远端流被删除事件
    this.client_.on("stream-removed", (evt) => {
      const remoteStream = evt.stream;
      const id = remoteStream.getId();
      // 关闭远端流内部的音视频播放器
      remoteStream.stop();
      this.remoteStreams_ = this.remoteStreams_.filter((stream) => {
        return stream.getId() !== id;
      });
      this.removeView();
      // removeView(id);
      console.log(`stream-removed ID: ${id}  type: ${remoteStream.getType()}`);
      console.log("远端流删除 - " + remoteStream.getUserId());
    });

    // 处理远端流更新事件，在音视频通话过程中，远端流音频或视频可能会有更新
    this.client_.on("stream-updated", (evt) => {
      const remoteStream = evt.stream;
      console.log(
        "type: " +
          remoteStream.getType() +
          " stream-updated hasAudio: " +
          remoteStream.hasAudio() +
          " hasVideo: " +
          remoteStream.hasVideo()
      );
      clearTimeout(Timer);
      console.log(' =================>>>>>处理远端流更新事件clearTimeout(Timer): ',  clearTimeout(Timer));
      if (remoteStream.hasVideo()) {
        remoteStream.unmuteAudio();
        this.setState({
          showTips: false,
        });
      }
      console.log("远端流更新！");
    });

    // 远端流音频或视频mute状态通知
    this.client_.on("mute-audio", (evt) => {
      console.log(evt.userId + " mute audio");
    });
    this.client_.on("unmute-audio", (evt) => {
      console.log(evt.userId + " unmute audio");
    });
    this.client_.on("mute-video", (evt) => {
      console.log(evt.userId + " mute video");
    });
    this.client_.on("unmute-video", (evt) => {
      console.log(evt.userId + " unmute video");
    });

    // 信令通道连接状态通知
    this.client_.on("connection-state-changed", (evt) => {
      console.log(
        `RtcClient state changed to ${evt.state} from ${evt.prevState}`
      );
      console.log("evt.state: ", evt.state);
    });
  }

  addView = () => {
    this.setState({
      showRemoteStream: true,
    });
  };
  removeView = () => {
    this.setState(
      {
        showRemoteStream: false,
      },
      () => this.props.cancelCall()
    );
  };
  handleHangeUp = () => {
    this.leave();
    this.removeView();
  };
  handleSwitch = () => {
    this.setState({
      switchDiv: !this.state.switchDiv,
    });
  };
  render() {
    const { showRemoteStream, showTips } = this.state;
    return (
      <Fragment>
        <div className="trtcContainer" style={{ position: "absolute" }}>
          <div className="video-grid">
            <div
              id={this.state.switchDiv ? "local_stream" : "remote_stream"}
              className="video-placeholder"
              onClick={this.handleSwitch}
            ></div>
            {showTips ? <h2>连线中...</h2> : null}
            <div
              id={!this.state.switchDiv ? "local_stream" : "remote_stream"}
              className="video-placeholder"
              onClick={this.handleSwitch}
            ></div>
            <div className="hangUpBox">
              <div className="hangUpItem" onClick={this.handleHangeUp}>
                <Icon type="phone" />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
