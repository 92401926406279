import config from '../utils/apiconfig';
import request from '../utils/request';

const {api} = config;
const { station, stationId,stationMapData} = api;

/**
 * 查询 列表
 */
export async function list(params) {
  return request({url: station, method: 'get', data: params,});
}

export async function getMapData(params) {
  return request({url: stationMapData, method: 'get', data: params,});
}
/**
 * 查询 详情
 */
export async function getDetail(paramId) {
  return request({url: stationId, method: 'get', data: {
      id:paramId
    }});
}
/**
 * 编辑 
 */
export async function edit(params) {
  return request({url: stationId, method: 'put', data: params});
}
/**
 * 删除 
 */
export async function deleteItem (params) {
  return request({url: station+ '/delete' , method: 'post', data:params});
}
/**
 * 创建 
 */
export async function createItem(params) {
  return request({url: station, method: 'post', data: params,});
}
