import config from '../utils/apiconfig';
import request from '../utils/request';

const {api} = config;
const {areaIssueDayReport, areaPatrolAreaLevelReport, areaPatrolAreaLevelDetailReport, mineTaskReport, problemsInProcessingChart, lastestWt, lastestXj, wt, xj} = api;


export async function findAreaIssueDayReport(params) {
  return request({url: areaIssueDayReport, method: 'get', data: params,});
}

export async function findAreaPatrolAreaLevelReport(params) {
  return request({url: areaPatrolAreaLevelReport, method: 'get', data: params,});
}

export async function findAreaPatrolAreaLevelDetailReport(params) {
  return request({url: areaPatrolAreaLevelDetailReport, method: 'get', data: params,});
}

export async function findMineTaskReport(params) {
  return request({url: mineTaskReport, method: 'get', data: params,});
}

export async function getProblemsInProcessingChart(params) {
  return request({url: problemsInProcessingChart, method: 'get', data: params,});
}


export async function findLastestWtReport(params) {
  return request({url: lastestWt, method: 'get', data: params,});
}

export async function findWt(params) {
  return request({url: wt, method: 'get', data: params,});
}

export async function findLastestXjReport(params) {
  return request({url: lastestXj, method: 'get', data: params,});
}

export async function findXj(params) {
  return request({url: xj, method: 'get', data: params,});
}

