import "./index.less";

import {
  Avatar,
  Badge,
  Dropdown,
  Icon,
  Layout,
  Menu,
  Popover,
  message
} from "antd";

import AppQrcode from "../../components/AppQrcode/AppQrcode";
import FeedbackAdd from "../../systemManage/feedback/FeedbackAdd";
import React from "react";
import ResetPwd from "./ResetPwd";
import { clearAuth } from "utils/auth";
import { connect } from "react-redux";
import { createItem as feedbackCreate } from "../../../api/feedback";
import { getQrCode } from "../../../api/basic";
import { mineMessageList } from "api/messageNotification";

const { Header } = Layout;
@connect(state => {
  return {
    loginUser: state.loginUser.data,
    loginUserAllData: state.loginUser,
    permissions: state.permission.data
  };
})
export default class Top extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      visible: false,
      messageTotal: 0,
      modalShow: false,
      showRwd: false
    };
    this.messageUnradData = {
      status: "UNREAD",
      pageSize: 10,
      page: 1
    };
  }

  getMineMessageList = () => {
    return new Promise((resolve, reject) => {
      mineMessageList(this.messageUnradData).then(res => {
        if (res.code === 200) {
          resolve(res);
        } else {
          reject("消息列表获取失败");
        }
      });
    });
  };

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000 * 60);
    if (this.props.permissions.includes("PERMS_MESSAGE_LIST")) {
      this.getMineMessageList().then(res => {
        this.setState({ messageTotal: res.total });
      });
    }
    getQrCode({ appType: "ANDROID" }).then(res => {
      if (res.code === 200) {
        this.setState({
          qrData: res.data
        });
      }
    });
  }
  tick() {
    this.getMineMessageList().then(res => {
      this.setState({ messageTotal: res.total });
    });
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onCancel = () => {
    this.setState({
      visible: false,
      showRwd: false
    });
  };
  dropDownEvents = item => {
    if (item.key === "logOut") {
      location.href = "/login";
      clearAuth();
    }
    if (item.key === "resetPwd") {
      this.setState({
        visible: true
      });
    }
    if (item.key === "feedback") {
      this.setState({ modalShow: true });
    }
  };
  goMessagePage = () => {
    sessionStorage.setItem("activeItem", "messageNotification");
    location.href = "/messageNotification";
  };
  menu = (
    <Menu className="logOut" onClick={this.dropDownEvents}>
      <Menu.Item key="resetPwd">个人中心</Menu.Item>
      <Menu.Item key="feedback">意见反馈</Menu.Item>
      <Menu.Item key="logOut">退出</Menu.Item>
    </Menu>
  );
  onOk = data => {
    if (data.files) {
      data.files = data.files.map(ele => {
        return ele.url;
      });
    }
    feedbackCreate(data).then(res => {
      this.setState({
        modalLoading: false
      });
      if (res.code === 200) {
        message.info("反馈成功!");
        this.setState({
          modalShow: false
        });
      }
    });
  };
  showForm = () => {
    this.setState({
      showRwd: true
    });
  };

  render() {
    const loginUser =
      (this.props.loginUser && this.props.loginUser.principal) || {};
    return (
      <Header className="header-top">
        <div className="logBg">
          <img className="logo" src={require("images/layout/ls--logo.png")} />
        </div>
        <div className="header-right">
          
          <Popover
            content={<AppQrcode appversion={this.state.qrData} />}
            title="客户端下载"
            placement="bottomRight"
          >
            <div className="QrCodeBox">
              <Icon type="qrcode" />
            </div>
          </Popover>
          {this.props.permissions &&
          this.props.permissions instanceof Array &&
          this.props.permissions.includes("PERMS_MESSAGE_LIST") ? (
            <span className="message-out" onClick={this.goMessagePage}>
              <Badge count={this.state.messageTotal}>
                <i className="rvicon rvicon-xiaoxi" />
              </Badge>
            </span>
          ) : (
            ""
          )}

          <Avatar src={loginUser.avatarUrl} className="user-img" icon="user" />
          <Dropdown overlay={this.menu}>
            <a className="ant-dropdown-link">
              {loginUser.nickname} <Icon type="down" />
            </a>
          </Dropdown>
        </div>
        
        <ResetPwd

          onCancel={this.onCancel}
          visible={this.state.visible}
          showForm={this.showForm}
          showRwd={this.state.showRwd}
        />
        <FeedbackAdd
          onOk={this.onOk}
          modalShow={this.state.modalShow}
          onCancel={() => {
            this.setState({ modalShow: false });
          }}
          modalData={{ source: "WEB" }}
          modalKey={"add"}
          title={"添加"}
          hasDefault={true}
        />
      </Header>
    );
  }
}
