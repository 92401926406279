import { realtimeMonitoring } from "api";

export const RTMLIST = "RTMLIST";

export const RTMLIST_NEW = "RTMLIST_NEW";

export const getRTMAction = data => ({
  type: RTMLIST,
  payload: {
    promise: realtimeMonitoring.list(data)
  }
});

export const getRTMActionNew = data => ({
  type: RTMLIST_NEW,
  payload: {
    promise: realtimeMonitoring.list(data)
  }
});