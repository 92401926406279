import localForage from "localforage";

import { store } from "../redux";
import Cookies from "js-cookie";
import { actionPermissions } from "../redux/actions/basic";

const TokenKey = "userToken";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 3 });
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function hasPermission(perm) {
  //permissions.includes('PERMS_PZSUM_ADMIN')
  return store.getState().permission.data.includes(perm);
}

export function setUserId(id) {
  return localStorage.setItem("userId", id);
}
export function getUserId() {
  return localStorage.getItem("userId");
}

export const auth = data => {
  setToken(data.token);
  const permissions = [];
  data.principal.authorities.map(ele => {
    if (!permissions.includes(ele.authority)) {
      permissions.push(ele.authority);
    }
  });
  localStorage.setItem("roles", data.roles);

  return store.dispatch(actionPermissions(permissions));
};

export const clearAuth = () => {
  removeToken();
  localStorage.removeItem("roles");
  sessionStorage.removeItem("activeItem");
  localForage.removeItem("persist:permission");
  // localForage.removeItem('persist:emums');
  localForage.removeItem("persist:root");
  localStorage.removeItem("userId");
};
