import config from "utils/apiconfig";
import request from "utils/request";

const { api } = config;
const { filecon, fileconId } = api;

/**
 * 查询河流列表
 */
export async function fileconList(params) {
  return request({ url: filecon, method: "get", data: params });
}

/**
 * 查询河流详情
 */
export async function fileconDetail(id) {
  return request({
    url: fileconId,
    method: "get",
    data: {
      damId: id
    }
  });
}
