import config from "utils/apiconfig";
import request from "utils/request";

const { api } = config;
const { river, riverId, riverReachRiver, riverMapData } = api;

/**
 * 查询河流列表
 */
export async function riverList(params) {
  return request({ url: river, method: "get", data: params });
}
/**
 * 查询河流聚合列表
 */
export async function riverReachRiverList(params) {
  return request({ url: riverReachRiver, method: "get", data: params });
}
/**
 * 查询河流详情
 */
export async function getRiverDetail(id) {
  return request({
    url: riverId,
    method: "get",
    data: {
      riverId: id
    }
  });
}
/**
 * 编辑河流
 */
export async function editRiver(params) {
  return request({ url: riverId, method: "put", data: params });
}
/**
 * 删除河流
 */
export async function deleteRiver(params) {
  return request({ url: river, method: "delete", data: params });
}
/**
 * 创建河流
 */
export async function createRiver(params) {
  return request({ url: river, method: "post", data: params });
}

export async function getMapData(params) {
  return request({ url: riverMapData, method: "get", data: params });
}
