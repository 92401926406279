import {
    VIDEOSURVEILLANCELIST,
    HYDROLOGYPERFORMANCERT,
    HYDROLOGYPERFORMANCEHISTORY,
  } from '../actions/videoSurveillance';
  import {reducerPackage} from "../../utils";
  
  export const videoSurveillanceList = reducerPackage(VIDEOSURVEILLANCELIST);
  export const hydrologyPerformanceRtList = reducerPackage(HYDROLOGYPERFORMANCERT);
  export const hydrologyPerformanceHistoryList = reducerPackage(HYDROLOGYPERFORMANCEHISTORY);
