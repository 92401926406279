import { task } from "api";

export const TASKMELIST = "TASKMELIST";
export const TASKCOMPLETELIST = "TASKCOMPLETELIST";
export const TASKCOMPLETEXBLIST = "TASKCOMPLETEXBLIST";
export const TASKCOMPLETECSLIST = "TASKCOMPLETECSLIST";

export const taskMeAction = (data) => ({
  type: TASKMELIST,
  payload: {
    promise: task.taskMeList(data),
  },
});
export const taskCompleteAction = (data) => ({
  type: TASKCOMPLETELIST,
  payload: {
    promise: task.taskCompleteList(data),
  },
});
export const taskCompletexiebanAction = (data) => ({
  type: TASKCOMPLETEXBLIST,
  payload: {
    promise: task.taskCompleteList(data),
  },
});
export const taskCompleteChaosongAction = (data) => ({
  type: TASKCOMPLETECSLIST,
  payload: {
    promise: task.taskCompleteList(data),
  },
});
