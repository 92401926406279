import React, { Fragment, Component } from "react";
import { wxRelated } from "../../api";
import "./index.less";
import history from "utils/history";
import InfiniteScroll from "react-infinite-scroller";

import { Input, Icon, List, Spin, Button } from "antd";
import CrossType from "../oneMap/crossType";

export default class RiverDe extends Component {
  state = {
    data: [],
    loading: false,
    hasMore: true,
    pageNum: 1,
    pageSize: 10,
  };

  handleClick = (id) => {
    history.push({
      pathname: "/wxRiverDeMap",
      state: { id },
    });
  };

  handleChange = (e) => {
    let value = e.target.value;
    this.setState({
      value,
    });
    this.fetchData(null, 1, value);
  };

  handleClickSeach = () => {
    this.fetchData(null, 1, this.state.value);
  };

  componentDidMount() {
    this.fetchData((res) => {
      this.setState({
        data: res.data,
      });
    });
  }

  fetchData = (callback, pageNum, searchText = "") => {
    wxRelated.wxRlist({ searchText: searchText, page: pageNum }).then((res) => {
      if (res.code === 200) {
        this.setState({
          data: res.data,
          total: res.total,
        });
        callback && callback(res);
      }
    });
  };

  handleInfiniteOnLoad = () => {
    let { data } = this.state;
    this.setState({
      loading: true,
      pageNum: this.state.pageNum + 1,
    });
    if (data.length === this.state.total) {
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }
    this.fetchData((res) => {
      data = data.concat(res.data);
      this.setState({
        data,
        loading: false,
      });
    }, this.state.pageNum);
  };

  render() {
    return (
      <div className="demo-infinite-container container-river de ">
        <div className="header">
          <form action="javascript:void(0)">
            <input
              type="search"
              placeholder="请输入河流名称"
              value={this.state.value}
              onChange={this.handleChange}
            />
            <Button
              type="primary"
              icon="search"
              size="default"
              onClick={this.handleClickSeach}
            />
          </form>
        </div>
        <InfiniteScroll
          initialLoad={false}
          pageStart={1}
          loadMore={this.handleInfiniteOnLoad}
          hasMore={!this.state.loading && this.state.hasMore}
          useWindow={false}
        >
          <List
            dataSource={this.state.data}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                onClick={() => this.handleClick(item.id)}
              >
                <div className="cn">
                  <CrossType type={item.crossType} />
                  {item.name}
                </div>
                <div className="arrow">
                  <Icon type="right" />
                </div>
              </List.Item>
            )}
          >
            {this.state.loading && this.state.hasMore && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </div>
    );
  }
}
