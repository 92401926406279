import {loginlog} from 'api';

export const LOGINLOGLIST = 'LOGINLOGLIST';


export const tableAction = (data) => ({
  type: LOGINLOGLIST,
  payload: {
    promise: loginlog.list(data)
  }
});
