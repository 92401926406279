import config from "../utils/apiconfig";
import request from "../utils/request";

const { api } = config;
const { wetland, wetlandId, wetlandAll, wetlandMapData } = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({ url: wetland, method: "get", data: params });
}
export async function Alllist(params) {
  return request({ url: wetlandAll, method: "get", data: params });
}

export async function getMapData(params) {
  return request({ url: wetlandMapData, method: "get", data: params });
}

/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({
    url: wetlandId,
    method: "get",
    data: {
      id: paramId,
    },
  });
}

/**
 * 编辑
 */
export async function edit(params) {
  return request({ url: reservoirId, method: "put", data: params });
}

/**
 * 删除
 */
export async function deleteItem(params) {
  return request({ url: reservoir, method: "delete", data: params });
}

/**
 * 创建
 */
export async function createItem(params) {
  return request({ url: reservoir, method: "post", data: params });
}
