import {ratingChief} from 'api';

export const RATINGCHIEFLIST = 'RATINGCHIEFLIST';


export const tableAction = (data) => ({
  type: RATINGCHIEFLIST,
  payload: {
    promise: ratingChief.list(data)
  }
});
