import React, { Component } from "react";
import "./index.less";
import history from "utils/history";

export default class NewsDe extends Component {
  render() {
    const content =
      history &&
      history.location &&
      history.location.state &&
      history.location.state.content;
    return (
      <div
        className="contentText"
        dangerouslySetInnerHTML={{
          __html: content || ""
        }}
      ></div>
    );
  }
}
