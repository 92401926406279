import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {Button, Form, Icon, Input, message} from "antd";
import {auth, clearAuth} from "../../utils/auth";
import {bindThirdAccount} from "../../api/user";
import history from "../../utils/history";
import { chiefManage, user } from "../../api"
import {loginUser, refreshUser} from "../../redux/actions/login";
import {actionEmums} from "../../redux/actions/basic";
import styled from "styled-components"
import LogoImg from "../../images/bind-third-account.png"

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    padding-top: 100px;
    background: rgb(240, 242, 245);
`
const HeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    & p {
        margin: 10px 0px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
    }
`
const HeaderWrapperBox = styled.div`
    display: flex;
    align-items: center;
`
const LogoBox = styled.div`
    background: url(${LogoImg});
    background-repeat: no-repeat;
    background-size: contain;
    width: 48px;
    height: 48px;
    margin-right: 10px;
`
const NameBox = styled.div`
    text-align: center;
    font-size: 33px;
    color: rgba(0, 0, 0, 0.85);
    font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 600;
`
const LoginBox = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 368px;
    margin: 0px auto;
    & h4 {
        text-align: center;
        margin: 0px 0px 20px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: bold;
    }
    & .logout {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
`


@connect(
  state => {
    return {
      emums: state.emums,
      loginUserData: state.loginUser.allData
    }
  },
  { loginUser, actionEmums, refreshUser }
)
@Form.create()
export default class BindThirdAccount extends React.Component {

  constructor(props) {
    super(props);
    //const that = this;
    this.state = {
      loading: false
    }
  }

  componentDidMount() {

  }

  onChangeUserName = e => {
    this.props.form.setFieldsValue({loginUniqueKey: e.target.value})
  }


  emitEmpty = () => {
    this.userNameInput.focus()
    this.props.form.setFieldsValue({loginUniqueKey: ""})
  }



  //获取用户详情
  getUserDetail = async paramId => {
    return new Promise((resolve, reject) => {
      user.getUserDetail(paramId).then(res => {
        if (res.code === 200) {
          resolve(res.data)
        } else {
          resolve({})
          message.error("用户信息获取失败")
        }
      })
    })
  }
  //获取河长详情
  getChiefDetail = async paramId => {
    return new Promise((resolve, reject) => {
      chiefManage.getDetail(paramId).then(res => {
        if (res.code === 200) {
          resolve(res.data)
        } else {
          resolve({})
        }
      })
    })
  }

  enterLoading = status => {
    this.setState({ loading: status })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      console.log("values", values)
      if (!err) {
        bindThirdAccount(values).then(res => {
          if (res.code === 200) {
            clearAuth();
            message.info("登录成功!")
            auth(res.data)
            const userId = res.data.principal.id
            Promise.all([
              this.getUserDetail(userId),
              this.getChiefDetail(userId)
            ]).then(values => {
              const userPrincipal = Object.assign({}, values[0], values[1])
              this.enterLoading(false)
              const userAllData = this.props.loginUserData
              userAllData.data.principal = userPrincipal
              localStorage.setItem(
                "avatarUrl",
                userAllData.data.principal.avatarUrl
              )
              userAllData.data.getDetail = true
              const rolesFirstKey = Object.keys(
                userAllData.data.principal.roles
              )[0]
              userAllData.data.principal.roles =
                userAllData.data.principal.roles[rolesFirstKey]
              this.props.refreshUser(userAllData).then(res => {
                let activeItems = sessionStorage.getItem("activeItem")
                if (activeItems) {
                  let nowPath = activeItems.split(",")
                  nowPath = "/" + nowPath[nowPath.length - 1]
                  history.push(nowPath)
                } else {
                  history.push("/index")
                }
              })
            })
          }

        })

      }
    })
  }

  render() {

    const {getFieldDecorator} = this.props.form
    const suffix = this.props.form.getFieldsValue().loginUniqueKey ? (
      <Icon type="close-circle" onClick={this.emitEmpty}/>
    ) : null

    return (
<Container>
                <HeaderBox>
                    <HeaderWrapperBox>
                        <LogoBox />
                        <NameBox>智慧河长</NameBox>
                    </HeaderWrapperBox>
                    <p>首次使用请绑定您的智慧河长账号</p>
                </HeaderBox>
                <LoginBox>
                    <h4>登 录</h4>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item>
                            {getFieldDecorator("loginUniqueKey", {
                                rules: [
                                    { required: true, message: "请输入账号" }
                                ]
                            })(
                                <Input
                                    placeholder="请输入账号"
                                    prefix={
                                        <Icon
                                            type="user"
                                            className="icon-change"
                                        />
                                    }
                                    suffix={suffix}
                                    onChange={this.onChangeUserName}
                                    ref={node => (this.userNameInput = node)}
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("passwd", {
                                rules: [
                                    { required: true, message: "请输入密码" }
                                ]
                            })(
                                <Input
                                    type="password"
                                    placeholder="请输入密码"
                                    prefix={
                                        <Icon
                                            type="lock"
                                            className="icon-change"
                                        />
                                    }
                                />
                            )}
                        </Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            loading={this.state.loading}
                        >
                            确认绑定
                        </Button>
                    </Form>
                    <div className="logout">
                        <a onClick={()=>{
                          clearAuth();
                          location.href = '/login';
                        }}>退出</a>

                    </div>
                </LoginBox>
            </Container>
    );
  }


  static contextTypes = {}
  static propTypes = {}
  static defaultProps = {}
}
