import {
  RESERVOIRMANAGELIST,
  RESERVOIR,
  RESERVOIRALL,
  RESERVOIRALLLISTDETAILS
} from "actions/reservoirManage";
import { reducerPackage } from "../../utils";

export const reservoirManageList = reducerPackage(RESERVOIRMANAGELIST);
export const reservoir = reducerPackage(RESERVOIR);
export const reservoirManageAllList = reducerPackage(RESERVOIRALL);
