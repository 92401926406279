import moment from "moment"
import { clearAuth } from "./auth"
import localForage from "localforage"
import { message } from "antd"
import history from "./history"
import { isImgFile } from "./sha1file"

export const filterEmums = function(emums, key, disableKey) {
  const arrayData = []
  let allData
  if (emums && typeof emums === "object" && emums[key]) {
    allData = emums[key] || ""
  } else {
    console.log(`没有发现可用枚举!`)
    localForage.removeItem("persist:emums")
    history.push("/login")
    // sessionStorage.removeItem('activeItem');
    return
  }
  for (let item in allData) {
    if (item === disableKey) break
    arrayData.push({
      name:
        allData[item]["enumDesc"] === "已完成"
          ? "已办结"
          : allData[item]["enumDesc"] === "草稿"
          ? "已更新"
          : allData[item]["enumDesc"] === "创建"
          ? "已新建"
          : allData[item]["enumDesc"] === "发布"
          ? "已发布"
          : allData[item]["enumDesc"] === "办理中"
          ? "处理中" : allData[item]["enumDesc"] === '135考核'? '十三五考核':allData[item]["enumDesc"],
      value: allData[item]["enumName"]
    })
  }
  return arrayData
}
export const itemReplaceEmums = (emums, item, values, fields) => {
  values.map((ele, index) => {
    const useData = emums[fields[index].trim()]
    if (item[values[index]] && useData[item[values[index]]]) {
      item[values[index]] =
        useData[item[values[index]]]["enumDesc"] === "巡查督办"
          ? "巡查提醒"
          : useData[item[values[index]]]["enumDesc"] === "任务流程"
          ? "问题督办"
          : useData[item[values[index]]]["enumDesc"] === "告警提醒"
          ? "超时提醒"
          : useData[item[values[index]]]["enumDesc"]
    }
  })
  return item
}
export const getEmumsDesc = (emums, item, value, field) => {
  const currentEmums = emums[field]
  let str = ""
  if (item && item[value] && currentEmums) {
    str =
      currentEmums[item[value]]["enumDesc"] === "草稿"
        ? "已更新"
        : currentEmums[item[value]]["enumDesc"] === "创建"
        ? "已新建"
        : currentEmums[item[value]]["enumDesc"] === "发布"
        ? "已发布"
        : currentEmums[item[value]]["enumDesc"]
  }
  return str
}
export const areaLevel = {
  province: 1,
  city: 2,
  district: 3,
  town: 4,
  village: 5
}
export const chiefLevelEmums = {
  city: 1,
  district: 2,
  town: 3,
  village: 4
}
export const areaNames = ["province", "CITY", "DISTRICT", "TOWN", "VILLAGE"]
export const areaReplace = (area, domain) => {
  let backData = {
    areaName: "",
    areaId: []
  }
  let useDomain = (domain && domain.toLowerCase()) || ""
  let domainLevel = areaLevel[useDomain] || 1
  if (area) {
    for (const item in areaLevel) {
      const nowLevel = areaLevel[item]
      if (area[item] && nowLevel >= domainLevel) {
        backData.areaId.push(area[item].id.toString())
        backData.areaName +=
          nowLevel > domainLevel ? `/${area[item].name}` : area[item].name
      }
    }
    if (!backData.areaName) {
      backData.areaId = [area.id.toString()]
      backData.areaName = area.name
    }
  }
  return backData
}
/**
 * 时间段过滤，以天和秒的形式返回时间段
 * 单个时间过滤，以天，小时，秒等形式，返回时间格式
 * @type {{rangeFormate: function(*, *), toDay: function(*=): (string | string[] | string), toSecond: function(*=): (string | string[] | string), switchSconds: function(*=): string, toText: function(*=): string}}
 */
export const dateFormat = {
  rangeFormate: (values, fields) => {
    const backValues = {}
    backValues[fields[0]] = moment(values[0]).format("YYYY-MM-DD")
    backValues[fields[1]] = moment(values[1]).format("YYYY-MM-DD")
    return backValues
  },
  rangeFormateSeconds: (values, fields) => {
    const backValues = {}
    backValues[fields[0]] = moment(values[0]).format("YYYY-MM-DD HH:mm:ss")
    backValues[fields[1]] = moment(values[1]).format("YYYY-MM-DD HH:mm:ss")
    return backValues
  },
  toDay: values => {
    let backValues = ""
    if (values instanceof Array) {
      backValues = []
      backValues = values.map(ele => {
        return moment(ele).format("YYYY-MM-DD")
      })
    } else {
      backValues = moment(values).format("YYYY-MM-DD")
    }
    return backValues
  },
  toSecond: values => {
    let backValues = ""
    if (values instanceof Array) {
      backValues = []
      backValues = values.map(ele => {
        return moment(ele).format("YYYY-MM-DD HH:mm:ss")
      })
    } else {
      backValues = moment(values).format("YYYY-MM-DD HH:mm:ss")
    }
    return backValues
  },
  switchSconds: value => {
    let time = parseFloat(value)
    let allStr = ""
    const minuteFormat = 60
    const hourFormat = minuteFormat * 60
    const dayFormat = hourFormat * 24
    const day = parseInt(time / dayFormat)
    const strDay = day >= 10 ? day : `0${day}`
    if (day > 0) allStr += `${strDay}天`
    const hours = parseInt((time - day * dayFormat) / hourFormat)
    const strHours = hours >= 10 ? hours : `0${hours}`
    if (hours > 0) allStr += `${strHours}小时`
    const minutes = parseInt(
      (time - day * dayFormat - hours * hourFormat) / minuteFormat
    )
    const strMinutes = minutes >= 10 ? minutes : `0${minutes}`
    if (minutes > 0) allStr += `${strMinutes}分钟`
    return allStr
  },
  toText: value => {
    let backValue = moment(value).format("YYYY-MM-DD")
    backValue = backValue.replace(/-/g, "")
    return backValue
  }
}
export const orgPostsAll = {
  filter: (dataItems, name, attr) => {
    let useAttr = attr || []
    const dataArray = []
    const EditId = ''
    if (dataItems[name]) {
      const values = dataItems[name]
      // if (values.keys && values.keys.length > 0) {
      //   values.keys.map(ele => {
      //     const itemObj = {}
      //     itemObj[useAttr[0] || "orgId"] = values.names[ele]
      //     itemObj[useAttr[1] || "post"] = values.position[ele]
      //     dataArray.push(itemObj)
      //   })
      // }
      dataItems[name] = values && [].concat({orgId:values,post:dataItems.position}) //接口没有修改，只传递区域ID
    }
    return dataItems
  },
  back: (dataItems, name) => {
    const nowFormValue = {
      keys: [],
      names: [],
      position: [],
      text: [],
      organizationValues: []
    }
    dataItems[name].map((ele, index) => {
      nowFormValue.keys.push(index)
      nowFormValue.names.push(ele.org.id)
      nowFormValue.position.push(ele.post)
      nowFormValue.organizationValues.push(ele.org)
      nowFormValue.text.push(ele.org.name)
    })
    dataItems[name] = nowFormValue.text.toString()
    return dataItems
  },
  backEdit: (dataItems, name,metaData) => {
    if(metaData && metaData.orgPosts &&  metaData.orgPosts[0] && metaData.orgPosts[0].org && metaData.orgPosts[0].org.name == dataItems[name]){
      dataItems[name] = dataItems && [].concat({orgId:metaData.orgPosts[0].org.id,post:dataItems.position}) 
    }else{
      dataItems[name] = dataItems && [].concat({orgId:dataItems[name],post:dataItems.position}) 
    }
    return dataItems
  }
}
export const treeFindPath = (selectedKeys, treeData) => {
  let sel = []

  function findPath(selectedId, children) {
    for (var i = 0; i < children.length; i++) {
      const item = children[i]
      if (selectedId === item.id) {
        sel.push(item.id)
        return
      }
      if (item.children) {
        findPath(selectedId, item.children, item)
        if (sel.length) {
          sel.push(item.id)
          return
        }
      }
    }
  }

  findPath(selectedKeys[0], treeData)
  //todo 从叶到根,想反过来自己数组翻转
  console.log("树节点路径:", sel)
  return sel
}
/**
 *
 * @param url
 * @returns {boolean}
 */
export const isVideo = url => {
  const reg = /\.(avi|3gp|flv|mp4|ogg)$/
  return reg.test(url)
}
/**
 * 数据分离图片和视频
 * @param data
 * @param key
 */
export const separatImgsAndVideos = (data, key) => {
  const useImgs = []
  const useVideo = []
  const useFiles = []
  if (data[key] && data[key] instanceof Array) {
    data[key].map(ele => {
      if (isImgFile(ele.raw.name)) {
        useImgs.push(ele.url)
      } else if (isVideo(ele.raw.name)) {
        useVideo.push(ele.url)
      } else {
        useFiles.push(ele.url)
      }
    })
  }
  return {
    imgs: useImgs,
    videos: useVideo,
    files: useFiles
  }
}
