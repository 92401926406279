import React, { Component } from "react";
import { wxRelated } from "../../api";
import { Icon, Divider, Empty } from "antd";
import history from "utils/history";
import RvIcon from "../components/RvIcon";
import "./index.less";
export default class MoatAmbassador extends Component {
  state = {
    data: [],
  };
  componentDidMount = () => {
    wxRelated.wxMaList().then((res) => {
      if (res.code === 200) {
        this.setState({
          data: res.data.sort((a, b) => b.reportNum - a.reportNum),
        });
      }
    });
  };

  handlePush = (phone) => {
    history.push({
      pathname: "/wxMaDe",
      state: { phone },
    });
  };

  render() {
    const { data } = this.state;
    return (
      <div className="container-river body">
        {
          data.length > 0 ?
            <div className="maList">
              {
                data.map((v, indx) => (
                  <div className="maItems" key={indx}>
                    <div
                      className="maItemU"
                      onClick={() => this.handlePush(v.reportPhone)}
                    >
                      <div className="maLeft">
                        <div className="maLeftImg">
                          <img
                            src={v.reportHeader}
                            alt=""
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div className="maLeftInfo">
                          <div className="maLeftInfoWrapper">
                            <div className="names">{v.reportUser}</div>
                            <div className="hz">
                              {indx == 0 ? (
                                <RvIcon
                                  type="rvicon-huizhang"
                                  style={{
                                    color: "rgba(247, 137, 0, 1)",
                                  }}
                                ></RvIcon>
                              ) : indx == 1 ? (
                                <RvIcon
                                  type="rvicon-huizhang"
                                  style={{
                                    color: "rgba(184, 229, 247, 1)",
                                  }}
                                ></RvIcon>
                              ) : indx == 2 ? (
                                <RvIcon
                                  type="rvicon-huizhang"
                                  style={{
                                    color: "rgba(234, 201, 109, 1)",
                                  }}
                                ></RvIcon>
                              ) : null}
                            </div>
                          </div>
                          <div className="rpNum">
                            上报次数：<i>{v.reportNum}</i>
                          </div>
                        </div>
                      </div>
                      <div className="maRight">
                        <Icon type="right" />
                      </div>
                    </div>
                    <Divider style={{ margin: "16px 0 4px 0px" }}></Divider>
                    <div className="maItemD">
                      参与河流：
                  {v && v.rivers.map((re) => re && re.name).join(";")}
                    </div>
                  </div>
                ))
              }
            </div>
            : <Empty className="noData"></Empty>
        }

      </div>
    );
  }
}
