import {list} from 'api/pzCustom/outfallGoverment';

export const OUTFALLGOVERMENT = 'OUTFALLGOVERMENT';

export const tableAction = (data) => ({
  type: OUTFALLGOVERMENT,
  payload: {
    promise: list(data)
  }
});
