import config from '../utils/apiconfig';
import request from '../utils/request';

const {api} = config;
const { issue,issueId,issueMapData,issueProject} = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({url: issue, method: 'get', data: params,});
}


export async function getMapData(params) {
  return request({url: issueMapData, method: 'get', data: params,});
}
/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({url: issueId, method: 'get', data: {
      id:paramId
    },});
}
/**
 * 编辑
 */
export async function edit(params) {
  return request({url: issueId, method: 'put', data: params});
}
/**
 * 删除
 */
export async function deleteItem (params) {
  return request({url: issue+ '/delete' , method: 'post', data:params});
}
/**
 * 创建
 */
export async function createItem(params) {
  return request({url: issue, method: 'post', data: params,});
}


export async function listProjectIssue(params) {
  return request({url: issueProject, method: 'get', data: params,});
}

