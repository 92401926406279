import config from "../utils/apiconfig";
import request from "../utils/request";

const { api } = config;
const { wxNews,wxRiver,wxRiverReach,wxRiverId,wxMA,wxMaId,wxRePly,wxRiverMap } = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({ url: wxNews, method: "get", data: params });
}


/**
 * 查询列表
 */
export async function wxRlist(params) {
  return request({ url: wxRiver, method: "get", data: params });
}
/**
 * 查询列表
 */
export async function wxRRlist(params) {
  return request({ url: wxRiverReach, method: "get", data: params });
}
/**
 * 查询列表
 */
export async function wxRiverDetail(params) {
  return request({ url: wxRiverId, method: "get", data: params });
}
/**
 * 查询列表
 */
export async function wxMaList(params) {
  return request({ url: wxMA, method: "get", data: params });
}
/**
 * 查询列表
 */
export async function wxMaDeList(params) {
  return request({ url: wxMaId, method: "get", data: params });
}
/**
 * 查询列表
 */
export async function wxMaReplyList(params) {
  return request({ url: wxRePly, method: "get", data: params });
}
/**
 * 查询列表
 */
export async function wxRMap(params) {
  return request({ url: wxRiverMap, method: "get", data: params });
}
