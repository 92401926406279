import {municipalLevelManage} from 'api';

export const MUNICIPALLEVELMANAGELIST = 'MUNICIPALLEVELMANAGELIST';

export const tableAction = (data) => ({
  type: MUNICIPALLEVELMANAGELIST,
  payload: {
    promise: municipalLevelManage.list(data)
  }
});
