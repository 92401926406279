import { Redirect, Route, Switch } from "react-router-dom";
import { getToken, hasPermission } from "utils/auth";

import BindThirdAccount from "../views/login/BindThirdAccount";
import Layout from "views/layout";
import Login from "views/login";
import MoatAmbassador from "../views/wxRelated/MoatAmbassador";
import MoatAmbassadorDe from "../views/wxRelated/MoatAmbassadorDe";
import News from "../views/wxRelated/News";
import NewsDe from "../views/wxRelated/NewsDe";
import OAuthLogin from "../views/login/OAuthLogin";
import React from "react";
import RiverDe from "../views/wxRelated/RiverDe";
import RiverDeMap from "../views/wxRelated/RiverDeMap";
import Welcome from 'views/welcome';

/* 进入路由的判断 */

const Routes = () => {
  let CommandCenter;
  if (location.protocol == "https:") {
    CommandCenter = require("../pages/commandCenter");
  }
  return (
    <Switch>
      <WXRoute exec path="/wxNewsDe" component={NewsDe} />
      <WXRoute exec path="/wxNews" component={News} />
      <WXRoute exec path="/wxRiverDe" component={RiverDe} />
      <WXRoute exec path="/wxRiverDeMap" component={RiverDeMap} />
      <WXRoute exec path="/wxMa" component={MoatAmbassador} />
      <WXRoute exec path="/wxMaDe" component={MoatAmbassadorDe} />
      <Route exec path="/login" component={Login} />
      {location.protocol == "https:" ? (
        <Route exec path="/commandCenter" component={CommandCenter.default} />
      ) : null}

      <Route path="/welcome" component={Welcome} />
      <Route exec path="/oauth-login" component={OAuthLogin} />

      <ThirdAccountBindRoute
        exec
        path="/bind-third-account"
        component={BindThirdAccount}
      />
      <PrivateRoute exec path="" component={Layout} />
    </Switch>
  );
};

const ThirdAccountBindRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getToken() && hasPermission("ROLE_NO_BIND_USER") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getToken() ? (
        !hasPermission("ROLE_NO_BIND_USER") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/bind-third-account",
              state: { from: props.location },
            }}
          />
        )
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const WXRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

export default Routes;
