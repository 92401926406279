import {list} from 'api/pzCustom/blackSmellyWaterGoverment';

export const BLACKSMELLYWATERGOVERMENT = 'BLACKSMELLYWATERGOVERMENT';

export const tableAction = (data) => ({
  type: BLACKSMELLYWATERGOVERMENT,
  payload: {
    promise: list(data)
  }
});
