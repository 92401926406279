import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const { device,  deviceId} = api;

/**
 * 查询河流列表
 */
export async function list(params) {
  return request({url: device, method: 'get', data: params,});
}
/**
 * 查询河流详情
 */
export async function getDetail(paramId) {
  return request({url: deviceId, method: 'get', data: {
      id:paramId
    },});
}
/**
 * 编辑河流
 */
export async function edit(params) {
  return request({url: deviceId, method: 'put', data: params});
}
/**
 * 删除河流
 */
export async function deleteItem (params) {
  return request({url: device+ '/delete' , method: 'post', data:params});
}
/**
 * 创建河流
 */
export async function createItem(params) {
  return request({url: device, method: 'post', data: params,});
}
