import React, { Component } from "react";
import { Progress } from "antd";

export default class TopAreaIssueChart extends Component {
  render() {
    const { listTop } = this.props;

    return (
      <div className="topList">
        {listTop &&
          listTop.filter((f) => f.issueNum > 0).map((item, indx) => (
            <div className="topItem" key={indx}>
              <div className="topIndex">{indx + 1}</div>
              <div className="topProgress">
                <div className="report">
                  <div className="reportName">
                    {item.entityName && item.entityName}
                  </div>
                  <Progress
                    percent={item.issueNum && item.issueNum}
                    format={(percent) => `${percent}`}
                    status="active"
                    strokeColor={{
                      from: "#F94064",
                      to: "#FABC00",
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}
