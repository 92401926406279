import {dam} from 'api';

export const DAMLIST = 'DAMLIST';


export const damAction = (data) => ({
  type: DAMLIST,
  payload: {
    promise: dam.damList(data)
  }
});
