import {
  TASKMELIST,
  TASKCOMPLETELIST,
  TASKCOMPLETEXBLIST,
  TASKCOMPLETECSLIST,
} from '../actions/task';
import {reducerPackage} from "../../utils";

export const taskMeList = reducerPackage(TASKMELIST);

export const taskCompleteList = reducerPackage(TASKCOMPLETELIST);
export const taskCompletexiebanList = reducerPackage(TASKCOMPLETEXBLIST);
export const taskCompletechaosongList = reducerPackage(TASKCOMPLETECSLIST);
