import React, { Component } from "react";
import { Cascader } from "antd";
import _ from "lodash";
import { areaList, siblingsArea } from "api/region";

class SelectArea extends Component {
  state = {
    options: [],
    value: ""
  };

  getAreaList = () =>
    areaList({ page: 1, pageSize: 100000 }).then(res => {
      if (res.code === 200) {
        let results = res.data.map((v, i) => {
          return {
            value: v.id,
            label: v.name,
            isLeaf: v.level === "VILLAGE" ? true : false
          };
        });
        this.setState({
          options: results
        });
      }
    });

  componentDidMount = () => this.getAreaList();

  loadData = selectedOptions => {
    if (selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      if (targetOption.value) {
        areaList({
          parentId: targetOption.value,
          page: 1,
          pageSize: 100000
        }).then(res => {
          if (res.code === 200) {
            targetOption.loading = false;
            let results = res.data.map((v, i) => {
              return {
                value: v.id,
                label: v.name,
                isLeaf: v.level === "VILLAGE" ? true : false
              };
            });
            targetOption.children = results;
            this.setState({
              options: [...this.state.options]
            });
          }
        });
      }
    }
  };
  onChange = value => {
    this.setState({
      value
    });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };
  reset = () => {
    this.setState({
      value: ""
    });
  };

  render() {
    const { width, margin } = this.props;

    return (
      <Cascader
      popupClassName="popupClass"
        style={{ width: width ? width : 150, margin: margin ? margin : "none" }}
        options={this.state.options}
        loadData={this.loadData}
        onChange={this.onChange}
        value={this.state.value}
        changeOnSelect
        placeholder="请选择行政区域"
      />
    );
  }
}

export default SelectArea;
