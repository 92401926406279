import {list} from 'api/pzCustom/pzsum';

export const PZSUMLIST = 'PZSUMLIST';

export const tableAction = (data) => ({
  type: PZSUMLIST,
  payload: {
    promise: list(data)
  }
});
