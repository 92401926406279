export default () => {
  return [
    {
      name: "首页",
      url: "index",
      icon: "rvicon rvicon-shouye",
    },
    {
      name: "一张图",
      url: "onemap",
      icon: "rvicon  rvicon-map",
    },

    {
      name: "基础信息",
      url: "basicMessage",
      icon: "rvicon rvicon-jichuxinxi-copy",
      permission: [
        "USER_LIST",
        "ROLE_LIST",
        "ORG_LIST",
        "STATION_LIST",
        "RIVER_LIST",
        "RIVER_REACH_LIST",
        "RIVER_SYSTEM_LIST",
        "LAKE_LIST",
        "RESERVOIR_LIST",
        "ENGINEERING_PROJECT_LIST",
        "EWAGE_OUTLET_LIST",
        "WATER_INTAKE_LIST",
        "DEVICE_LIST",
        "DEVICE_MANU_LIST",
        "DEVICE_MODEL_LIST",
        "COMPANY_LIST",
        "CHIEF_LIST",
        "PROPOSAL_LIST",
        "LIMIT_LIST",
      ],
      children: [{
        name: "河湖基础信息",
        url: "riverBasic",
        permission: [
          "CHIEF_LIST",
          "RIVER_REACH_LIST",
          "LAKE_LIST",
          "RESERVOIR_LIST",
          "RIVER_LIST",
          "WATER_INTAKE_LIST",
          "EWAGE_OUTLET_LIST",
          "COMPANY_LIST",
          "ENGINEERING_PROJECT_LIST",
          "RIVER_SYSTEM_LIST",
        ],
        children: [{
          name: "河长/湖长/渠长/库长/湿地长管理",
          url: "chiefManage",
          permission: ["CHIEF_LIST"],
        },

        {
          name: "河流信息",
          url: "riverMessage",
          permission: ["RIVER_LIST"],
        },
        {
          name: "河段河长管理",
          url: "reachManage",
          permission: ["RIVER_REACH_LIST"],
        },
        {
          name: "塘坝管理",
          url: "damMessage",
          permission: ["RIVER_LIST"],
        },
        {
          name: "渠道信息",
          url: "canalMessage",
          permission: ["RIVER_LIST"],
        },
        {
          name: "渠段渠长管理",
          url: "canalManage",
          permission: ["RIVER_REACH_LIST"],
        },
        { name: "湖泊信息", url: "lakeMessage", permission: ["LAKE_LIST"] },
        {
          name: "湖片湖长管理",
          url: "lakeManage",
          permission: ["LAKE_LIST"],
        },
        {
          name: "水库信息",
          url: "reservoirMessage",
          permission: ["RIVER_REACH_LIST"],
        },
        {
          name: "库片库长管理",
          url: "reservoirManage",
          permission: ["RESERVOIR_LIST"],
        },
        {
          name: "湿地信息",
          url: "wetlandsMessage",
          permission: ["RIVER_REACH_LIST"],
        },
        {
          name: "湿地片湿地长管理",
          url: "wetlandsManage",
          permission: ["RESERVOIR_LIST"],
        },
        {
          name: "取水口管理",
          url: "intakeManage",
          permission: ["WATER_INTAKE_LIST"],
        },
        {
          name: "排污口管理",
          url: "sewageOutlet",
          permission: ["EWAGE_OUTLET_LIST"],
        },
        {
          name: "水电站管理",
          url: "hydropowerStation",
          permission: ["EWAGE_OUTLET_LIST"],
        },
        {
          name: "企业管理",
          url: "companyManage",
          permission: ["COMPANY_LIST"],
        },
        {
          name: "河长公示牌管理",
          url: "publicBoardManage",
        },
        {
          name: "河流断面管理",
          url: "riverSectionManage",
        },
        {
          name: "涉河涉湖工程项目",
          url: "engineerProject",
          permission: ["ENGINEERING_PROJECT_LIST"],
        },
        {
          name: "水系信息",
          url: "riverSystem",
          permission: ["RIVER_SYSTEM_LIST"],
        },
        ],
      },
      {
        name: "用户配置",
        url: "userManage",
        permission: ["USER_LIST", "ROLE_LIST", "ORG_LIST"],
        children: [
          { name: "用户管理", url: "userManage", permission: ["USER_LIST"] },
          { name: "角色管理", url: "roleManage", permission: ["ROLE_LIST"] },
          {
            name: "组织机构管理",
            url: "organizationManage",
            permission: ["ORG_LIST"],
          },
        ],
      },
      {
        name: "监控管理",
        url: "deviceMenu",
        permission: [
          "STATION_LIST",
          "DEVICE_LIST",
          "DEVICE_MANU_LIST",
          "DEVICE_MODEL_LIST",
        ],
        children: [
          {
            name: "测站管理",
            url: "siteManage",
            permission: ["STATION_LIST"],
          },
          {
            name: "设备管理",
            url: "deviceManage",
          },
          {
            name: "设备厂家管理",
            url: "deviceVender",
          },
          {
            name: "设备型号管理",
            url: "deviceTypeManage",
          },
        ],
      },
      {
        name: "系统中心",
        url: "sysTemMenu",
        permission: ["PROPOSAL_LIST", "LIMIT_LIST", "AREA_LIST"],
        children: [
          {
            name: "意见反馈",
            url: "feedback",
            permission: ["PROPOSAL_LIST"],
          },
          { name: "时限配置", url: "timeLimit", permission: ["LIMIT_LIST"] },
          {
            name: "行政区域管理",
            url: "regionManage",
            permission: ["AREA_LIST"],
          },
        ],
      },
      ],
    },

    {
      name: "河档河策",
      url: "riverArchives",
      icon: "rvicon rvicon-ziyuan",
      permission: ["HCHD_RIVER_REACH", "HCHD_LAKE_ALL", "HCHD_RESERVOIR_ALL"],
      children: [{
        name: "河段",
        url: "riverArchivesReachManage",
        permission: ["HCHD_RIVER_REACH"],
      },
      {
        name: "湖片",
        url: "riverArchivesLakeManage",
        permission: ["HCHD_LAKE_ALL"],
      },
      {
        name: "库片",
        url: "riverArchivesReservoirManage",
        permission: ["HCHD_RESERVOIR_ALL"],
      },
      ],
    },

    {
      name: "巡查管理",
      url: "patrolAndOversee",
      icon: "rvicon rvicon-dingwei1",
      permission: [
        "PLAN_LIST",
        "SUPERVISE_PLAN_RECORD_LIST",
        "PLAN_SUPERVISE_LIST",
        "RATING_PATROL_LIST",
      ],
      children: [
        { name: "例行巡查", url: "patrolPlan", permission: ["PLAN_LIST"] },
        { name: "巡查记录", url: "riverCruiseRecord", permission: ["PLAN_LIST"] },
        {
          name: "监督巡查",
          url: "supervisionTourRiver",
          permission: ["SUPERVISE_PLAN_RECORD_LIST"],
        },
        {
          name: "巡查提醒",
          url: "riverSupervision",
          permission: ["PLAN_SUPERVISE_LIST"],
        },
        {
          name: "巡查考核",
          url: "riverCruiseAssess",
          permission: ["RATING_PATROL_LIST"],
        },
      ],
    },
    {
      name: "事件管理",
      url: "issues",
      icon: "rvicon rvicon-wenti-m",
      permission: [
        "ISSUE_LIST",
        "ISSUE_SUPERVISE_LIST",
        "GZ_CD",
        "PUBLIC_REPORT_LIST",
      ],
      children: [
        { name: "问题管理", url: "issuesManage", permission: ["ISSUE_LIST"] },
        {
          name: "问题督办",
          url: "issueSupervise",
          permission: ["ISSUE_SUPERVISE_LIST"],
        },
        {
          name: "公众上报",
          url: "publicReport",
          permission: ["PUBLIC_REPORT_LIST"],
        },
      ],
    },
    {
      name: "工作台",
      url: "synergy",
      hasMessage: true,
      icon: "rvicon rvicon-mn_hezuo",
      children: [
        { name: "我的待办", url: "toDo" },
        { name: "我的已办", url: "haveDone" },
        { name: "我的协办", url: "xieban" },
        { name: "我的抄送", url: "chaosong" },
      ],
    },
    {
      name: "信息共享",
      url: "shareFile",
      icon: "rvicon rvicon-jichuxinxi-copy",
      permission: ["SHARE_INFO_CREATE", "SHARE_LIST"],
      children: [{
        name: "发布管理",
        url: "shareInfo",
        permission: ["SHARE_INFO_CREATE"],
      },
      { name: "信息查阅", url: "riverNews", permission: ["SHARE_LIST"] },
      { name: "工作简报", url: "briefreport", permission: ["SHARE_LIST"] },
      { name: "月小结报", url: "monthreport", permission: ["SHARE_LIST"] },
      { name: "文件管理", url: "filecon", permission: ["SHARE_LIST"] },
      { name: "督查管理", url: "supervise", permission: ["SHARE_LIST"] }
      ],
    },
    {
      name: "统计分析",
      url: " statisticAnalysis",
      icon: "rvicon rvicon-tongji",
      permission: ["PATROL_ANALYSIS", "ISSUE_ANALYSIS"],
      children: [{
        name: "巡查统计",
        url: "patrolAnalysis",
        permission: ["PATROL_ANALYSIS"],
      },
      {
        name: "问题统计",
        url: "issueAnalysis",
        permission: ["ISSUE_ANALYSIS"],
      },
      {
        name: "超时问题统计",
        url: "issueOverTimeAnalysis",
        permission: ["ISSUE_TIMEOUT_ANALYSIS"],
      },
      {
        name: "协同工单统计",
        url: "jointAnalysis",
      },
      ],
    },
    {
      name: "协同工单",
      url: "joint",
      icon: "rvicon rvicon-xietong",
      permission: ["JOINT_CREATE", "JOINT_LIST", "JOINT_CREATE"],
      children: [
        { name: "协同起单", url: "jointStart", permission: ["JOINT_CREATE"] },
        { name: "在途工单", url: "jointOnGoing", permission: ["JOINT_LIST"] },
        { name: "办结工单", url: "jointClosed", permission: ["JOINT_LIST"] },
      ],
    },
    {
      name: "水库动态监管",
      url: "reservoirDynamicSupervision",
      icon: "rvicon rvicon-dongtai",
      children: [],
    },

    {
      name: "水电站生态流量",
      url: 'https://sd.digitwater.com',
      icon: "rvicon rvicon-shuidianzhan",
      externalChain: true,
      children: [],
    },
    {
      name: "山洪动态监管",
      url: "mountainFloodDynamicSupervision",
      icon: "rvicon rvicon-hongshui",
      children: [],
    },
    {
      name: "在线监测",
      url: "video",
      icon: "rvicon rvicon-jiankong",
      children: [
        { name: "视频监控", url: "videoSurveillance", },
      ]
    },
  ];
};
