import config from "../utils/apiconfig";
import request from "../utils/request";

const { api } = config;
const { dayHistory, dayHistoryDetail } = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({ url: dayHistory, method: "get", data: params });
}

/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({
    url: dayHistoryDetail,
    method: "get",
    data: {
      id: paramId
    }
  });
}

/**
 * 编辑
 */
export async function edit(params) {
  return request({ url: dayHistoryDetail, method: "put", data: params });
}

/**
 * 删除
 */
export async function deleteItem(params) {
  return request({ url: dayHistory, method: "delete", data: params });
}

/**
 * 创建
 */
export async function createItem(params) {
  return request({ url: dayHistory, method: "post", data: params });
}
