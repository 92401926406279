import React, { Component } from "react";
import { Button, Input } from "antd";
import SelectResourceObj from "./selectResourceObj";
import SelectArea from "./selectArea";
import SelectDate from "./selectDate";
import moment from "moment";
class QueryComR extends Component {
  state = {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };

  handleChangeDate = (date, dateString) => {
    this.setState({
      startDate: dateString && dateString[0],
      endDate: dateString && dateString[1],
    });
  };
  handleChangeArea = (val) => {
    this.setState({
      areaId: val,
    });
  };
  handleChangeResourceObj = (val) => {
    this.setState({
      objType: val,
    });
  };
  handleChangeInput = (e) => {
    this.setState({
      objName: e.target.value,
    });
  };
  handleQueryR = () => {
    const { startDate, endDate, objType, objName, areaId } = this.state;
    if (startDate && endDate) {
      this.setState(
        {
          startDate,
          endDate,
          objType,
          objName,
          areaId: areaId && areaId[areaId.length - 1],
        },
        () => this.props.query({ ...this.state })
      );
    }
  };
  render() {
    return (
      <div>
        <SelectDate onChange={this.handleChangeDate}></SelectDate>
        <SelectArea
          width="100%"
          margin="8px 0 0 0"
          onChange={this.handleChangeArea}
        ></SelectArea>

        <SelectResourceObj
          width="100%"
          margin="8px 0 0 0"
          onChange={this.handleChangeResourceObj}
        ></SelectResourceObj>

        <Input
          placeholder="请输入资源名称"
          style={{ marginTop: "8px" }}
          value={this.state.objName}
          onChange={this.handleChangeInput}
        />

        <Button
          type="primary"
          block
          style={{ margin: "10px 0" }}
          onClick={this.handleQueryR}
        >
          查询
        </Button>
      </div>
    );
  }
}

export default QueryComR;
