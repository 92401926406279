import {riverSystem} from 'api';

export const RIVERSYSTEMLIST = 'RIVERSYSTEMLIST';


export const riverSystemList = (data) => ({
  type: RIVERSYSTEMLIST,
  payload: {
    promise: riverSystem.riverSystemList(data)
  }
});
