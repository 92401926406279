import {canal} from 'api';

export const CANAL = 'CANAL';
export const CANALAll = 'CANALAll';


export const canalList = (data) => ({
  type: CANAL,
  payload: {
    promise: canal.canalList(data)
  }
});
export const canalAllList = (data) => ({
  type: CANALAll,
  payload: {
    promise: canal.canalAllList(data)
  }
});
