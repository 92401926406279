import React, { Component } from "react";
import { Input, Select, Button, Table, Progress, Radio } from "antd";
const { Option } = Select;
import { riverCruisersList } from "../../api/commandCenter";

const columns = [
  {
    title: "姓名",
    dataIndex: "user.nickname",
    key: "name",
    width: 60,
  },
  {
    title: "级别",
    dataIndex: "area.level",
    key: "level",
    width: 60,
    render: (text, record, index) => {
      return text === "CITY"
        ? "市级"
        : text === "DISTRICT"
        ? "区县级"
        : text === "TOWN"
        ? "乡镇级"
        : text === "VILLAGE"
        ? "村级"
        : null;
    },
  },
  {
    title: "巡查资源",
    dataIndex: "objName",
    key: "objName",
    ellipsis: true,
  },
];

class QueryCom extends Component {
  state = {
    rcList: [],
    name: null,
    level: null,
  };
  componentDidMount = () => {
    this.getList()
    this.interval = setInterval(() => this.getList(), 1000 * 60);
  };
  getList = (value) => {
    riverCruisersList(value).then((res) => {
      if (res.code === 200) {
        this.setState({
          rcList: res.data.users,
        });
      }
    });
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleChangeSelect = (value) => {
    this.setState({
      level: value,
    });
  };
  handleChangeInput = (e) => {
    this.setState({
      name: e.target.value,
    });
  };
  handleQuery = () => {
    this.getList({
      userName: this.state.name,
      level: this.state.level,
    });
  };
  render() {
    const { rcList } = this.state;
    return (
      <div className="queryBar">
        <Input
          placeholder="请输入河长姓名"
          style={{ marginTop: "8px" }}
          value={this.state.name}
          onChange={this.handleChangeInput}
        />
        <Select
          allowClear
          placeholder="请选择河长级别"
          style={{ width: "100%", marginTop: "8px" }}
          onChange={this.handleChangeSelect}
          dropdownClassName="dpdClass"
        >
          <Option value="CITY">市级</Option>
          <Option value="DISTRICT">区县级</Option>
          <Option value="TOWN">乡镇级</Option>
          <Option value="VILLAGE">村级</Option>
        </Select>
        <Button
          type="primary"
          block
          style={{ margin: "10px 0" }}
          onClick={this.handleQuery}
        >
          查询
        </Button>

        <Table
          onRow={(record) => {
            return {
              onClick: (event) => {
                this.props.tableClick(record);
              },
            };
          }}
          dataSource={rcList}
          columns={columns}
          size="small"
          bordered={false}
          pagination={false}
        />
      </div>
    );
  }
}

export default QueryCom;
