import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {issueTimeoutChart, chiefIssueTimeoutTable, chiefIssueTable, riverReachIssueTable, issueChart, issueTimeoutReportPerson, issueTimeoutReach,jointTable,municipalLevelTable} = api;

/**
 * 查询巡查河长统计
 */
export async function chiefIssueTableList(params) {
  return request({
    url: chiefIssueTable,
    method: 'get',
    data: {areaLevel: params && params.chiefLevel, ...params }
  });
}

export async function riverReachIssueTableList(params) {
  return request({url: riverReachIssueTable, method: 'get',   data: {areaLevel: params && params.chiefLevel, ...params }});
}

/**
 * 查询问题图标
 */
export async function issueChartList(params) {
  return request({url: issueChart, method: 'get',  data: {areaLevel: params && params.chiefLevel, ...params }});
}

/**
 * 超时问题
 */
export async function issueTimeoutChartList(params) {
  return request({url: issueTimeoutChart, method: 'get', data: {areaLevel: params && params.chiefLevel, ...params }});
}

export async function chiefIssueTimeoutTableList(params) {
  return request({url: issueTimeoutReportPerson, method: 'get', data: {areaLevel: params && params.chiefLevel, ...params }});
}

export async function riverReachIssueTimeoutTableList(params) {
  return request({url: issueTimeoutReach, method: 'get', data: {areaLevel: params && params.chiefLevel, ...params }});
}

export async function jointTableList(params) {
  return request({url: jointTable, method: 'get', data: params});
}

export async function municipalLevelTableList(params) {
  return request({url: municipalLevelTable, method: 'get', data: params});
}
