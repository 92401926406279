import { patrolAnalysis } from "api";

export const CHIEFPATROLTABLE = "CHIEFPATROLTABLE";
export const CHIEFPATROLCHART = "CHIEFPATROLCHART";
export const CHIEFPATROLTABLE_HALFYEAR = "CHIEFPATROLTABLE_HALFYEAR";
export const CHIEFPATROLCHART_HALFYEAR = "CHIEFPATROLCHART_HALFYEAR";
export const RIVERREACHPATROLCHART = "RIVERREACHPATROLCHART";
export const RIVERREACHPATROLTABLE = "RIVERREACHPATROLTABLE";


export const RIVERREACHPATROLCHART_HALFYEAR = "RIVERREACHPATROLCHART_HALFYEAR";
export const RIVERREACHPATROLTABLE_HALFYEAR = "RIVERREACHPATROLTABLE_HALFYEAR";
export const CHIEFPATROLTWONCHART = "CHIEFPATROLTWONCHART";
export const CHIEFPATROLVILLAGECHART = "CHIEFPATROLVILLAGECHART";
export const AREASTATISTICSTABLEACTION = "AREASTATISTICSTABLEACTION";

export const HOMECHIEFPATROLTABLE_FULLYEAR = "HOMECHIEFPATROLTABLE_FULLYEAR";
export const HOMECHIEFPATROLCHART_FULLYEAR = "HOMECHIEFPATROLCHART_FULLYEAR";

export const HOMECHIEFPATROLTABLE_HALFYEAR = "HOMECHIEFPATROLTABLE_HALFYEAR";
export const HOMECHIEFPATROLCHART_HALFYEAR = "HOMECHIEFPATROLCHART_HALFYEAR";

export const HOMECHIEFPATROLTABLE_DIS = "HOMECHIEFPATROLTABLE_DIS";
export const HOMECHIEFPATROLCHART_DIS = "HOMECHIEFPATROLCHART_DIS";

export const HOMECHIEFPATROLTWONCHART = "HOMECHIEFPATROLTWONCHART";
export const HOMECHIEFPATROLVILLAGECHART = "HOMECHIEFPATROLVILLAGECHART";

export const chiefPatrolTableAction = (data) => ({
  type: CHIEFPATROLTABLE,
  payload: {
    promise: patrolAnalysis.chiefPatrolTableList(data),
  },
});

export const chiefPatrolChartAction = (data) => ({
  type: CHIEFPATROLCHART,
  payload: {
    promise: patrolAnalysis.chiefPatrolChartList(data),
  },
});
export const chiefPatrolTableAction_halfYear = (data) => ({
  type: CHIEFPATROLTABLE_HALFYEAR,
  payload: {
    promise: patrolAnalysis.chiefPatrolTableList(data),
  },
});

export const chiefPatrolChartAction_halfYear = (data) => ({
  type: CHIEFPATROLCHART_HALFYEAR,
  payload: {
    promise: patrolAnalysis.chiefPatrolChartList(data),
  },
});

export const chiefPatrolTableActionClear = () => ({
  type: CHIEFPATROLTABLE,
  payload: {
    promise: () => {
      return new Promise((resolve) => {
        resolve({ data: [] });
      });
    },
  },
});
export const chiefPatrolChartActionClear = () => ({
  type: CHIEFPATROLCHART,
  payload: {
    promise: () => {
      return new Promise((resolve) => {
        resolve({ data: [] });
      });
    },
  },
});
export const riverreachPatrolTableAction = (data) => ({
  type: RIVERREACHPATROLTABLE,
  payload: {
    promise: patrolAnalysis.riverreachPatrolTableList(data),
  },
});
export const riverreachPatrolChartAction = (data) => ({
  type: RIVERREACHPATROLCHART,
  payload: {
    promise: patrolAnalysis.riverreachPatrolChartList(data),
  },
});
export const riverreachPatrolTableAction_halfYear = (data) => ({
  type: RIVERREACHPATROLTABLE_HALFYEAR,
  payload: {
    promise: patrolAnalysis.riverreachPatrolTableList(data),
  },
});
export const riverreachPatrolChartAction_halfYear = (data) => ({
  type: RIVERREACHPATROLCHART_HALFYEAR,
  payload: {
    promise: patrolAnalysis.riverreachPatrolChartList(data),
  },
});
export const riverreachPatrolTableActionClear = (data) => ({
  type: RIVERREACHPATROLTABLE,
  payload: {
    promise: () => {
      return new Promise((resolve) => {
        resolve({ data: [] });
      });
    },
  },
});
export const riverreachPatrolChartActionClear = (data) => ({
  type: RIVERREACHPATROLCHART,
  payload: {
    promise: () => {
      return new Promise((resolve) => {
        resolve({ data: [] });
      });
    },
  },
});

export const chiefPatrolTownChartAction = (data) => ({
  type: CHIEFPATROLTWONCHART,
  payload: {
    promise: patrolAnalysis.chiefPatrolTwonChartList(data),
  },
});

export const chiefPatrolVillageChartAction = (data) => ({
  type: CHIEFPATROLVILLAGECHART,
  payload: {
    promise: patrolAnalysis.chiefPatrolVillageChartList(data),
  },
});
//巡查统计按区域查询
export const areaStatisticsTableAction = (data) => ({
  type: AREASTATISTICSTABLEACTION,
  payload: {
    promise: patrolAnalysis.areaStatisticsTableList(data),
  },
});

// 首页 相关


export const homechiefPatrolTableAction_fullYear = (data) => ({
  type: HOMECHIEFPATROLTABLE_FULLYEAR,
  payload: {
    promise: patrolAnalysis.chiefPatrolTableList(data),
  },
});

export const homechiefPatrolChartAction_fullYear = (data) => ({
  type: HOMECHIEFPATROLCHART_FULLYEAR,
  payload: {
    promise: patrolAnalysis.chiefPatrolChartList(data),
  },
});



export const homechiefPatrolTableAction_halfYear = (data) => ({
  type: HOMECHIEFPATROLTABLE_HALFYEAR,
  payload: {
    promise: patrolAnalysis.chiefPatrolTableList(data),
  },
});

export const homechiefPatrolChartAction_halfYear = (data) => ({
  type: HOMECHIEFPATROLCHART_HALFYEAR,
  payload: {
    promise: patrolAnalysis.chiefPatrolChartList(data),
  },
});






export const homeDischiefPatrolTableAction = (data) => ({
  type: HOMECHIEFPATROLTABLE_DIS,
  payload: {
    promise: patrolAnalysis.chiefPatrolTableList(data),
  },
});

export const homeDischiefPatrolChartAction = (data) => ({
  type: HOMECHIEFPATROLCHART_DIS,
  payload: {
    promise: patrolAnalysis.chiefPatrolChartList(data),
  },
});










export const homechiefPatrolTownChartAction = (data) => ({
  type: HOMECHIEFPATROLTWONCHART,
  payload: {
    promise: patrolAnalysis.chiefPatrolTwonChartList(data),
  },
});

export const homechiefPatrolVillageChartAction = (data) => ({
  type: HOMECHIEFPATROLVILLAGECHART,
  payload: {
    promise: patrolAnalysis.chiefPatrolVillageChartList(data),
  },
});
