import axios from 'axios';
import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const { pzqhxd,pzqhxdId,qhxdCflag,qhxdExport} = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({url: pzqhxd, method: 'get', data: params,});
}
/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({url: pzqhxdId, method: 'get', data: {
      id:paramId
    },});
}
/**
 * 编辑
 */
export async function edit(params) {
  return request({url: pzqhxdId, method: 'put', data: params});
}
/**
 * 删除
 */
export async function deleteItem (params) {
  return request({url: pzqhxd+ '/delete' , method: 'post', data:params});
}
/**
 * 创建
 */
export async function createItem(params) {
  return request({url: pzqhxd, method: 'post', data: params,});
}
/**
 * 是否可创建
 */
export async function canCreate() {
  return request({url: qhxdCflag, method: 'get'});
}
/**
 * 是否可创建
 */
export async function exportList(params) {
  return axios({method:'get',download:true,url: qhxdExport,params:params,responseType :'blob'});
}
