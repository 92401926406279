import { wetlands } from "api";

export const WETLANDS = "WETLANDS";
export const WETLANDSALL = "WETLANDSALL";

export const wetlandList = (data) => ({
  type: WETLANDS,
  payload: {
    promise: wetlands.list(data),
  },
});

export const wetlandsAllList = (data) => ({
  type: WETLANDSALL,
  payload: {
    promise: wetlands.Alllist(data),
  },
});
