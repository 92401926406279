import React, { Fragment, Component } from "react";
import { wxRelated } from "../../api";
import "./index.less";
import { Map, Polyline } from "react-amap";
import CrossType from "../oneMap/crossType";
import history from "utils/history";
import { amapKey } from "../../utils/constant";

export default class RiverDeMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      id: null,
      map: null,
      path: [],
    };

    this.mapEvents = {
      created: (mapInstance) => {
        this.setState(
          {
            map: mapInstance,
          },
          () => {
            const id =
              history &&
              history.location &&
              history.location.state &&
              history.location.state.id;

            wxRelated.wxRiverDetail({ id }).then((res) => {
              if (res.code === 200) {
                this.setState(
                  {
                    data: res.data,
                    path: res.data.polyline
                      .split(";")
                      .filter((f, index) => index % 10 == 0)
                      .map((s) => {
                        const pa = s.split(",");
                        return pa;
                      }),
                  },
                  () => {
                    this.state.map &&
                      this.state.map.setZoomAndCenter(11, this.state.path[0]);
                  }
                );
              }
            });
          }
        );
      },
    };
  }

  componentDidMount = () => {};

  render() {
    const { data, path } = this.state;

    return (
      <Fragment>
        <div className="container-river ">
          <div className="map">
            {path && (
              <Map
                amapkey={amapKey}
                mapStyle="amap://styles/772e82098363cfb5b4689e108a57a628"
                events={this.mapEvents}
                zoom={11}
                animateEnable={false}
                version={"1.4.7"}
              >
                <Polyline
                  style={{
                    strokeColor: "#40a9ff",
                    strokeWeight: 3,
                    showDir: false,
                  }}
                  path={path}
                  visible={true}
                />
              </Map>
            )}
          </div>
          <div className="des">
            <div className="desTitle">
              <CrossType type={data.crossType} />
              {data.name ? data.name : "--"}
            </div>
            <div className="desItem">
              <div className="lable">普查代码：</div>
              <div className="names">
                {data.censcode ? data.censcode : "--"}
              </div>
            </div>
            <div className="desItem">
              <div className="lable">所属区划：</div>
              <div className="names">
                {data.area && data.area.name
                  ? data.area && data.area.name
                  : "--"}
              </div>
            </div>
            <div className="desItem">
              <div className="lable">河流长度：</div>
              <div className="names">{data.rlen ? data.rlen : "--"}千米</div>
            </div>
            <div className="desItem">
              <div className="lable">河流级别：</div>
              {data.level === "UNKNOW" && <div className="names">未知</div>}
              {data.level === "PROVINCE" && <div className="names">省级</div>}
              {data.level === "CITY" && <div className="names">市级</div>}
              {data.level === "DISTRICT" && <div className="names">区级</div>}
              {data.level === "TOWN" && <div className="names">乡级</div>}
              {data.level === "VILLAGE" && <div className="names">村级</div>}
            </div>
            <div className="desItem">
              <div className="lable">水系：</div>
              <div className="names">
                {data.riverSystem && data.riverSystem.name
                  ? data.riverSystem && data.riverSystem.name
                  : "--"}
              </div>
            </div>
            <div className="desItem">
              <div className="lable">别名：</div>
              <div className="names">{data.alias ? data.alias : "--"}</div>
            </div>
            <div className="desItem">
              <div className="lable">河流面积：</div>
              <div className="names">
                {data.rarea ? data.rarea : "--"}平方千米
              </div>
            </div>
            <div className="desItem">
              <div className="lable">河口流量：</div>
              <div className="names">
                {data.flow ? data.flow : "--"}立方米/秒
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
