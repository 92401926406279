import { lakeManage } from "api";

export const LAKEMANAGELIST = "LAKEMANAGELIST";
export const LAKEMANAGEALLLIST = "LAKEMANAGEALLLIST";

export const lakeList = data => ({
  type: LAKEMANAGELIST,
  payload: {
    promise: lakeManage.list(data)
  }
});
export const lakeAllList = data => ({
  type: LAKEMANAGEALLLIST,
  payload: {
    promise: lakeManage.lakeAllList(data)
  }
});
