import {list} from 'api/pzCustom/sewagePipeNetwork';

export const SEWAGEPIPENETWORK = 'SEWAGEPIPENETWORK';

export const tableAction = (data) => ({
  type: SEWAGEPIPENETWORK,
  payload: {
    promise: list(data)
  }
});
