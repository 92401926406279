/* eslint-disable react/jsx-no-target-blank */
import "./index.less";

import { Button, Col, Form, Icon, Input, Row, message } from "antd";
import { auth, setToken, setUserId } from "utils/auth";
import { chiefManage, user } from "../../api";

import HB from "../../images/hb.png";
import React from "react";
import { actionEmums } from "actions/basic";
import { connect } from "react-redux";
import history from "../../utils/history";
import { login } from "api";
import { loginUser } from "actions/login";
import { refreshUser } from "../../redux/actions/login";

const FormItem = Form.Item;

@connect(
  state => {
    return {
      emums: state.emums,
      loginUserData: state.loginUser.allData
    };
  },
  { loginUser, actionEmums, refreshUser }
)
@Form.create()
export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    if (
      !(
        this.props.emums &&
        this.props.emums.data &&
        this.props.emums.data.length > 0
      )
    ) {
      this.props.actionEmums();
    }
    this.state = {
      loading: false
    };
  }

  //获取用户详情
  getUserDetail = async paramId => {
    return new Promise((resolve, reject) => {
      user.getUserDetail(paramId).then(res => {
        if (res.code === 200) {
          resolve(res.data);
        } else {
          resolve({});
          message.error("用户信息获取失败");
        }
      });
    });
  };
  //获取河长详情
  getChiefDetail = async paramId => {
    return new Promise((resolve, reject) => {
      chiefManage.getDetail(paramId).then(res => {
        if (res.code === 200) {
          resolve(res.data);
        } else {
          resolve({});
        }
      });
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    let n = this.props.form.getFieldsValue().userName;
    let p = this.props.form.getFieldsValue().password;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          channel: "BROWSER",
          loginUniqueKey: values.userName.trim(),
          password: values.password
        };

        this.enterLoading(true);
        this.props
          .loginUser(data)
          .then(response => {
            let res = response.value;
            if (res.code === 200) {
              message.info("登录成功!");
              auth(res.data);
              const userId = res.data.principal.id;
              setUserId(userId)
              Promise.all([
                this.getUserDetail(userId),
                this.getChiefDetail(userId)
              ]).then(values => {
                const userPrincipal = Object.assign({}, values[0], values[1]);
                this.enterLoading(false);
                const userAllData = this.props.loginUserData;
                userAllData.data.principal = userPrincipal;
                localStorage.setItem(
                  "avatarUrl",
                  userAllData.data.principal.avatarUrl
                );
                userAllData.data.getDetail = true;
                const rolesFirstKey = Object.keys(
                  userAllData.data.principal.roles
                )[0];
                userAllData.data.principal.roles =
                  userAllData.data.principal.roles[rolesFirstKey];
                this.props.refreshUser(userAllData).then(res => {
                  history.push("/welcome")
                  // let activeItems = sessionStorage.getItem("activeItem");
                  // if (activeItems) {
                  //   let nowPath = activeItems.split(",");
                  //   nowPath = "/" + nowPath[nowPath.length - 1];
                  //   history.push(nowPath);
                  // } else {
                  //   history.push("/index");
                  //   // history.push("/welcome");
                  // }
                });
              });
            } else {
              this.enterLoading(false);
            }
          })
          .catch(err => {
            console.log(err);
            message.warn("访问异常");
          })
          .finally(err => {
            this.enterLoading(false);
          });
      }
    });
  };
  enterLoading = status => {
    this.setState({ loading: status });
  };

  onChangeUserName = e => {
    this.props.form.setFieldsValue({ userName: e.target.value });
  };

  emitEmpty = () => {
    this.userNameInput.focus();
    this.props.form.setFieldsValue({ userName: "" });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const suffix = this.props.form.getFieldsValue().userName ? (
      <Icon type="close-circle" onClick={this.emitEmpty} />
    ) : null;
    return (
      <div className="login-page-wrap">
        <div className="box-out">
          <div className="systemInfo">

            <div className="systemName"></div>
            <div className="container">
              <div className="leftBox"></div>
              <div className="dividingLine"></div>
              <div className="rightBox">
                <div className="box clearfix">
                  <div className="login-inner">
                    <Form onSubmit={this.handleSubmit}>
                      <FormItem>
                        {getFieldDecorator("userName", {
                          rules: [{ required: true, message: "请输入账号" }]
                        })(
                          <Input
                            placeholder="请输入账号"
                            size="large"
                            style={{
                              width: "300px"
                            }}
                            prefix={<Icon type="user" className="icon-change" />}
                            suffix={suffix}
                            onChange={this.onChangeUserName}
                            ref={node => (this.userNameInput = node)}
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator("password", {
                          rules: [{ required: true, message: "请输入密码" }]
                        })(
                          <Input
                            type="password"
                            size="large"
                            placeholder="请输入密码"
                            prefix={<Icon type="lock" className="icon-change" />}
                          />
                        )}
                      </FormItem>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="loginBtn"
                        size="large"
                        block
                        loading={this.state.loading}
                      >
                        立即登录
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div>
            <a href='https://beian.miit.gov.cn' target="_blank" >
              <div className="copyright" style={{ height: "2vh" }}>Copyright © 乐山市河湖管理站 版权所有 蜀ICP备20010963号-1</div>
            </a>
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=51110002002301" target="_blank">
              <div className="copyright" style={{ height: "4vh" }}><img width={20} height={20} src={HB} style={{ marginRight: "4px" }} />川公网安备51110002002301</div>
            </a>

          </div>

        </div>
      </div>
    );
  }
}
