import React, { Component, Fragment } from "react";
import { wxRelated } from "../../api";
import { Icon, Divider, Tag, Timeline, Collapse, Empty } from "antd";
import history from "utils/history";
import "./index.less";
const { Panel } = Collapse;
export default class MoatAmbassador extends Component {
  state = {
    data: [],
    show: false,
  };

  componentDidMount = () => {
    const phone =
      history &&
      history.location &&
      history.location.state &&
      history.location.state.phone;

    wxRelated.wxMaReplyList({ phone }).then((res) => {
      if (res.code == 200) {
        this.setState({
          data: res.data,
        });
      }
    });
  };
  handleChangeArrow = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  callback = (key) => {
    console.log(key);
  };
  render() {
    const { show, data } = this.state;

    let results = data.filter(
      (f) => f.status == "SOLVE" || f.status == "ACCEPTED"
    );

    return (
      <div className="container-river">
        {results.length > 0 ? (
          <div className="maList">
            {results.map((v, indx) => (
              <div className="maItemsBox" key={v.id}>
                <div className="maItemsBoxU">
                  <div className="maItemsBoxWrapper">
                    <div className="maItemL">
                      {v.firstImg ? (
                        <img
                          src={v.firstImg}
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="maItemC">
                      <div className="title">{v && v.address && v.address}</div>
                      <div className="tag">
                        {v.project === "ISSUE_ST" && (
                          <Tag color="#FF7C00">水体</Tag>
                        )}
                        {v.project === "ISSUE_PK" && (
                          <Tag color="#FF7C00">排口</Tag>
                        )}
                        {v.project === "ISSUE_HC" && (
                          <Tag color="#FF7C00">河床</Tag>
                        )}
                        {v.project === "ISSUE_DA" && (
                          <Tag color="#FF7C00">堤岸</Tag>
                        )}
                        {v.project === "ISSUE_GGSS" && (
                          <Tag color="#FF7C00">公共设施</Tag>
                        )}
                        {v.project === "ISSUE_WZ" && (
                          <Tag color="#FF7C00">违章行为</Tag>
                        )}
                        {v.project === "ISSUE_OTHER" && (
                          <Tag color="#FF7C00">其他</Tag>
                        )}
                      </div>
                      <div className="date">
                        <Icon type="history" style={{ marginRight: "10px" }} />
                        {v.createTime}
                      </div>
                      <div className="desc">受理编号：{v.reportCode}</div>
                      <div className="desc">
                        问题河流：{v.river && v.river.name}
                      </div>
                    </div>
                  </div>
                </div>
                <Collapse
                  // defaultActiveKey={["1"]}
                  onChange={this.callback}
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                  expandIconPosition="right"
                >
                  <Panel key="1">
                    <div className="maItemsBoxD">
                      <div className="exBox excolor">
                        问题描述：
                        <p style={{ color: "#8a8989" }}>{v.description}</p>
                      </div>
                      {v.replyList.length > 0 ? (
                        <div className="exBoxss excolor">
                          <p>处理情况：</p>
                          <Timeline>
                            {v.replyList.map((r, indx) => (
                              <Timeline.Item key={r.id}>
                                <div className="cllcsj">{r.createTime}</div>
                                <div className="hfdw">{r.orgName}</div>
                                <div className="lzyj">{r.content}</div>
                              </Timeline.Item>
                            ))}
                          </Timeline>
                        </div>
                      ) : null}
                    </div>
                  </Panel>
                </Collapse>
              </div>
            ))}
          </div>
        ) : (
          <Empty className="noData"></Empty>
        )}
      </div>
    );
  }
}
