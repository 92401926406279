import {patrolSupervise} from 'api';

export const PATROLSUPERVISELIST = 'PATROLSUPERVISELIST';


export const tableAction = (data) => ({
  type: PATROLSUPERVISELIST,
  payload: {
    promise: patrolSupervise.list(data)
  }
});
