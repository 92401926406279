import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {uploadFile,emums,todos,qrCode} = api;

export async function findFile(filename) {
  return request({
    url: uploadFile, method: 'get', data: {
      filename: filename
    }
  });
}
export async function getEmums(params) {
  return request({
    url: emums, method: 'get', data:params
  });
}
//获取待办提示数量
export async function getQuantity(params) {
  return request({
    url: todos, method: 'get', data:params
  })
}

/**
 * get 公共 -> 使用二维码
 * 
 */
export const getQrCode = async (params) => {
  return request({ url: qrCode, method: 'get', data: params })
}