import config from '../utils/apiconfig';
import request from '../utils/request';

const {api} = config;
const {areaId,area,areaSiblings,areaSurvey} = api;
/**
 * 查询行政区域
 */
export async function areaList(params) {
  return request({url: area, method: 'get', data: params,});
}
/**
 * 创建行政区域
 */
export async function createArea(params) {
  return request({url: area, method: 'post', data: params,});
}
/**
 * 查询行政区域
 */
export async function areaDetail(id) {
  return request({url: areaId, method: 'get', data: {areaId:id},});
}
/**
 * 编辑行政区域
 */
export async function editArea(params) {
  return request({url: areaId, method: 'put', data: params});
}
export async function survey(params) {
  return request({url: areaSurvey, method: 'put', data: params});
}

/**
 * 删除行政区域
 */
export async function deleteArea(params) {
  return request({url: area+ '/delete' , method: 'post', data: params});
}
/**
 * 删除行政区域
 */
export async function siblingsArea(id) {
  return request({url: areaSiblings, method: 'get', data: {areaId:id}});
}
