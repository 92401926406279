import {reach} from 'api';

export const REACHLIST = 'REACHLIST';


export const reachList = (data) => ({
  type: REACHLIST,
  payload: {
    promise: reach.list(data)
  }
});
