import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const { taskMe, taskComplete} = api;

/**
 * 查询列表
 */
export async function taskMeList(params) {
  return request({url: taskMe, method: 'get', data: params,});
}
/**
 * 查询列表
 */
export async function taskCompleteList(params) {
  return request({url: taskComplete, method: 'get', data: params,});
}

