import {chiefManage} from 'api';

export const CHIEFMANAGELIST = 'CHIEFMANAGELIST';


export const tableAction = (data) => ({
  type: CHIEFMANAGELIST,
  payload: {
    promise: chiefManage.list(data)
  }
});
