import {region} from 'api';

export const REGIONLIST = 'REGIONLIST';


export const areaAction = (data) => ({
  type: REGIONLIST,
  payload: {
    promise: region.areaList(data)
  }
});
