import { video } from "api"

export const VIDEOSURVEILLANCELIST = "VIDEOSURVEILLANCELIST"

export const getVideoSurveillanceList = data => ({
  type: VIDEOSURVEILLANCELIST,
  payload: {
    promise: video.videoSurveillanceList(data)
  }
})
export const HYDROLOGYPERFORMANCERT = "HYDROLOGYPERFORMANCERT"

export const getHydrologyPerformanceRt = data => ({
  type: HYDROLOGYPERFORMANCERT,
  payload: {
    promise: video.hydrologyPerformanceRtList(data)
  }
})
export const HYDROLOGYPERFORMANCEHISTORY = "HYDROLOGYPERFORMANCEHISTORY"

export const getHydrologyPerformanceHistory = data => ({
  type: HYDROLOGYPERFORMANCEHISTORY,
  payload: {
    promise: video.hydrologyPerformanceHistoryList(data)
  }
})
