import {
  CHIEFISSUETABLE,
  ISSUECHART,
  RIVERREACHISSUETABLE,
  CHIEFISSUETIMEOUTTABLE,
  ISSUETIMEOUTCHART,
  RIVERREACHISSUETIMEOUTTABLE,
  JOINTTABLEACTION,
  MUNICIPALLEVELTABLEACTION
} from '../actions/issueAnalysis';

import {reducerPackage} from "../../utils";

export const issueChart = reducerPackage(ISSUECHART);
export const chiefIssueTable = reducerPackage(CHIEFISSUETABLE);
export const riverReachIssueTable = reducerPackage(RIVERREACHISSUETABLE);
//超时问题
export const issueTimeoutChart = reducerPackage(ISSUETIMEOUTCHART);
export const chiefIssueTimeoutTable = reducerPackage(CHIEFISSUETIMEOUTTABLE);
export const riverReachIssueTimeoutTable = reducerPackage(RIVERREACHISSUETIMEOUTTABLE)


export const jointTable = reducerPackage(JOINTTABLEACTION);

export const municipalLevelTable = reducerPackage(MUNICIPALLEVELTABLEACTION);
