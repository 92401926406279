import {deviceTypeManage} from 'api';

export const TYPEMANAGELIST = 'TYPEMANAGELIST';


export const tableAction = (data) => ({
  type: TYPEMANAGELIST,
  payload: {
    promise: deviceTypeManage.list(data)
  }
});
