import {issueSupervise} from 'api';

export const ISSUESUPERVISELIST = 'ISSUESUPERVISELIST';
export const ISSUESUPERVISECOMPLETELIST = 'ISSUESUPERVISECOMPLETELIST';
export const ISSUESUPERVISEHISTORYLIST = 'ISSUESUPERVISEHISTORYLIST';
export const tableAction = (data) => ({
  type: ISSUESUPERVISELIST,
  payload: {
    promise: issueSupervise.list(data)
  }
});


export const tableActionComplete = (data) => ({
  type: ISSUESUPERVISECOMPLETELIST,
  payload: {
    promise: issueSupervise.completeList(data)
  }
});

export const tableActionHistory = (data) => ({
  type: ISSUESUPERVISEHISTORYLIST,
  payload: {
    promise: issueSupervise.historyList(data)
  }
});
