import config from "../utils/apiconfig";
import request from "../utils/request";

const { api } = config;
const { lake, lakeId, lakeMapData, lakeSurvey, lakeAll } = api;

/**
 * 查询列表
 */
export async function list(params) {
  return request({ url: lake, method: "get", data: params });
}
/**
 * 查询全部列表
 */
export async function lakeAllList(params) {
  return request({ url: lakeAll, method: "get", data: params });
}

export async function getMapData(params) {
  return request({ url: lakeMapData, method: "get", data: params });
}

/**
 * 查询详情
 */
export async function getDetail(paramId) {
  return request({
    url: lakeId,
    method: "get",
    data: {
      id: paramId
    }
  });
}

/**
 * 编辑
 */
export async function edit(params) {
  return request({ url: lakeId, method: "put", data: params });
}
export async function survey(params) {
  return request({ url: lakeSurvey, method: "put", data: params });
}

/**
 * 删除
 */
export async function deleteItem(params) {
  return request({ url: lake, method: "delete", data: params });
}

/**
 * 创建
 */
export async function createItem(params) {
  return request({ url: lake, method: "post", data: params });
}
