import {feedback} from 'api';

export const FEEDBACKLIST = 'FEEDBACKLIST';


export const feedbackAction = (data) => ({
  type: FEEDBACKLIST,
  payload: {
    promise: feedback.list(data)
  }
});
