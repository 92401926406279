import {list} from 'api/pzCustom/summaryOfGovernanceProjects';

export const SUMMARYOFGOVERNANCEPROJECTS = 'SUMMARYOFGOVERNANCEPROJECTS';

export const tableAction = (data) => ({
  type: SUMMARYOFGOVERNANCEPROJECTS,
  payload: {
    promise: list(data)
  }
});
