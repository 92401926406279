import {
  PATROLPLANLIST
} from '../actions/patrolPlan';
import {
  PATROLRECORDLIST,
  PATROLRECORDSUPERVISERLIST
} from '../actions/patrolRecord';
import {reducerPackage} from "../../utils";
//巡查计划
export const patrolPlanList = reducerPackage(PATROLPLANLIST);
//巡查记录
export const patrolRecordList = reducerPackage(PATROLRECORDLIST);
//监督巡查
export const patrolRecordSuperviseList = reducerPackage(PATROLRECORDSUPERVISERLIST);

