import config from "utils/apiconfig"
import request from "utils/request"

const { api } = config
const {
    riverGeneralSituaction
} = api


export async function riverGeneralSituactionList(params) {
  return request({ url: riverGeneralSituaction, method: "get", data: params })
    
}
