import './index.less';

import { Badge, Layout } from 'antd';

import Footer from './bottom';
import LSTop from './lsheader';
import Nav from './menu';
import { Provider } from 'context';
import React from 'react';
import RouterPages from "../../routes/routesPages";
import TabsRouterPages from "../../routesTabs/tabsRoutesPages";
import Top from './header';
import _ from 'lodash'
import { actionEmums } from 'actions/basic';
import { connect } from 'react-redux';

@connect(
  (state) => {
    return ({
      loginUser: state.loginUser.data,
      permissions: state.permission.data,
    });
  }, { actionEmums }
)

export default class Container extends React.Component {
  state = {
    theme: 'light',
    current: 'index',
    collapsed: false,
    mode: 'horizontal',
    marginLeft: 200,
    test: '改变颜色',
    breadList: [],
  };




  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      mode: this.state.collapsed ? 'inline' : 'vertical',
      marginLeft: this.state.collapsed ? 200 : 80
    });
  };


  render() {
    return (
      <Provider value={this.state}>
        <Layout className="containAll">
          <LSTop toggle={this.toggle} collapsed={this.state.collapsed} />
          <TabsRouterPages />
          {/* <Footer/> */}
        </Layout>
      </Provider>
    );
  }
};
