import {river} from 'api';

export const RIVER = 'RIVER';


export const riverList = (data) => ({
  type: RIVER,
  payload: {
    promise: river.riverList(data)
  }
});
