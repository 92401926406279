import {patrolRecord} from '../../api';

export const PATROLRECORDLIST = 'PATROLRECORDLIST';
export const PATROLRECORDSUPERVISERLIST = 'PATROLRECORDSUPERVISERLIST';


export const tableAction = (data) => ({
  type: PATROLRECORDLIST,
  payload: {
    promise: patrolRecord.list(data)
  }
});
export const tablePatrolSuperviseAction = (data) => ({
  type: PATROLRECORDSUPERVISERLIST,
  payload: {
    promise: patrolRecord.superviseList(data)
  }
});
