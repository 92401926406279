import {shareInfo} from 'api';

export const SHAREINFOLIST = 'SHAREINFOLIST';
export const SHAREINFONEWSLIST = 'SHAREINFONEWSLIST';


export const tableAction = (data) => ({
  type: SHAREINFOLIST,
  payload: {
    promise: shareInfo.list(data)
  }
});
export const tableActionNew = (data) => ({
  type: SHAREINFONEWSLIST,
  payload: {
    promise: shareInfo.list(data)
  }
});
