import React, { Component } from "react";
import { Progress } from "antd";
import "./index.less";
export default class TopChiefPatrolChart extends Component {
  render() {
    const { listTop } = this.props;
    return (
      <div className="topList">
        {listTop &&
          listTop
            .filter((f) => f.patrolNum > 0)
            .map((item, indx) => (
              <div className="topItem" key={indx}>
                <div className="topIndex">{indx + 1}</div>
                <div className="topProgress">
                  <div className="report">
                    <div className="riverCruiseName">
                      {item.entityName && item.entityName}
                    </div>
                    <Progress
                      percent={item.patrolNum && item.patrolNum}
                      format={(percent) => `${percent}`}
                      status="active"
                      strokeColor={{
                        from: "#0E8799",
                        to: "#34E95C",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
      </div>
    );
  }
}
