import { hydropower } from "api";

export const HYDROPOWERLIST = "HYDROPOWERLIST";

export const hydropowerAction = (data) => ({
  type: HYDROPOWERLIST,
  payload: {
    promise: hydropower.list(data),
  },
});
