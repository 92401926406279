import config from "utils/apiconfig";
import request from "utils/request";

const { api } = config;
const {
  channel,
  channelId,
  channelMap,
  channelAll,
  channelAllId,
  channelAllMap
} = api;

/**
 * 查询渠道列表
 */
export async function canalList(params) {
  return request({ url: channel, method: "get", data: params });
}

/**
 * 查询渠道详情
 */
export async function getCanalDetail(id) {
  return request({
    url: channelId,
    method: "get",
    data: {
      channelId: id
    }
  });
}
/**
 * 编辑渠道
 */
export async function editCanal(params) {
  return request({ url: channelId, method: "put", data: params });
}
/**
 * 删除渠道
 */
export async function deleteCanal(params) {
  return request({ url: canal, method: "delete", data: params });
}
/**
 * 创建渠道
 */
export async function createCanal(params) {
  return request({ url: canal, method: "post", data: params });
}

/**
 * 查询渠段
 */
export async function canalAllList(params) {
  return request({ url: channelAll, method: "get", data: params });
}

/**
 * 查询渠段详情
 */
export async function getCanalAllDetail(id) {
  return request({
    url: channelAllId,
    method: "get",
    data: {
      channelAllId: id
    }
  });
}

export async function getMapData(params) {
  return request({ url: channelMap, method: "get", data: params });
}
