import config from 'utils/apiconfig';
import request from 'utils/request';

const {api} = config;
const {heZhang} = api;

/**
 * 登录接口
 */
export async function loginUser(params) {
  return request({url: `${heZhang}/login`, method: 'post', data: params,});
}

export async function loginByOAuth(params) {
  return request({url: `${heZhang}/users/oauth/login`, method: 'get', data: params,});
}


/**
 * 接口测试
 */
export async function fetchDataTest (params) {
  // return request({url:'http://tingapi.ting.baidu.com/v1/restserver/ting', method: 'JSONP', data: params});
}



