import {
  SEWAGEOUTLETLIST
} from 'actions/sewageOutlet';
import {reducerPackage} from "../../utils";

export const initialState = {
  isFetching: false,
  data: []
};
export const sewageOutletList = reducerPackage(SEWAGEOUTLETLIST);

