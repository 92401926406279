import * as types from './mutationType';
import {reducerPackage} from '../../../utils/index';

export const manufacturersList = reducerPackage(types.MANUFACTURES_LIST);
export const waterStationList = reducerPackage(types.WATERSTATION_LIST);
export const waterMeterModelList = reducerPackage(types.METEREMODEL_LIST);
export const waterDeviceList = reducerPackage(types.WATERDEVICE_LIST);
export const waterMeterReplaceList = reducerPackage(types.WATERMETERREPLACE_LIST);

//
export const waterMeterList = reducerPackage(types.WATERMETER_LIST)


//查询采集器实时监控数据
export const collectorIsMonitoredList = reducerPackage(types.COLLECTORISMONITORED_LIST)
//查询采集器告警列表
export const collectorAlarmList = reducerPackage(types.COLLECTORALARM_LIST)
//查询采集器告警详情数据
export const collectorAlarmDetailsData =reducerPackage(types.COLLECTORALARMDETAILS_LIST)

//查询大表告警信息列表
export const largeMeterAlarmList = reducerPackage(types.LARGEMETERALARMINFO_LIST)
//查询大表告警信息详情数据
export const largeMeterAlarmDetailsData = reducerPackage(types.LARGEMETERALARMINFODETAILS_LIST)

//查询大表告警设置列表
export const largeMeterAlarmSetupList = reducerPackage(types.LARGEMETERALARMSETUP_LIST)

//新增大表告警设置
export const addLargeMeterAlarmSetup = reducerPackage(types.ADD_LARGMETERLARMSEUP)

//新增采集器实时监控
export const addCollectorIsMonitored = reducerPackage(types.ADD_COLLECTORISMONITORED)


//管线信息
export const pipelineLineInfoList = reducerPackage(types.PIPELINEINFO)

//新增管线信息
export const addPipelineInfo = reducerPackage(types.ADDPIPELINEINFO)

//用水企业管理列表
export const useWaterenterpriseList = reducerPackage(types.USEWATERENTERPRISE)

//用水企业统计分析列表
export const useWaterenterpriseStatisticsList = reducerPackage(types.ENTERPRISESTATISTICS)
//用水企业统计分析按用水企业查询图表数据
export const useWaterenterpriseStatisticsChartData = reducerPackage(types.ENTERPRISESTATISTICS_BY_ENTERPRISE)
//用水企业统计分析按用水企业查询(年)图表数据
export const useWaterenterpriseStatisticsYearChartData = reducerPackage(types.ENTERPRISESTATISTICS_BY_ENTERPRISE_YEAR)
//大表抄表列表
export const meterList = reducerPackage(types.METERLIST)