import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import "./index.less";
import { connect } from "react-redux";
import { message } from "antd";
import { getProblemsInProcessingChart } from "../../api/statistical";

@connect((state) => {
  return {
    emums: state.emums,
  };
}, {})
class PieChart extends Component {
  constructor(props) {
    1;
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    getProblemsInProcessingChart().then((res) => {
      if (res.code === 200) {
        this.setState({
          chartsOption: res.data,
        });
      } else {
        message.error(res.msg);
      }
    });
    this.getOption();
  };
  getOption = (data) => {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      color: [
        "#19bd9b",
        "#ea4d3d",
        "#f1c40f",
        "#818eb5",
        "#f3a29f",
        "#c911ac",
        "#c085ac",
      ],

      legend: {
        textStyle: {
          color: "#fff",
        },
        orient: "horizontal",
        data:
          data &&
          data.map((d) => {
            return (
              this.props.emums &&
              this.props.emums.data["IssueProject"] &&
              this.props.emums.data["IssueProject"][d.issueProject] &&
              this.props.emums.data["IssueProject"][d.issueProject].enumDesc
            );
          }),
        itemWidth: 8,
        itemHeight: 8,
        left: 20,
        bottom: -4,
        width: 210,
        selected: {
          侵占河道: true,
        },
        itemGap: 10,
        formatter: "{name}",
      },
      series: [
        {
          name: "问题类型",
          type: "pie",
          radius: ["25%", "38%"],
          selectedOffset: 10,
          hoverOffset: 10,
          center: [120, 110],
          label: {
            show: true,
            formatter: "{d}%",
          },
          labelLine: {},
          data:
            data &&
            data.map((d) => {
              return {
                value: d.issueNum,
                name:
                  this.props.emums &&
                  this.props.emums.data["IssueProject"] &&
                  this.props.emums.data["IssueProject"][d.issueProject] &&
                  this.props.emums.data["IssueProject"][d.issueProject]
                    .enumDesc,
              };
            }),
        },
      ],
    };
  };
  render() {
    return (
      <ReactEcharts
        option={this.getOption(this.state.chartsOption)}
        onChartReady={this.pieHighlight}
        onEvents={{
          mouseover: this.pieMouseover,
          mouseout: this.pieMouseout,
        }}
        style={{ width: "100%", height: "230px" }}
        className="react_for_echarts-pie"
      />
    );
  }
}

export default PieChart;
